import { Box } from "@mui/system";
import styled from "styled-components";
import { ImagePickerContainer } from "../../components/ImagePicker/ImagePicker.styled";
import { TextInputFieldContainer } from "../../components/TextInputField/TextInputField.styled";

export const FormContainer = styled.form`
  position: relative;
  width: 100%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ProfilePageContainer = styled(Box)`
  padding: 0 70px;
  width: 100%;
  height: 100%;
  @media (max-width: 600px) {
    padding: 0;
    padding-bottom: 32px;
    width: 100%;
    & ${TextInputFieldContainer} input {
      width: 100%;
    }
    & ${TextInputFieldContainer} div:has(input) {
      width: 100%;
    }
    & ${ImagePickerContainer} {
      margin-top: 32px;
    }
  }
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
`;

export const ButtonsAreaContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 32px;
  left: 0px;
  width: 100%;
`;
