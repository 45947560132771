import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { FormControl } from "@mui/material";
import {
  ErrorMessage,
  IconError,
  Option,
  SelectLabel,
  SelectMUI,
} from "./Select.styled";
import { DEFAULT_PLACEHOLDER } from "../../constants/defaultPlaceholderConstant";
import selectedTheme from "../../themes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "../Tooltip/Tooltip";

const Select = forwardRef((props, ref) => {
  const [isOpened, setIsOpened] = useState(false);
  const [value, setValue] = useState();

  const handleChange = (event) => {
    setValue(event?.target?.value);
    props?.onChange(event);
  };

  useImperativeHandle(ref, () => ({
    isOpened,
    setIsOpened,
    value,
  }));

  const toggleOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpened((prevState) => !prevState);
  };

  return (
    <FormControl
      size="small"
      fullWidth
      {...props?.formProps}
      className={props?.className}
    >
      <SelectLabel $required={props?.required}>{props?.label} </SelectLabel>
      <SelectMUI
        MenuProps={{
          sx: {
            maxHeight: "320px",
            maxWidth: "300px",
            "&& .Mui-selected": {
              backgroundColor: `${selectedTheme.colors.primaryButtonLightColor}`,
              "&:after": {
                position: "absolute",
                right: "0",
                content: `'✔'`,
                fontSize: "1rem",
                backgroundColor: `${selectedTheme.colors.primaryButtonLightColor}`,
                paddingLeft: "15px",
                paddingRight: "15px",
              },
            },
            "&& .MuiMenu-paper": {
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: `${selectedTheme.colors.questionSeparator}`,
                borderRadius: "8px",
              },
            },
            "&.MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: `${selectedTheme.colors.borderColor}`,
              },
            },
          },
        }}
        disabled={props?.disabled}
        inputRef={ref}
        IconComponent={props?.error ? IconError : ExpandMoreIcon}
        $isErrorShown={props?.error}
        defaultValue={DEFAULT_PLACEHOLDER}
        value={props?.value || DEFAULT_PLACEHOLDER}
        onChange={handleChange}
        open={isOpened}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: selectedTheme.colors.disabledFieldText,
          },
        }}
        {...props?.selectProps}
      >
        <Option
          hideDefaultValue={props?.hideDefaultValue}
          value={DEFAULT_PLACEHOLDER}
        >
          {props?.placeholder}
        </Option>
        {props?.options?.map((singleItem) => (
          <Option key={singleItem?.id} value={singleItem?.id}>
            {!isOpened && props?.showTooltip ? (
              <Tooltip title={singleItem?.label} maxWidth="250px">
                {singleItem?.label}
              </Tooltip>
            ) : (
              singleItem?.label
            )}
          </Option>
        ))}
      </SelectMUI>
      {props?.error && <ErrorMessage>{props?.error}</ErrorMessage>}
    </FormControl>
  );
});

Select.displayName = "Select";

Select.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  formProps: PropTypes.any,
  selectProps: PropTypes.any,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  hideDefaultValue: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

Select.defaultProps = {
  defaultValue: DEFAULT_PLACEHOLDER,
  value: DEFAULT_PLACEHOLDER,
  onChange: () => {},
  options: [],
  showTooltip: false,
};

export default Select;
