import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";
import { ErrorMessage } from "../NewClientForm.styled";

const ContactEmailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="contactEmail"
        placeholder={t("clients.newClientPlaceholders.contactEmail")}
        label={t("clients.contactEmail")}
        value={formik?.values?.contactEmail}
        onChange={formik?.handleChange}
        requiredField={
          newClientValidationSchema?.fields?.contactEmail?.exclusiveTests?.required
        }
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.contactEmail &&
                formik?.touched?.contactEmail &&
                "40px !important",
              width:
                formik?.errors?.contactEmail &&
                formik?.touched?.contactEmail &&
                "314px !important",
            },
          },
        }}
        error={formik?.errors?.contactEmail && formik?.touched?.contactEmail}
        helperText={formik?.errors?.contactEmail && formik?.touched?.contactEmail}
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.contactEmail && formik?.touched?.contactEmail && (
        <ErrorMessage>{formik?.errors?.contactEmail}</ErrorMessage>
      )}
    </>
  );
};

ContactEmailField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default ContactEmailField;
