import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_COMPANY = createSetType("SET_COMPANY");
export const SET_COMPANY_ERROR = "SET_COMPANY_ERROR";

const GET_COMPANIES_SCOPE = "GET_COMPANIES_SCOPE";
export const GET_COMPANIES_FETCH = createFetchType(GET_COMPANIES_SCOPE);
export const GET_COMPANIES_SUCCESS = createSuccessType(GET_COMPANIES_SCOPE);
export const GET_COMPANIES_ERROR = createErrorType(GET_COMPANIES_SCOPE);
export const GET_COMPANIES_CLEAR = createClearType(GET_COMPANIES_SCOPE);

export const MY_COMPANY_SCOPE = "MY_COMPANY_SCOPE";
export const MY_COMPANY_FETCH = createFetchType(MY_COMPANY_SCOPE);
export const MY_COMPANY_SUCCESS = createSuccessType(MY_COMPANY_SCOPE);
export const MY_COMPANY_ERROR = createErrorType(MY_COMPANY_SCOPE);
export const MY_COMPANY_SET = createSetType(MY_COMPANY_SCOPE);
export const MY_COMPANY_CLEAR = createClearType(MY_COMPANY_SCOPE);

const SINGLE_COMPANY_SCOPE = "SINGLE_COMPANY_SCOPE";
export const SINGLE_COMPANY_FETCH = createFetchType(SINGLE_COMPANY_SCOPE);
export const SINGLE_COMPANY_SUCCESS = createSuccessType(SINGLE_COMPANY_SCOPE);
export const SINGLE_COMPANY_ERROR = createErrorType(SINGLE_COMPANY_SCOPE);
export const SINGLE_COMPANY_SET = createSetType(SINGLE_COMPANY_SCOPE);

const CREATE_COMPANY_SCOPE = "CREATE_COMPANY_SCOPE";
export const CREATE_COMPANY_FETCH = createFetchType(CREATE_COMPANY_SCOPE);
export const CREATE_COMPANY_SUCCESS = createSuccessType(CREATE_COMPANY_SCOPE);
export const CREATE_COMPANY_ERROR = createErrorType(CREATE_COMPANY_SCOPE);

const EDIT_COMPANY_SCOPE = "EDIT_COMPANY_SCOPE";
export const EDIT_COMPANY_FETCH = createFetchType(EDIT_COMPANY_SCOPE);
export const EDIT_COMPANY_SUCCESS = createSuccessType(EDIT_COMPANY_SCOPE);
export const EDIT_COMPANY_ERROR = createErrorType(EDIT_COMPANY_SCOPE);

const CREATE_APR_IMAGES_SCOPE = "CREATE_APR_IMAGES_SCOPE";
export const CREATE_APR_IMAGES_FETCH = createFetchType(CREATE_APR_IMAGES_SCOPE);
export const CREATE_APR_IMAGES_SUCCESS = createSuccessType(
  CREATE_APR_IMAGES_SCOPE
);
export const CREATE_APR_IMAGES_ERROR = createErrorType(CREATE_APR_IMAGES_SCOPE);

const EDIT_APR_IMAGES_SCOPE = "EDIT_APR_IMAGES_SCOPE";
export const EDIT_APR_IMAGES_FETCH = createFetchType(EDIT_APR_IMAGES_SCOPE);
export const EDIT_APR_IMAGES_SUCCESS = createSuccessType(EDIT_APR_IMAGES_SCOPE);
export const EDIT_APR_IMAGES_ERROR = createErrorType(EDIT_APR_IMAGES_SCOPE);

export const SET_COMPANIES = createSetType("SET_COMPANIES");
export const SET_TOTAL_COMPANIES = createSetType("SET_TOTAL_COMPANIES");
export const CLEAR_COMPANIES = createClearType("CLEAR_COMPANIES");

export const EMAIL_VERIFICATION_SCOPE = "EMAIL_VERIFICATION_SCOPE";
export const EMAIL_VERIFICATION_FETCH = createFetchType(
  EMAIL_VERIFICATION_SCOPE
);
export const EMAIL_VERIFICATION_SUCCESS = createSuccessType(
  EMAIL_VERIFICATION_SCOPE
);
export const EMAIL_VERIFICATION_ERROR = createErrorType(
  EMAIL_VERIFICATION_SCOPE
);

export const UPLOAD_JSON_SECRET_SCOPE = "UPLOAD_JSON_SECRET_SCOPE";
export const UPLOAD_JSON_SECRET_FETCH = createFetchType(
  UPLOAD_JSON_SECRET_SCOPE
);
export const UPLOAD_JSON_SECRET_SUCCESS = createSuccessType(
  UPLOAD_JSON_SECRET_SCOPE
);
export const UPLOAD_JSON_SECRET_ERROR = createErrorType(
  UPLOAD_JSON_SECRET_SCOPE
);

export const VALIDATE_DRIVE_SCOPE = "VALIDATE_DRIVE_SCOPE";
export const VALIDATE_DRIVE_FETCH = createFetchType(VALIDATE_DRIVE_SCOPE);
export const VALIDATE_DRIVE_SUCCESS = createSuccessType(VALIDATE_DRIVE_SCOPE);
export const VALIDATE_DRIVE_ERROR = createErrorType(VALIDATE_DRIVE_SCOPE);

export const VALIDATE_NEW_DRIVE_SCOPE = "VALIDATE_NEW_DRIVE_SCOPE";
export const VALIDATE_NEW_DRIVE_FETCH = createFetchType(
  VALIDATE_NEW_DRIVE_SCOPE
);
export const VALIDATE_NEW_DRIVE_SUCCESS = createSuccessType(
  VALIDATE_NEW_DRIVE_SCOPE
);
export const VALIDATE_NEW_DRIVE_ERROR = createErrorType(
  VALIDATE_NEW_DRIVE_SCOPE
);
