import { Box } from "@mui/material";
import styled from "styled-components";

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 15px;
  justify-content: center;
  /* margin-left: -50px; */
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

export const ButtonsAreaContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: center;
  margin-top: auto; */
`;

