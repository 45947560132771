import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  TextareaInputContainer,
  TextareaInputField,
  TextareaInputTitle,
} from "./TextareaInput.styled";
import selectedTheme from "../../../themes";
import { ContentState, EditorState, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { createHtmlString, formatHtml } from "../../../util/helpers/htmlHelpers";
import draftToHtml from "draftjs-to-html";
// import { cleanDocx, isDocxContent } from "@prezly/docx-cleaner";
// import { docsSoap } from "docs-soap";
import LexicalEditor from "../../LexicalEditor/LexicalEditor";

const TextareaInput = (props) => {
  let propsObject = { ...props };
  if (props?.requiredField) delete propsObject.requiredField;
  if (props?.disabledField) delete propsObject.disabledField;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(createHtmlString(props?.value))
      )
    )
  );

  useEffect(() => {
    if (
      draftToHtml(convertToRaw(editorState?.getCurrentContent?.())) !==
      props?.value
    ) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(createHtmlString(props?.value))
          )
        )
      );
    }
    if (props?.value === null) {
      setEditorState(EditorState.createEmpty());
    }
  }, [props?.value]);

  // const onEditorStateChange = (newEditorState) => {
  //   props?.onChange?.(newEditorState);
  //   setEditorState(newEditorState);
  // };

  // const handlePastedText = (text, html) => {
  //   try {
  //     let newHtml = "";
  //     if (html != null) {
  //       const parser = new DOMParser();
  //       const { body } = parser.parseFromString(html, "text/html");
  //       if (isDocxContent(body)) {
  //         newHtml = cleanDocx(html);
  //       } else {
  //         console.log(createHtmlString(html));
  //         newHtml = docsSoap(createHtmlString(html));
  //         newHtml = newHtml.replace(/<li><p>/g, "<li>");
  //         newHtml = newHtml.replace(/<\/p><\/li>/g, "</li>");
  //       }
  //     } else {
  //       newHtml = createHtmlString(text);
  //     }
  //     const currentContent = editorState.getCurrentContent(),
  //       currentSelection = editorState.getSelection();
  //     const newContent = Modifier.replaceWithFragment(
  //       currentContent,
  //       currentSelection,
  //       ContentState.createFromBlockArray(htmlToDraft(newHtml)).getBlockMap()
  //     );

  //     const newEditorState = EditorState.push(
  //       editorState,
  //       newContent,
  //       "insert-fragment"
  //     );
  //     setEditorState(
  //       EditorState.forceSelection(
  //         newEditorState,
  //         newContent.getSelectionAfter()
  //       )
  //     );
  //   } catch (e) {
  //     console.dir(e);
  //   }
  //   return true;
  // };

  const handleChangeRichText = (newValue) => {
    props?.onBlur(formatHtml(newValue))
  }

  return (
    <TextareaInputContainer className={props?.className}>
      <TextareaInputTitle
        $required={
          (props?.required || props?.requiredField) &&
          props?.value?.length === 0
        }
      >
        {props?.title || props?.label}
      </TextareaInputTitle>
      {props?.isRichText ? (
        <>
          <LexicalEditor
            readOnly={props?.disabled || props?.disabledField}
            value={props?.value}
            key={props?.value}
            onChange={handleChangeRichText}
            onClickFnc={props?.onClickFnc}
          />
          {/* <Editor
          onBlur={() => props?.onBlur(editorState)}
          editorState={editorState}
          // wrapperClassName="demo-wrapper"
          editorClassName={
            props?.disabled || props?.disabledField
              ? "rich-text-editor-disabled"
              : "rich-text-editor"
          }
          handlePastedText={handlePastedText}
          wrapperClassName={
            props?.disabled || props?.disabledField
              ? "wrapper-text-editor-disabled"
              : "wrapper-text-editor"
          }
          toolbarClassName="toolbar-text-editor"
          onEditorStateChange={onEditorStateChange}
          readOnly={props?.disabled || props?.disabledField}
          toolbar={{
            options: [
              "inline",
              "fontSize",
              // "fontFamily",
              "list",
              "textAlign",
              // "colorPicker",
              // "link",
              // "embedded",
              // "emoji",
              // "image",
              // "remove",
              // "history",
            ],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [
                "bold",
                "italic",
                "underline",
                // "strikethrough",
                // "superscript",
                // "subscript",
              ],
            },

            fontFamily: {
              options: ["Inter"],
            },
          }}
        /> */}
        </>
      ) : (
        <TextareaInputField
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: selectedTheme.colors.disabledFieldText,
            },
            ...props?.sx,
          }}
          disabled={props?.disabled || props?.disabledField}
          width="100%"
          $isErrorShown={props?.error}
          id={props?.name}
          minRows={props?.minRows}
          onChange={props?.onChange}
          onBlur={props?.onBlur}
          value={props?.value}
          placeholder={props?.placeholder}
          {...propsObject}
        />
      )}

      <ErrorMessage>{props?.error} </ErrorMessage>
    </TextareaInputContainer>
  );
};

TextareaInput.propTypes = {
  name: PropTypes.string,
  minRows: PropTypes.number,
  required: PropTypes.bool,
  requiredField: PropTypes.bool,
  disabledField: PropTypes.bool,
  disabled: PropTypes.bool,
  isRichText: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  sx: PropTypes.object,
  onClickFnc: PropTypes.func,
};

TextareaInput.defaultProps = {
  minRows: 3,
  value: "",
  onBlur: () => {},
};

export default TextareaInput;
