import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AddFile,
  AddIcon,
  AdditionalDescription,
  ImagePickerContainer,
  ImagePickerContentContainer,
  ImagePickerDescription,
  ImagePickerDescriptionText,
  ImagePickerLabel,
} from "./ImagePicker.styled";
import { useDropzone } from "react-dropzone";
import { IMAGE_TYPE } from "../../constants/imageTypesConstants";
import ImagePreview from "./ImagePreview/ImagePreview";
import { useDispatch } from "react-redux";
import {
  fetchImages,
  fetchPostImages,
} from "../../store/actions/images/imagesActions";
import { Trans } from "react-i18next";
import IsLoadingComponent from "../Loader/IsLoadingComponent/IsLoadingComponent";
import {
  getImageFormatFromBase64,
  getImageSizes,
} from "../../util/helpers/imageHelpers";

const ImagePicker = (props) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageSizes, setImageSizes] = useState({
    width: props?.width,
    height: props?.height,
  });
  const [isLoadedImage, setIsLoadedImage] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  // Drag and drop image
  const { getRootProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    noClick: true,
    onDrop: (acceptedFiles) => {
      if (!props?.disabled) {
        handleImage(acceptedFiles[0]);
      }
    },
  });

  const setContainerSize = (width, height) => {
    let newWidth = props?.minWidth;
    if (width >= props?.minWidth) {
      if (width <= props?.maxWidth) {
        newWidth = width;
      } else {
        newWidth = props?.maxWidth;
      }
    }
    let newHeight = props?.minHeight;
    if (height >= props?.minHeight) {
      if (height <= props?.maxHeight) {
        newHeight = height;
      } else {
        newHeight = props?.maxHeight;
      }
    }
    setImageSizes({
      width: newWidth + 2,
      height: newHeight + 2,
    });
  };

  useEffect(() => {
    // Set width and height of inputted image
    if (image?.length > 0) {
      const demoImg = new Image();
      demoImg.src = image;
      demoImg.onload = () => {
        setContainerSize(demoImg?.naturalWidth, demoImg?.naturalHeight);
      };
    }
  }, [image]);

  // Template method pattern on handling image
  useEffect(() => {
    setImageUrl(props?.image);
    if (
      props?.image &&
      ((props?.image?.length > 0 && !isLoadedImage) || props?.image !== imageUrl)
    ) {
      getImage(props?.image);
    } else {
      setIsLoadingImage(false);
    }
    if (!isLoadedImage) setIsLoadedImage(true);
  }, [props?.image]);

  // Simulate click on file input
  const handleChange = () => {
    if (!props?.disabled) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const getImage = (image) => {
    dispatch(
      fetchImages({
        images: [image],
        handleApiResponseSuccess: handleApiResponseSuccessGet,
        handleApiResponseError: handleApiResponseErrorGet,
      })
    );
  };

  const handleApiResponseSuccessGet = (images) => {
    setImage(images?.imagesArray?.[0]);
    setIsLoadingImage(false);
  };

  const handleApiResponseErrorGet = () => {
    setIsLoadingImage(false);
    setImage("");
    props?.setImage("");
  };

  const handleApiResponseSuccessUpload = (images) => {
    if (isLoadedImage && props?.setImage) {
      props?.setImage(images?.images?.[0]);
    }
    getImage(images?.images?.[0]);
  };

  // Reads image as both base64 and multipart type
  const handleImage = (file) => {
    setIsLoadingImage(true);
    if (
      file?.type !== IMAGE_TYPE.JPEG &&
      file?.type !== IMAGE_TYPE.JPG &&
      file?.type !== IMAGE_TYPE.PNG
    )
      return;
    let reader = new FileReader();
    // console.log(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      let image = new Image();
      image.src = reader.result;
      image.onload = () => {
        // console.log(image.width);
        let newSizes = getImageSizes(image?.width, image?.height);
        // console.log(newSizes);
        canvas.width = newSizes?.width;
        canvas.height = newSizes?.height;
        context?.drawImage?.(image, 0, 0, newSizes.width, newSizes.height);
        let imageFormat = getImageFormatFromBase64(reader?.result);
        // console.log(imageFormat);
        canvas.toBlob(
          (blob) => {
            let newFile = blob;
            dispatch(
              fetchPostImages({
                images: [
                  new File(
                    [newFile],
                    `${file?.name?.slice(0, file?.name?.lastIndexOf("."))}.${
                      imageFormat?.extension
                    }`,
                    {
                      type: imageFormat?.mimeType,
                    }
                  ),
                ],
                handleApiResponseSuccess: handleApiResponseSuccessUpload,
              })
            );
          },
          imageFormat?.mimeType,
          0.3
        );
      };
    };
    reader.onerror = (error) => {
      console.dir(error);
    };
  };

  const handleDelete = () => {
    setImage("");
    props?.setImage("");
    setImageSizes({
      width: props?.width,
      height: props?.height,
    });
  };

  return (
    <ImagePickerContainer
      className={props?.className}
      onClick={!image ? handleChange : () => {}}
    >
      {props?.label && (
        <ImagePickerLabel $required={props?.required && image?.length === 0}>
          {props?.label}
        </ImagePickerLabel>
      )}
      <ImagePickerContentContainer
        height={imageSizes?.height}
        width={imageSizes?.width}
        $hasImage={image}
        component="form"
        $disabled={props?.disabled}
        {...getRootProps()}
      >
        <IsLoadingComponent isLoading={isLoadingImage}>
          {/* No current image */}
          {/* ****** */}
          {!image && (
            <ImagePickerDescription>
              <ImagePickerDescriptionText>
                {props?.descriptionText}
              </ImagePickerDescriptionText>
              {props?.additionalDescriptionText && (
                <AdditionalDescription>
                  {props?.additionalDescriptionText}
                </AdditionalDescription>
              )}
            </ImagePickerDescription>
          )}
          {/* ****** */}

          <AddFile
            type="file"
            ref={fileInputRef}
            onInput={(event) => handleImage(event.target.files?.[0])}
            accept=".jpg, .jpeg, .png"
            formEncType="multipart/form-data"
          />

          {image ? (
            <ImagePreview
              smallerButtons={props?.smallerButtons}
              ref={imageRef}
              image={image}
              handleDelete={handleDelete}
              handleChange={handleChange}
              label={props?.label}
              disabled={props?.disabled}
              modalTitle={props?.modalTitle}
              disablePreview={props?.disablePreview}
            />
          ) : (
            <AddIcon />
          )}
          {props?.children}
        </IsLoadingComponent>
      </ImagePickerContentContainer>
    </ImagePickerContainer>
  );
};
ImagePicker.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setImage: PropTypes.func,
  image: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  smallerButtons: PropTypes.bool,
  console: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalDescriptionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  required: PropTypes.bool,
  modalTitle: PropTypes.string,
  disablePreview: PropTypes.bool,
};

ImagePicker.defaultProps = {
  descriptionText: <Trans i18nKey={"imagePicker.choose"} />,
  width: 320,
  height: 120,
  maxWidth: 400,
  maxHeight: 400,
  minWidth: 50,
  minHeight: 50,
};
export default ImagePicker;
