import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
// import { selectUsersErrorMessage } from "../../../store/selectors/usersSelectors";
// import { useDispatch } from "react-redux";
// import { setUsersErrorMessage } from "../../../store/actions/user/userActions";

const EmailField = (props) => {
  const formik = props?.formik;

  const { t } = useTranslation();
  // const errorMessage = useSelector(selectUsersErrorMessage);
  // const dispatch = useDispatch();

  // const handleChange = (event) => {
  //   if (errorMessage?.length > 0) dispatch(setUsersErrorMessage(""));
  //   formik?.handleChange(event);
  // };

  return (
    <>
      <TextInputField
        name="email"
        label={t("users.email")}
        placeholder={t("users.newUserPlaceholders.email")}
        value={formik?.values?.email}
        onChange={formik?.handleChange}
        error={formik?.touched?.email && formik?.errors?.email}
        helperText={formik?.touched?.email && formik?.errors?.email}
        requiredField={props?.isRequired}
        disabledField={props?.isReadOnly}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.email &&
                formik?.touched?.email &&
                "40px !important",
              width:
                formik?.errors?.email &&
                formik?.touched?.email &&
                "314px !important",
            },
          },
        }}
      />
      {/* {((formik?.errors?.email && formik?.touched?.email) ||
        errorMessage?.length > 0) && (
        <ErrorMessage>{formik?.errors?.email || errorMessage}</ErrorMessage>
      )} */}
    </>
  );
};

EmailField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default EmailField;
