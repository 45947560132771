import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { UsersTableContainer } from "./UsersTable.styled";
import Table from "../../Table/Table";
import usersTableConstants from "../../../constants/tableConstants/dashboardTables/usersTableConstants";
import { useTranslation } from "react-i18next";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import {
  fetchUsers,
  fetchUsersClear,
} from "../../../store/actions/user/userActions";
import {
  selectTotalActiveUsers,
  selectActiveUsers,
} from "../../../store/selectors/usersSelectors";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import { findRoleByRoleName } from "../../../constants/rolesConstants";
import { useLocation } from "react-router-dom";
import { GET_USERS_SCOPE } from "../../../store/actions/user/userActionConstants";
import RoleDashboardRenderer from "../../Users/UsersTable/ActiveUsersTable/Renderers/RoleDashboardRenderer/RoleDashboardRenderer";

const UsersTable = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const location = useLocation();

  const tableColumns = useMemo(() => {
    return Object.keys(usersTableConstants).map((property) => ({
      ...usersTableConstants[property],
      field: t(usersTableConstants[property].i18key),
      backendProperty:
        usersTableConstants[property]?.backendProperty || property,
      propertyName: property,
      redirect: usersTableConstants[property]?.redirect || false,
      renderer:
        property === "role"
          ? RoleDashboardRenderer
          : usersTableConstants[property]?.renderer || TextRenderer,
    }));
  }, [usersTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) =>
      newData?.map?.((singleData) => ({
        users: `${singleData?.firstName} ${singleData?.lastName}`,
        lastLogin:
          new Date(singleData?.lastLoginDate)?.getFullYear() < 2000
            ? ""
            : formatDateTime(singleData?.lastLoginDate),
        role: singleData?.roles?.map?.((item) => findRoleByRoleName(item)),
        id: singleData?.id,
      })),
    []
  );

  return (
    <UsersTableContainer>
      <Table
        hideAddButton
        hidePaging
        hideHeader
        sortingColumn="lastLoginDate"
        initialSize={4}
        sortDesc
        tableColumns={tableColumns}
        isStatic
        loadingActionType={GET_USERS_SCOPE}
        dispatchFunction={fetchUsers}
        initialPage={location?.state?.page}
        initialSearchValue={location?.state?.searchValue}
        customPayload={{ isActive: true }}
        dataSelector={selectActiveUsers}
        totalDataSelector={selectTotalActiveUsers}
        mapDataFunction={mapDataFunction}
        clearDispatchFunction={fetchUsersClear}
        ref={tableRef}
      />
    </UsersTableContainer>
  );
};

UsersTable.propTypes = {
  children: PropTypes.node,
};

export default UsersTable;
