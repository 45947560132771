import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AddStandardsContainer,
  StandardsInputsLabel,
} from "./TemplateStandardsEditor.styled";
import { TableTitle } from "../ActiveTemplateEditor.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectSingleScanningObjectStandards } from "../../../../store/selectors/scanningObjectsSelectors";
import {
  addScanningObjectStandards,
  deleteScanningObjectStandards,
  setScanningObjectsChanged,
} from "../../../../store/actions/scanningObjects/scanningObjectsActions";
import TemplateStandardsPicker from "./TemplateStandardsPicker/TemplateStandardsPicker";
import TemplateStandardsTable from "./TemplateStandardsTable/TemplateStandardsTable";

const TemplateStandardsEditor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedStandards = useSelector(selectSingleScanningObjectStandards);
  console.log(selectedStandards?.length)


  const handleAddStandard = (standard) => {
    if (
      selectedStandards?.find?.(
        (singleStandard) => singleStandard?.id === standard?.id
      )
    )
      return;
    dispatch(addScanningObjectStandards(standard));
    dispatch(setScanningObjectsChanged(true));
  };

  const handleRemoveStandard = useCallback(
    (standard) => {
      if (
        selectedStandards?.find?.(
          (singleStandard) => singleStandard?.id === standard?.id
        )
      ) {
        dispatch(deleteScanningObjectStandards(standard?.id));
        dispatch(setScanningObjectsChanged(true));
      }
    },
    [selectedStandards]
  );

  return (
    <AddStandardsContainer>
      <TableTitle>{t("standards.title")}</TableTitle>
      <StandardsInputsLabel>
        {t("scanningObjects.newScanningObject.templateEditor.addStandard")}
      </StandardsInputsLabel>
      <TemplateStandardsPicker
        handleAddStandard={handleAddStandard}
        data={selectedStandards}
      />

      <TemplateStandardsTable
        custom
        data={selectedStandards}
        handleRemoveStandard={handleRemoveStandard}
        isReadOnly={props?.isReadOnly}
      />
    </AddStandardsContainer>
  );
};

TemplateStandardsEditor.displayName = "TemplateStandardsEditor";

TemplateStandardsEditor.propTypes = {
  children: PropTypes.node,
  selectedStandards: PropTypes.array,
  setSelectedStandards: PropTypes.func,
  isReadOnly: PropTypes.bool,
};
TemplateStandardsEditor.defaultProps = {
  selectedStandards: [],
};

export default TemplateStandardsEditor;
