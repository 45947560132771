import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../TextInputField/TextInputField";
import Tooltip from "../../../Tooltip/Tooltip";

const CompanyNameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={
        formik?.values?.companyName?.length > 41
          ? formik?.values?.companyName
          : ""
      }
    >
      <TextInputField
        name="companyName"
        label={t("companySettings.companyName")}
        value={formik?.values?.companyName}
        onChange={formik?.handleChange}
        error={formik?.touched?.companyName && formik?.errors?.companyName}
        helperText={formik?.touched?.companyName && formik?.errors?.companyName}
        requiredField={props?.isRequired}
      />
    </Tooltip>
  );
};

CompanyNameField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default CompanyNameField;
