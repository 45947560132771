import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  FindingStatusCardsChainContainer,
  FindingStatusCardsChainHorizontalSeparator,
  FindingStatusCardsChainVerticalSeparator,
  FindingStatusCardsConnectedChain,
  TooltipContainer,
} from "./FindingStatusCardsChain.styled";
import {
  getAllStatusColors,
  statusTableConstants,
} from "../../../../../constants/findingStatusConstants";
import FindingStatusCard from "../../../../Cards/FindingStatus/FindingStatusCard";
import i18n from "../../../../../i18n";
import useAuth from "../../../../../hooks/useAuth";
import { useSelector } from "react-redux";
import {
  selectFindingSavingDetails,
  selectSingleFindingOld,
} from "../../../../../store/selectors/findingsSelectors";
import Tooltip from "../../../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../hooks/useIsMobile";

const FindingStatusCardsChain = (props) => {
  const { isManager } = useAuth();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const singleFindingOld = useSelector(selectSingleFindingOld);
  const findingDetails = useSelector(selectFindingSavingDetails);

  const completedTooltip = useMemo(() => {
    if (findingDetails?.isCompleteStateAllowed) return "";
    return findingDetails?.itemsToEdit?.map?.((singleItem, index) => {
      return (
        <TooltipContainer key={index}>
          {t("findings.notFilledMandatoryField", {
            sectionName: singleItem?.section?.name,
            scanningSubjectOrderNumber: singleItem?.subject?.index + 1,
            questionOrderNumber: singleItem?.item?.index + 1,
          })}
          <br></br>
        </TooltipContainer>
      );
    });
  }, [findingDetails]);

  // Current active status
  const currentActiveStatus = useMemo(() => {
    return !props?.isEditing
      ? statusTableConstants.inProgress.id
      : props?.currentActiveStatus;
  }, [props?.isEditing, props?.currentActiveStatus]);

  // Get status colors and props
  const statuses = useMemo(() => {
    if (!props?.isEditing) {
      return getAllStatusColors({ outsideTable: true, selected: true }).slice(
        0,
        1
      );
    }
    return getAllStatusColors(
      {
        outsideTable: true,
      },
      [
        {
          disabled:
            singleFindingOld?.findingStateId ===
              statusTableConstants.closed.id ||
            (singleFindingOld?.findingStateId ===
              statusTableConstants.cancelled.id &&
              !isManager),
          selected: currentActiveStatus === statusTableConstants.inProgress.id,
        },
        {
          disabled:
            (singleFindingOld?.findingStateId ===
              statusTableConstants.closed.id &&
              !isManager) ||
            singleFindingOld?.findingStateId ===
              statusTableConstants.cancelled.id ||
            !findingDetails?.isCompleteStateAllowed,
          selected: currentActiveStatus === statusTableConstants.completed.id,
          tooltip: completedTooltip,
        },
        {
          disabled:
            [
              statusTableConstants.inProgress.id,
              statusTableConstants.cancelled.id,
            ].includes(singleFindingOld?.findingStateId) ||
            (singleFindingOld?.findingStateId ===
              statusTableConstants.completed.id &&
              !isManager),
          selected: currentActiveStatus === statusTableConstants.closed.id,
        },
        {
          disabled:
            singleFindingOld?.findingStateId === statusTableConstants.closed.id,
          selected: currentActiveStatus === statusTableConstants.cancelled.id,
        },
      ]
    );
  }, [
    props?.isEditing,
    currentActiveStatus,
    singleFindingOld?.findingStateId,
    findingDetails,
  ]);

  // Statuses shown on the left
  const leftStatuses = useMemo(() => {
    return !props?.isEditing ? statuses : statuses.slice(0, 3);
  }, [statuses]);

  // Statuses shown on the right
  const rightStatuses = useMemo(() => {
    return !props?.isEditing ? [] : statuses.slice(3);
  }, [statuses]);

  // Change status
  const handleChangeStatus = (item, newStatusId) => {
    if (!item?.disabled && newStatusId !== currentActiveStatus) {
      props?.handleChangeStatus(newStatusId);
    }
  };

  return (
    <FindingStatusCardsChainContainer>
      <FindingStatusCardsConnectedChain>
        {leftStatuses.map((item, index) => (
          <React.Fragment key={index}>
            <Tooltip
              title={item?.tooltip}
              maxWidth={isMobile ? "90vw" : "none"}
            >
              <FindingStatusCard
                statusBackgroundColor={item.colors.backgroundColor}
                statusTextColor={item.colors.color}
                borderWidth={currentActiveStatus === index + 1 ? 2 : 0.5}
                borderColor={item.colors.borderColor}
                circleColor={item.colors.circleColor}
                value={i18n.t(item.i18key)}
                style={{ cursor: "pointer" }}
                onClick={() => handleChangeStatus(item, index + 1)}
              />
            </Tooltip>
            {index !== leftStatuses?.length - 1 && (
              <FindingStatusCardsChainHorizontalSeparator />
            )}
          </React.Fragment>
        ))}
      </FindingStatusCardsConnectedChain>

      {props?.isEditing && (
        <>
          <FindingStatusCardsChainVerticalSeparator />

          {rightStatuses.map((item, index) => (
            <React.Fragment key={index}>
              <FindingStatusCard
                statusBackgroundColor={item.colors.backgroundColor}
                statusTextColor={item.colors.color}
                style={{ cursor: "pointer" }}
                borderWidth={
                  currentActiveStatus === statusTableConstants.cancelled.id
                    ? 2
                    : 0.5
                }
                onClick={() =>
                  handleChangeStatus(item, statusTableConstants.cancelled.id)
                }
                borderColor={item.colors.borderColor}
                circleColor={item.colors.circleColor}
                value={i18n.t(item.i18key)}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </FindingStatusCardsChainContainer>
  );
};

FindingStatusCardsChain.propTypes = {
  findingNumber: PropTypes.string,
  isEditing: PropTypes.bool,
  currentActiveStatus: PropTypes.number,
  handleChangeStatus: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default FindingStatusCardsChain;
