import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ErrorMessage,
  NewUserInputlabel,
  RolesContainer,
} from "../NewUserForm.styled";
import Checkbox from "../Checkbox/Checkbox";
import rolesConstants from "../../../constants/rolesConstants";

const RolePicker = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const handleChangeRoles = (role) => {
    if (formik?.values?.userRole?.find?.((checkedRole) => checkedRole === role)) {
      formik?.setFieldValue?.(
        "userRole",
        formik?.values?.userRole?.filter?.(
          (oldCheckedRole) => oldCheckedRole !== role
        )
      );
    } else {
      formik?.setFieldValue?.("userRole", [...formik?.values?.userRole, role]);
    }
  };
  return (
    <>
      <NewUserInputlabel
        $required={props?.isRequired && !formik?.values?.userRole.length > 0}
      >
        {t("users.userRole")}
      </NewUserInputlabel>
      <RolesContainer>
        {rolesConstants.map((singleRole) => {
          return (
            <Checkbox
              key={singleRole.roleName}
              name={singleRole.roleName}
              checked={
                formik?.values?.userRole?.find?.(
                  (formikValue) => singleRole.roleName === formikValue
                ) !== undefined
              }
              label={t(singleRole.i18key)}
              onChange={() => handleChangeRoles(singleRole.roleName)}
              error={formik?.errors?.userRole && formik?.touched?.userRole}
              helperText={formik?.errors?.userRole && formik?.touched?.userRole}
              disabled={props?.isReadOnly}
            />
          );
        })}
      </RolesContainer>
      {formik?.errors?.userRole && formik?.touched?.userRole && (
        <ErrorMessage>{formik?.errors?.userRole}</ErrorMessage>
      )}
    </>
  );
};

RolePicker.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default RolePicker;
