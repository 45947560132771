import React from "react";
import PropTypes from "prop-types";
import { Title } from "./PageTitle.styled";

const PageTitle = (props) => {
  return <Title>{props?.children}</Title>;
};

PageTitle.propTypes = {
  children: PropTypes.node,
};

export default PageTitle;
