import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ButtonsAreaContainer,
  ButtonsContainer,
  TitleButtonsContainer,
  NewClientFormContainer,
  NewClientInputsContainer,
  NewClientLeftColumn,
  NewClientRightColumn,
  RequiredFieldsText,
} from "./NewClientForm.styled";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import { useFormik } from "formik";
import { newClientInitialValues } from "../../../initialValues/newClientInitialValues";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import history from "../../../store/utils/history";
import {
  fetchClients,
  fetchDeleteClient,
} from "../../../store/actions/clients/clientsActions";
import newClientValidationSchema from "../../../validations/newClientValidationSchema";
import { removeSemicolonsAtEnd } from "../../../util/helpers/stringHelpers";
import { CLIENTS_PAGE, EDIT_CLIENT_PAGE } from "../../../constants/pages";
import PageTitle from "../../FormPageTitle/PageTitle";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import ClientNameField from "./ClientNameField/ClientNameField";
import TinField from "./TinField/TinField";
import AddressField from "./AddressField/AddressField";
import PhoneNumberField from "./PhoneNumberField/PhoneNumberField";
import IdNumberField from "./IdNumberField/IdNumberField";
import ContactEmailField from "./ContactEmailField/ContactEmailField";
import DistributionMailField from "./DistributionMailField/DistributionMailField";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useIsMobile from "../../../hooks/useIsMobile";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import { useMemo } from "react";

const NewClientForm = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const routeMatch = useRouteMatch();
  const { t } = useTranslation();
  const clientId = routeMatch.params?.clientId;

  const goBack = ({ name, isDeleted }) => {
    dispatch(closeModal());

    history.replace(CLIENTS_PAGE, {
      forceRefetch: true,
      name,
      isDeleted,
      ...location?.state,
    });
  };

  const handleApiResponseSuccess = (payload) => {
    if (!payload?.isDeleted) {
      dispatch(closeModal());
      const titleI18Key = props?.isEditing
        ? "toast.success.editedTitle"
        : "toast.success.createdTitle";
      const descriptionI18Key = props?.isEditing
        ? "toast.success.editedDescription"
        : "toast.success.createdDescription";
      makeToastMessage(
        {
          title: t(titleI18Key, {
            typeOfData: t("clients.typeOfData"),
          }),
          description: t(descriptionI18Key, {
            name: payload.name,
            typeOfData: t("clients.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      if (!props?.isEditing) {
        history.replace(
          replaceInRoute(EDIT_CLIENT_PAGE, {
            clientId: payload?.id,
          })
        );
      } else {
        history.replace(
          replaceInRoute(EDIT_CLIENT_PAGE, {
            clientId: clientId,
          })
        );
      }
    } else {
      dispatch(
        fetchClients({
          page: history?.location?.state?.page || 1,
          forceRefetch: true,
          handleApiResponseSuccess: () => goBack(payload),
        })
      );
    }
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const clientData = {
      address: values?.address,
      name: values?.clientName?.replace?.(/  +/g, " ")?.trim?.(),
      tin: String(values?.pib?.replace?.(/  +/g, " ")?.trim?.()),
      identificationNumber: String(values?.idNumber?.replace?.(/  +/g, " ").trim?.()),
      email: values?.contactEmail,
      distributionEmails: removeSemicolonsAtEnd(
        values?.distributionEmails?.join?.(";")
      ),
      phoneNumber: String(values?.phoneNumber),
    };
    // const clientId = routeMatch.params?.clientId;
    dispatch(
      props?.dispatchFunction({
        clientData,
        clientId,
        handleApiResponseSuccess: (clientId) =>
          handleApiResponseSuccess({
            name: values?.clientName,
            isDeleted: false,
            id: clientId,
          }),
        handleApiResponseError: handleApiResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: newClientInitialValues(props?.prefilledData),
    validationSchema: newClientValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleDeleteClient = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteClient({
        clientId: routeMatch?.params?.clientId,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            name: formik?.values?.clientName,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <NewClientFormContainer onSubmit={formik?.handleSubmit}>
      <TitleButtonsContainer>
        <PageTitle
          title={props?.title}
          isEditing={props?.isEditing}
          isLoading={props?.isLoading}
          onSubmit={handleDeleteClient}
          dispatch={dispatch}
          disableActions={props?.readOnly}
          tooltipTitle="clients.deleteClient"
          modalTitle="clients.modal.delete.title"
          modalDescription={{
            first: "clients.modal.delete.description.1",
            second: "clients.modal.delete.description.2",
          }}
        />
        {!props?.readOnly && !isMobile && (
          <ButtonsAreaContainer>
            <ButtonsContainer>
              <PrimaryButton onClick={() => history.goBack()}>
                {t("clients.cancel")}
              </PrimaryButton>
              <PrimaryButton
                type="submit"
                variant="contained"
                disabled={isSavingEnabled}
              >
                {t("common.save")}
              </PrimaryButton>
            </ButtonsContainer>
            <RequiredFieldsText>
              {t("common.requiredFieldsText")}
            </RequiredFieldsText>
          </ButtonsAreaContainer>
        )}
      </TitleButtonsContainer>
      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <NewClientInputsContainer>
            <NewClientLeftColumn>
              <ClientNameField formik={formik} isReadOnly={props?.readOnly} />
              <TinField formik={formik} isReadOnly={props?.readOnly} />
              <AddressField formik={formik} isReadOnly={props?.readOnly} />
              <PhoneNumberField formik={formik} isReadOnly={props?.readOnly} />
              <IdNumberField formik={formik} isReadOnly={props?.readOnly} />
            </NewClientLeftColumn>

            <NewClientRightColumn>
              <ContactEmailField formik={formik} isReadOnly={props?.readOnly} />
              <DistributionMailField
                formik={formik}
                isReadOnly={props?.readOnly}
              />
            </NewClientRightColumn>
          </NewClientInputsContainer>
          {/* {!props?.readOnly && (
            <ButtonsAreaContainer>
              <RequiredFieldsText>
                {t("common.requiredFieldsText")}
              </RequiredFieldsText>
              <ButtonsContainer>
                <PrimaryButton onClick={() => history.goBack()}>
                  {t("clients.cancel")}
                </PrimaryButton>
                <PrimaryButton type="submit" variant="contained">
                  {t("common.save")}
                </PrimaryButton>
              </ButtonsContainer>
            </ButtonsAreaContainer>
          )} */}
        </>
      )}
      {!props?.readOnly && isMobile && (
        <ButtonsAreaContainer>
          <ButtonsContainer>
            <PrimaryButton onClick={() => history.goBack()}>
              {t("clients.cancel")}
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              variant="contained"
              disabled={isSavingEnabled}
            >
              {t("common.save")}
            </PrimaryButton>
          </ButtonsContainer>
          <RequiredFieldsText>
            {t("common.requiredFieldsText")}
          </RequiredFieldsText>
        </ButtonsAreaContainer>
      )}
    </NewClientFormContainer>
  );
};

NewClientForm.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  prefilledData: PropTypes.any,
  dispatchFunction: PropTypes.func,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default NewClientForm;
