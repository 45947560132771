import * as Yup from "yup";
import i18n from "../i18n";
import { loginFormikConstants } from "../initialValues/loginInitialValues";

export default Yup.object().shape({
  [loginFormikConstants.username]: Yup.string()
    // .email(i18n.t("login.emailFormat"))
    .required(i18n.t("login.usernameRequired")),
  [loginFormikConstants.password]: Yup.string().required(
    i18n.t("login.passwordRequired")
  ),
});
