import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import IsLoadingComponent from "../../Loader/IsLoadingComponent/IsLoadingComponent";

export const FindingPreviewModalContainer = styled(Box)`
  padding: 32px;
  position: absolute;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 500px;
  gap: 12px;
  z-index: ${(props) => "15" + props.rank || "151"};
  @media (max-width: 600px) {
    width: 90vw;
    max-height: 100vh;
  }
`;
export const FindingPreviewContainer = styled.iframe`
  position: relative;
  overflow: hidden;
  max-width: 700px;
  width: 700px;
  height: 700px;
  padding: 10px;
  border: 1px solid ${selectedTheme.colors.borderColor};
  border-radius: 12px;
  & body {
    overflow: hidden;
  }
  @media (max-width: 600px) {
    width: 100%;
    overflow: auto;
  }
`;
export const LoadingWrapper = styled(IsLoadingComponent)`
  flex: 1;
`;
export const ButtonsContainer = styled(Box)``;
