import { Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const RedirectModalRendererContainer = styled(Typography)`
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${selectedTheme.fonts.mainText};
  padding-right: ${(props) =>
    props.$mobile ? (props.$smallerText ? "80px" : "50px") : 0};
`;
