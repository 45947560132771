import React, { useState } from "react";
import PropTypes from "prop-types";
import { TemplateSectionsListItemContainer } from "./ListItem.styled";
import TemplateSectionsListItemDetails from "./ListItemEdit/ListItemDetails/ListItemDetails";
import TemplateSectionsListItemEdit from "./ListItemEdit/ListItemEdit";
import OrdinalNumberButton from "../../../../../Button/OrdinalNumberButton/OrdinalNumberButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteScanningObjectSingleSection,
  moveScanningObjectSingleSection,
  setScanningObjectSingleSection,
  setScanningObjectsChanged,
} from "../../../../../../store/actions/scanningObjects/scanningObjectsActions";
import { selectSingleScanningObjectAllSections } from "../../../../../../store/selectors/scanningObjectsSelectors";

const TemplateSectionsListItem = (props) => {
  const [isEditing, setIsEditing] = useState(props?.isEditing);
  const [title, setTitle] = useState(props?.section?.title);
  const dispatch = useDispatch();
  const allSections = useSelector(selectSingleScanningObjectAllSections);

  const handleStartEditing = () => {
    setIsEditing(true);
  };
  const handleEndEditing = (value) => {
    if (value) {
      setTitle(value);
      if (props?.isEditing) {
        props?.handleAddSection(value);
      } else
        dispatch(
          setScanningObjectSingleSection({
            section: { ...props?.section, title: value },
          })
        );
    }
    if (props?.isEditing) {
      props?.handleCancelAddingSection();
    }
    setIsEditing(false);
    dispatch(setScanningObjectsChanged(true));
  };

  const handleMoveSection = (backwards = false) => {
    const indexOfSection = allSections?.findIndex?.(
      (singleSection) => singleSection?.id === props?.section?.id
    );
    if (indexOfSection === -1) return;
    if (indexOfSection === 0 && backwards) return;
    if (indexOfSection === allSections?.length - 1 && !backwards) return;
    dispatch(
      moveScanningObjectSingleSection({
        sectionId: props?.section?.id,
        backwards,
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };

  const handleDeleteSection = () => {
    dispatch(deleteScanningObjectSingleSection(props?.section?.id));
    dispatch(setScanningObjectsChanged(true));
  };

  return (
    <TemplateSectionsListItemContainer>
      {props?.ordinalNumber && (
        <OrdinalNumberButton
          number={props?.ordinalNumber}
          onBelowArrowClick={() => handleMoveSection(true)}
          onAboveArrowClick={() => handleMoveSection()}
          disabled={props?.isReadOnly}
        />
      )}
      {isEditing ? (
        <TemplateSectionsListItemEdit
          handleEndEditing={handleEndEditing}
          oldValue={title}
        />
      ) : (
        <TemplateSectionsListItemDetails
          title={title}
          handleStartEditing={handleStartEditing}
          chosen={props?.chosen}
          handleChooseSection={props?.handleChooseSection}
          handleDeleteSection={handleDeleteSection}
          isReadOnly={props?.isReadOnly}
        />
      )}
    </TemplateSectionsListItemContainer>
  );
};

TemplateSectionsListItem.propTypes = {
  children: PropTypes.node,
  section: PropTypes.object,
  chosen: PropTypes.bool,
  handleChooseSection: PropTypes.func,
  ordinalNumber: PropTypes.number,
  handleMoveSection: PropTypes.func,
  isEditing: PropTypes.bool,
  handleAddSection: PropTypes.func,
  handleEditSection: PropTypes.func,
  handleCancelAddingSection: PropTypes.func,
  handleDeleteSection: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

TemplateSectionsListItem.defaultProps = {
  isEditing: false,
};

export default TemplateSectionsListItem;
