import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";
import { ErrorMessage } from "../NewClientForm.styled";

const IdNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="idNumber"
        placeholder={t("clients.newClientPlaceholders.idNumber")}
        label={t("clients.idNumber")}
        number
        value={formik?.values?.idNumber}
        onChange={formik?.handleChange}
        requiredField={
          newClientValidationSchema?.fields?.idNumber?.exclusiveTests?.required
        }
        error={formik?.errors?.idNumber && formik?.touched?.idNumber}
        helperText={formik?.errors?.idNumber && formik?.touched?.idNumber}
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.idNumber && formik?.touched?.idNumber && (
        <ErrorMessage>{formik?.errors?.idNumber}</ErrorMessage>
      )}
    </>
  );
};

IdNumberField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default IdNumberField;
