import * as Yup from "yup";
import i18n from "../i18n";

export default Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(255, i18n.t("stuffs.firstNameNumberOfCharacters"))
    .required(i18n.t("registerUser.firstNameRequired")),
  lastName: Yup.string()
    .trim()
    .max(255, i18n.t("stuffs.lastNameNumberOfCharacters"))
    .required(i18n.t("registerUser.lastNameRequired")),
  rank: Yup.string().max(255, i18n.t("stuffs.rankNumberOfCharacters")),
  email: Yup.string()
    .trim()
    .email(i18n.t("clients.emailFormat"))
    .max(255, i18n.t("stuffs.emailNumberOfCharacters"))
    .required(i18n.t("stuffs.emailRequired")),
  user: Yup.string(),
  licenseUrl: Yup.string(),
  signatureUrl: Yup.string(),
  licenseNumber: Yup.string().max(255, i18n.t("stuffs.licenseNumberNumberOfCharacters")),
});
