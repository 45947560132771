import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AddButton,
  StandardInputsContainer,
} from "./TemplateStandardsPicker.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PlusCircledIcon from "../../../../Icon/Icons/PlusCircledIcon";
import Tooltip from "../../../../Tooltip/Tooltip";
import { selectStandardsData } from "../../../../../store/selectors/standardsSelectors";
import { fetchStandards } from "../../../../../store/actions/standards/standardsActions";
import {
  selectScanningObjectType,
  selectSelectedChecklistTemplate,
} from "../../../../../store/selectors/scanningObjectsSelectors";
import { addScanningObjectDefaultStandards } from "../../../../../store/actions/scanningObjects/scanningObjectsActions";
import { ASC_KEY, CAPTION_KEY } from "../../../../../constants/queryConstants";
import AutocompleteField from "../../../../Field/AutocompleteField/AutocompleteField";

const TemplateStandardsPicker = (props) => {
  const { t } = useTranslation();
  const [selectedStandard, setSelectedStandard] = useState(null);
  const standards = useSelector(selectStandardsData);
  console.log(standards?.length);
  const checklistTemplate = useSelector(selectSelectedChecklistTemplate);
  const subjectType = useSelector(selectScanningObjectType);
  const dispatch = useDispatch();

  const handleAddStandard = () => {
    if (selectedStandard) {
      props?.handleAddStandard(selectedStandard);
    }
    setSelectedStandard();
  };

  useEffect(() => {
    dispatch(
      fetchStandards({
        page: 1,
        size: 200,
        sorting: {
          column: CAPTION_KEY,
          sortDirection: ASC_KEY,
        },
        findingTypeId: subjectType?.id,
      })
    );
    setSelectedStandard(null);
  }, [subjectType]);

  useEffect(() => {
    if (standards?.length > 0 && checklistTemplate?.added) {
      const defaultStandards = standards?.filter?.(
        (standard) => standard?.isDefault
      );
      dispatch(addScanningObjectDefaultStandards(defaultStandards));
    }
  }, [standards, checklistTemplate?.added, subjectType]);

  return (
    <StandardInputsContainer>
      <AutocompleteField
        options={standards?.filter?.(
          (singleStandard) =>
            props?.data?.find(
              (singleStandardToFind) =>
                singleStandardToFind?.id === singleStandard?.id
            ) === undefined
        )}
        label={t(
          "scanningObjects.newScanningObject.templateEditor.pickStandard"
        )}
        onChange={(value) => setSelectedStandard(value)}
        dropdownLabel={["caption"]}
        value={selectedStandard}
        removeMaxWidth
      />
      <Tooltip title={t("scanningObjects.tooltip.addStandard")}>
        <AddButton onClick={handleAddStandard}>
          <PlusCircledIcon />
        </AddButton>
      </Tooltip>
    </StandardInputsContainer>
  );
};

TemplateStandardsPicker.propTypes = {
  handleAddStandard: PropTypes.func,
  data: PropTypes.array,
};

TemplateStandardsPicker.defaultProps = {
  data: [],
};

export default TemplateStandardsPicker;
