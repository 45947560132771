import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { hexToRGB } from "../../../../../util/helpers/colorHelper";

export const EmptyScanningObjectPickerContainer = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.isActiveTemplate ? "row" : "column")};
  border: 1px solid ${selectedTheme.colors.tableBorder};
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  border-radius: 6px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  align-items: center;
  & * {
    margin: 0 auto;
  }
  width: 739px;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const EmptyScanningObjectAltTextContainer = styled(Box)`
  display: flex;
  margin-left: ${(props) => (props.isActiveTemplate ? "-160px" : "auto")};
  @media (max-width: 600px) {
    margin-top: 8px;
    margin-left: auto;
  }
`;

export const EmptyScanningObjectAltText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  margin-bottom: ${(props) => (props.isActiveTemplate ? "0px" : "10px")};
  color: ${selectedTheme.colors.emptyMeasuringInstrumentsPlaceholder};
`;

export const EmptyScanningObjectAltTextLink = styled(Typography)`
  color: ${selectedTheme.colors.linkColor};
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
`;
