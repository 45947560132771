import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SetPasswordModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: ${(props) => "15" + props.rank || "151"};
  width: 476px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & * {
    text-align: left;
  }
`;

export const PasswordValidationText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 21px;
  color: ${selectedTheme.colors.placeholderColor};
`;
