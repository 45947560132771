import {
  attemptFetchImages,
  attemptUploadImages,
} from "../../request/imagesRequest";
import {
  GET_IMAGES_FETCH,
  POST_IMAGES_FETCH,
} from "../actions/images/imagesActionConstant";
import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
} from "@redux-saga/core/effects";
import {
  fetchImagesError,
  fetchImagesSuccess,
  fetchPostImagesError,
  fetchPostImagesSuccess,
} from "../actions/images/imagesActions";

function* fetchImages({ payload }) {
  try {
    const response = yield all(
      payload.images.map((image) => {
        return call(
          attemptFetchImages,
          {
            imagePath: image,
          },
          {
            responseType: "blob",
          }
        );
      })
    );
    const blobs = response.map(
      (entry) => new Blob([entry.data], { type: entry.headers["Content-Type"] })
    );
    const images = blobs.map((blob) => URL.createObjectURL(blob));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess, {
        imagesArray: images,
      });
    }
    yield put(fetchImagesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchImagesError(errorMessage));
  }
}

function* fetchPostImages({ payload }) {
  try {
    for (let i = 0; i < payload?.images?.length; i++) {
      const formData = new FormData();
      formData.append("images", payload?.images?.[i]);
      const { data } = yield call(attemptUploadImages, formData);
      if (payload.handleApiResponseSuccess) {
        yield call(payload.handleApiResponseSuccess, {
          images: data,
        });
      }
    }
    yield put(fetchPostImagesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchPostImagesError(errorMessage));
  }
}

export default function* imagesSaga() {
  yield all([
    takeEvery(GET_IMAGES_FETCH, fetchImages),
    takeLatest(POST_IMAGES_FETCH, fetchPostImages),
  ]);
}
