import {
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
  deleteRequest,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchStuffs = (payload) =>
  getRequest(apiEndpoints.stuffs.getStuffs, payload);

export const attemptCreateStuff = (payload) =>
  postRequest(apiEndpoints.stuffs.createStuff, payload);

export const attemptFetchSingleStuff = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.stuffs.getSingleStuff, {
      stuffId: payload.stuffId,
    })
  );

export const attemptEditSingleStuff = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.stuffs.editStuff, {
      stuffId: payload.stuffId,
    }),
    payload.data
  );

export const attemptUploadStuffLicense = (payload) =>
  postRequest(apiEndpoints.images.postImages, payload);

export const attemptDeleteStuff = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.stuffs.deleteStuff, {
      stuffId: payload.stuffId,
    })
  );
