import * as Yup from "yup";
import i18n from "../i18n";

export default Yup.object().shape({
  caption: Yup.string()
    .trim()
    .max(255, i18n.t("standards.modal.validationMessage"))
    .required(i18n.t("standards.modal.errorMessage")),
  isDefault: Yup.bool(),
  findingTypes: Yup.array(),
});
