import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import i18next from "i18next";

export function formatDate(date, fmt = "dd.MM.yyyy", locale = enUS) {
  const dt = new Date(date?.toString());
  return format(dt, fmt, { locale });
}
export function getDateWithoutTimezone(date) {
  if (typeof date === "object") {
    return date?.toISOString?.().indexOf?.("Z") === -1
      ? date?.toISOString?.()
      : date?.toISOString?.()?.slice?.(0, -1);
  }
  return date?.indexOf?.("Z") === -1 ? date : date?.slice?.(0, -1);
}

export function isEqualDates(date1, date2) {
  console.log(date1);
  console.log(date2);
  return (
    date1?.getFullYear?.() === date2?.getFullYear?.() &&
    date1?.getFullYear?.() != null &&
    date1?.getMonth?.() === date2?.getMonth?.() &&
    date1?.getMonth?.() != null &&
    date1?.getDate?.() === date2?.getDate?.() &&
    date1?.getDate?.() != null
  );
}

export function formatDateTime(
  date,
  options = {
    convertToLocalTime: true,
  }
) {
  if (!date) return;
  let dt = new Date(date);
  if (options?.convertToLocalTime) {
    dt = convertUTCDateToLocalDate(dt);
  }
  return format(dt, "dd.MM.yyyy HH:mm");
}

export function getDateDay(date) {
  const dt = new Date(date);
  return format(dt, "dd");
}

export function getDateMonth(date) {
  const dt = new Date(date);
  return format(dt, "MM");
}

export function getDateYear(date) {
  const dt = new Date(date);
  return format(dt, "y");
}

export function formatDateTimeLocale(date) {
  const dt = new Date(date);
  return format(dt, "MM/dd/y hh:mm aa");
}

// TODO add locale
export function formatDateRange(dates) {
  const start = formatDate(dates.start);
  const end = formatDate(dates.end);
  return i18next.t("common.date.range", { start, end });
}

export function convertDateToISO(date) {
  return new Date(
    new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000
  ).toISOString();
}
export function convertUTCDateToLocalDate(date) {
  let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export function convertLocalDateToUTCDate(date) {
  let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours + offset);

  return newDate;
}

export function constructDate(date) {
  var dateArray = date.split(".");
  return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
}
