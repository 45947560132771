import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newStuffValidationSchema from "../../../../validations/newStuffValidationSchema";
import { ErrorMessage } from "../NewStuffForm.styled";

const LastNameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="lastName"
        label={t("stuffs.lastName")}
        placeholder={t("stuffs.newStuffPlaceholders.lastName")}
        value={formik?.values?.lastName}
        onChange={formik?.handleChange}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.lastName &&
                formik?.touched?.lastName &&
                "40px !important",
              width:
                formik?.errors?.lastName &&
                formik?.touched?.lastName &&
                "314px !important",
            },
          },
        }}
        error={formik?.errors?.lastName && formik?.touched?.lastName}
        helperText={formik?.errors?.lastName && formik?.touched?.lastName}
        requiredField={
          newStuffValidationSchema?.fields?.lastName?.exclusiveTests?.required
        }
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.lastName && formik?.touched?.lastName && (
        <ErrorMessage>{formik?.errors?.lastName}</ErrorMessage>
      )}
    </>
  );
};

LastNameField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default LastNameField;
