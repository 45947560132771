import React from "react";
import { toast } from "react-toastify";
import ToastComponent from "../../components/Toast/ToastComponent";
import {
  APPLICATION_TOAST_CONTAINER_ID,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "../../constants/toastConstants";

const defaultOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
  draggable: true,
  closeButton: false,
  containerId: APPLICATION_TOAST_CONTAINER_ID,
};

export const makeToastMessage = (toastContent, options) => {
  const toastOptions = {
    ...defaultOptions,
    ...options,
  };
  if (typeof toastContent === "string") {
    toast(toastContent, toastOptions);
  } else
    toast(
      <ToastComponent
        title={toastContent.title}
        description={toastContent.description}
        type={TOAST_SUCCESS}
      />,
      toastOptions
    );
};
export const makeErrorToastMessage = (
  toastContent,
  options = defaultOptions
) => {
  const toastOptions = {
    ...defaultOptions,
    ...options,
  };
  if (typeof toastContent === "string") {
    toast(toastContent, toastOptions);
  } else
    toast(
      <ToastComponent
        title={toastContent.title}
        description={toastContent.description}
        type={TOAST_ERROR}
        pre={toastOptions?.pre}
      />,
      toastOptions
    );
};
