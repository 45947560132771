import styled from "styled-components";
import { Box, TextField } from "@mui/material";
import selectedTheme from "../../../../themes";
import Icon from "../../../Icon/Icon";

export const DistributionMailContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  position: relative;
`;
export const InputRightElement = styled(Icon)`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 13.3px;
  right: 15px;
  & svg {
    width: 18px;
    height: 18px;
  }
`;
export const DistributionInputField = styled(TextField)`
  width: 100%;
  & input {
    /* width: ${(props) =>
      props.type === "checkbox" ? "fit-content" : "100%"}; */
    height: 20px;
    width: 100%;
    padding: 12px 16px;
    border: 2px solid ${selectedTheme.colors.borderColor};
    border-radius: 12px;
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 14px;
    border: 1px solid
      ${(props) =>
        props?.$isErrorShown
          ? selectedTheme.colors.errorBorderColor
          : selectedTheme.colors.inputBorderColor};
  }
  @media (max-width: 600px) {
    & input {
      height: 18px;
    }
  }
`;

export const MinusIconContainer = styled(Box)`
  cursor: pointer;
`;
export const DistributionTextFieldContainer = styled(Box)`
  position: relative;
  flex: 1;
`;
