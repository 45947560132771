import React from "react";
import PropTypes from "prop-types";
import {
  LogoutIcon,
  UserPopoverContainer,
  UserPopoverItemsContainer,
  UserPopoverItemText,
  UserPopoverSingleItemContainer,
} from "./UserPopover.styled";
import userPopoverNavigationConstants from "../../constants/userPopoverNavigationConstants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions/login/loginActions";
import ProfileIcon from "../Icon/Icons/ProfileIcon";

const UserPopover = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  // Change on code refactor
  const navigationItems = userPopoverNavigationConstants(
    () => {},
    <ProfileIcon />,
    handleLogout,
    <LogoutIcon />
  );

  const handleClickNavigationItem = (navigationItem) => {
    navigationItem?.onClick?.();
    props?.closePopover?.();
  };

  return (
    <UserPopoverContainer>
      <UserPopoverItemsContainer>
        {navigationItems.map((navigationItem) => (
          <UserPopoverSingleItemContainer
            key={navigationItem?.id}
            to={navigationItem?.link || ""}
            onClick={() => handleClickNavigationItem(navigationItem)}
          >
            {navigationItem?.icon}
            <UserPopoverItemText>
              {t(navigationItem?.i18key)}
            </UserPopoverItemText>
          </UserPopoverSingleItemContainer>
        ))}
      </UserPopoverItemsContainer>
    </UserPopoverContainer>
  );
};

UserPopover.propTypes = {
  children: PropTypes.node,
  closePopover: PropTypes.func,
};

export default UserPopover;
