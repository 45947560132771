import React from "react";
import PropTypes from "prop-types";
import {
  DeleteIconContainer,
  PageeTitle,
  PageTitleContainer,
} from "./PageTitle.styled";
import TrashIcon from "../Icon/Icons/TrashIcon";
import i18n from "../../i18n";
import { toggleSetDeleteModal } from "../../store/actions/modal/modalActions";
import Tooltip from "../Tooltip/Tooltip";

const PageTitle = (props) => {
  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t(props?.modalTitle),
        description: {
          first: i18n.t(props?.modalDescription?.first),
          second: i18n.t(props?.modalDescription?.second),
        },
        onSubmit: props?.onSubmit,
        rank: 1,
      })
    );
  };

  return (
    <PageTitleContainer $noMargin={props?.noMargin}>
      <PageeTitle>{props?.title}</PageeTitle>
      {props?.isEditing && !props?.isLoading && !props?.disableActions && (
        <Tooltip title={i18n.t(props?.tooltipTitle)}>
          <DeleteIconContainer onClick={handleDelete}>
            <TrashIcon></TrashIcon>
          </DeleteIconContainer>
        </Tooltip>
      )}
    </PageTitleContainer>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  noMargin: PropTypes.bool,
  onSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  tooltipTitle: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.any,
  disableActions: PropTypes.bool,
};

export default PageTitle;
