import React from "react";
import PropTypes from "prop-types";
import {
  CopySubjectIcon,
  IconContainer,
  PageTitle,
  PageTitleContainer,
  PageTitleLeftContainer,
  PageTitleOutterContainer,
} from "./NewScanningObjectTitle.styled";
import TrashIcon from "../../../Icon/Icons/TrashIcon";
import i18n from "../../../../i18n";
import { toggleSetDeleteModal } from "../../../../store/actions/modal/modalActions";
import Tooltip from "../../../Tooltip/Tooltip";
// import FindingTypesSelect from "../../../FindingType/FindingTypesSelect";
import { useSelector } from "react-redux";
import { selectScanningObjectType } from "../../../../store/selectors/scanningObjectsSelectors";
import FindingTypeOption from "../../../Cards/FindingTypeOption/FindingTypeOption";
// import { setSingleScanningObjectType } from "../../../../store/actions/scanningObjects/scanningObjectsActions";
// import { useTranslation } from "react-i18next";

const NewScanningObjectTitle = (props) => {
  // const dispatch = useDispatch();
  // const { t } = useTranslation();
  const findingType = useSelector(selectScanningObjectType);

  // const handleChangeSubjectType = (newType) => {
  //   dispatch(setSingleScanningObjectType(newType));
  // };
  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("scanningObjects.modal.delete.title"),
        description: {
          first: i18n.t("scanningObjects.modal.delete.description.1"),
          second: i18n.t("scanningObjects.modal.delete.description.2"),
        },
        onSubmit: props?.onSubmit,
        rank: 1,
      })
    );
  };

  const handleCopy = () => {
    if (!props?.isScanningObjectChanged) props?.handleCopy();
  };

  return (
    <PageTitleOutterContainer>
      <PageTitleContainer $noMargin={props?.noMargin}>
        <PageTitleLeftContainer>
          <PageTitle>{props?.title}</PageTitle>
          {/* <FindingTypesSelect
          onChange={handleChangeSubjectType}
          value={{ id: findingType?.id, name: t(findingType?.i18key) }}
        /> */}
        </PageTitleLeftContainer>
        {props?.isEditing && !props?.isLoading && !props?.disableActions && (
          <>
            <Tooltip
              title={
                props?.isScanningObjectChanged
                  ? i18n.t("common.saveFirst")
                  : i18n.t("scanningObjects.copyScanningObject")
              }
            >
              <IconContainer onClick={handleCopy}>
                <CopySubjectIcon
                  $disabledIcon={props?.isScanningObjectChanged}
                />
              </IconContainer>
            </Tooltip>
            <Tooltip title={i18n.t("scanningObjects.deleteScanningObject")}>
              <IconContainer onClick={handleDelete}>
                <TrashIcon />
              </IconContainer>
            </Tooltip>
          </>
        )}
      </PageTitleContainer>
      <FindingTypeOption findingTypeLabel={findingType?.caption} />
    </PageTitleOutterContainer>
  );
};

NewScanningObjectTitle.propTypes = {
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  noMargin: PropTypes.bool,
  onSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  tooltipTitle: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.any,
  disableActions: PropTypes.bool,
  handleCopy: PropTypes.func,
  isScanningObjectChanged: PropTypes.bool,
};

export default NewScanningObjectTitle;
