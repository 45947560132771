import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";
import { ErrorMessage } from "../NewClientForm.styled";
import Tooltip from "../../../Tooltip/Tooltip";

const PhoneNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={
        formik?.values?.phoneNumber?.length > 41
          ? formik?.values?.phoneNumber
          : ""
      }
    >
      <TextInputField
        name="phoneNumber"
        placeholder={t("clients.newClientPlaceholders.phoneNumber")}
        label={t("clients.phoneNumber")}
        number
        value={formik?.values?.phoneNumber}
        onChange={(event) => {
          formik?.setFieldValue?.("phoneNumber", event.target.value);
        }}
        requiredField={
          newClientValidationSchema?.fields?.phoneNumber?.exclusiveTests
            ?.required
        }
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.phoneNumber &&
                formik?.touched?.phoneNumber &&
                "40px !important",
              width:
                formik?.errors?.phoneNumber &&
                formik?.touched?.phoneNumber &&
                "314px !important",
            },
          },
        }}
        error={formik?.errors?.phoneNumber && formik?.touched?.phoneNumber}
        helperText={formik?.errors?.phoneNumber && formik?.touched?.phoneNumber}
        onInput={(e) => {
          e.target.value =
            e.target.value[0] === "0" && e.target.value.length > 1
              ? "0" +
                String(
                  Math.max(0, parseInt(e.target.value)).toString().slice(0, 14)
                )
              : Math.max(0, parseInt(e.target.value)).toString().slice(0, 14);
        }}
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.phoneNumber && formik?.touched?.phoneNumber && (
        <ErrorMessage>{formik?.errors?.phoneNumber}</ErrorMessage>
      )}
    </Tooltip>
  );
};

PhoneNumberField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default PhoneNumberField;
