import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Title from "../Typography/Title/Title";
import { MeasuringInstrumentsContentContainer } from "./MeasuringInstrumentsContent.styled";
import MeasuringInstrumentsTable from "./MeasuringInstrumentsTable/MeasuringInstrumentsTable";
import { useDispatch } from "react-redux";
import { fetchFindingTypes } from "../../store/actions/findings/findingsActions";

const MeasuringInstrumentsContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchFindingTypes());
  }, []);

  return (
    <MeasuringInstrumentsContentContainer>
      <Title>{t("measuringInstruments.title")}</Title>
      <MeasuringInstrumentsTable />
    </MeasuringInstrumentsContentContainer>
  );
};

export default MeasuringInstrumentsContent;
