import React from "react";
import PropTypes from "prop-types";
import {
  CopyFindingModalContainer,
  InputsContainer,
} from "./CopyFindingModal.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import BackdropComponent from "../../MUI/BackdropComponent";
import ReactDatePicker from "react-datepicker";
import { FindingTextField } from "../../Findings/NewFinding/FindingSettings/FindingSettingsForm/FindingSettingsForm.styled";
import { copyFindingInitialValues } from "../../../initialValues/copyFindingInitialValues";
import { convertDateToISO } from "../../../util/helpers/dateHelpers";
import {
  fetchCopyFinding,
  fetchSingleFinding,
} from "../../../store/actions/findings/findingsActions";
import { useHistory } from "react-router-dom";
import { FINDINGS_EDIT_PAGE } from "../../../constants/pages";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import copyFindingValidationSchema from "../../../validations/copyFindingValidationSchema";

const CopyFindingModal = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const handleApiResponseSuccess = (data) => {
    history.push(
      replaceInRoute(FINDINGS_EDIT_PAGE, {
        findingId: data?.data?.id,
      })
    );
    dispatch(fetchSingleFinding({ findingId: data?.data?.id }));
    props?.setSelectedTab?.({ id: 0 });
    props?.handleResponseSuccess?.();
  };
  const handleSubmit = (values) => {
    console.log(values);
    dispatch(
      fetchCopyFinding({
        findingId: props?.id,
        date: convertDateToISO(formik?.values?.date),
        handleApiResponseSuccess,
      })
    );
    dispatch(closeModal());
  };
  const formik = useFormik({
    initialValues: copyFindingInitialValues,
    validationSchema: copyFindingValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <CopyFindingModalContainer
        component="form"
        onSubmit={formik?.handleSubmit}
        rank={props?.rank}
      >
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{t("findings.copyFindingModal.title")}</ModalTitle>
        <InputsContainer>
          <ReactDatePicker
            placeholderText={t("findings.form.dateOfChecking.placeholder")}
            disabled={props?.disabled}
            selected={formik?.values?.date}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => formik?.setFieldValue?.("date", date)}
            customInput={
              <FindingTextField
                date
                disabledField={props?.disabled}
                name={formik?.values?.date}
                placeholder={t("findings.form.dateOfChecking.placeholder")}
                value={formik?.values?.date}
                onChange={formik?.handleChange}
                error={formik?.errors?.date}
                helperText={formik?.errors?.date}
                // fullWidth
              />
            }
          />
        </InputsContainer>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.cancel")}
          </CancelButton>
          <SubmitButton onClick={formik?.handleSubmit}>
            {t("common.copy")}
          </SubmitButton>
        </ButtonsContainer>
      </CopyFindingModalContainer>
    </>
  );
};

CopyFindingModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  date: PropTypes.string,
  id: PropTypes.number,
  setSelectedTab: PropTypes.func,
  handleResponseSuccess: PropTypes.func,
};
CopyFindingModal.defaultProps = {
  title: "Promeni lozinku",
  onSubmit: () => {},
};

export default CopyFindingModal;
