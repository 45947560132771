import { APPLICATION_TOAST_CONTAINER_ID } from "../../constants/toastConstants";
import i18n from "../../i18n";
import { attachPostRequestListener } from "../../request";
import { makeErrorToastMessage } from "../utils/makeToastMessage";

export default () => (next) => (action) => {
  attachPostRequestListener(async (error) => {
    if (!error?.response) {
      return Promise.reject(error);
    }
    if (error?.response?.status === 500 || error?.response?.status === 400) {
      let errorMessage;
      try {
        const res = await error?.response?.data?.text?.();
        errorMessage = JSON.parse(res)?.message;
      } catch (e) {
        errorMessage = error?.response?.data?.message;
      } finally {
        if (typeof errorMessage !== "string")
          errorMessage = i18n.t("apiErrors.error");
        makeErrorToastMessage(
          {
            title: i18n.t("apiErrors.error"),
            description: errorMessage,
          },
          {
            containerId: APPLICATION_TOAST_CONTAINER_ID,
          }
        );
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  });

  next(action);
};
