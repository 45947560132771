import * as Yup from "yup";
import i18n from "../i18n";

export default Yup.object().shape({
  firstName: Yup.string().required(i18n.t("registerUser.firstNameRequired")),
  lastName: Yup.string().required(i18n.t("registerUser.lastNameRequired")),
  createdDate: Yup.string(),
  email: Yup.string()
    .email(i18n.t("clients.emailFormat"))
    .required(i18n.t("registerUser.emailRequired")),
});
