export const settingsInitialValues = (values) => ({
  companyName: values?.name || "",
  companyPIB: values?.tin || "",
  identificationNumber: values?.identificationNumber || "",
  address: values?.address || "",
  companyDirector: values?.directorInfo || "",
  bankAccountNumber: values?.bankAccount || "",
  phoneNumber: values?.phoneNumber || "",
  email: values?.email || "",
  logo: values?.logoUrl || "",
  stamp: values?.stampUrl || "",
  signature: values?.directorSignatureUrl || "",
  license: values?.licenseUrl || "",
  APR: values?.aprImages || [],
  distributionEmails: values?.distributionEmails?.split(";") || [""],
  userName: values?.userNameForMailService || "",
  password: values?.passwordForMailService || "",
  host: values?.hostForMailService || "",
  port:
    values?.portForMailService && values?.portForMailService !== 0
      ? values?.portForMailService
      : "",
  enableSSl: values?.enableSSlForMailService || false,
  automaticallySendEmails: values?.automaticallySendEmails || false,
  driveFolderId: values?.driveFolderId || "",
  hasCredentialsFile: values?.hasCredentialsFile || false,
  hasEmailPassword: values?.hasEmailPassword || false,
  automaticallyUploadToDrive: values?.automaticallyUploadToDrive || false,
});
