import React from "react";
import PropTypes from "prop-types";
import {
  ArchivingDetailsContainer,
  CheckboxMainContainer,
  ContentLeft,
  FormContainer,
} from "./ArchivingDetails.styled";
import Checkbox from "../../NewUser/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import archivingProviders from "../../../constants/archivingProviders";
import { useState } from "react";
import { ContentRight } from "../EmailInformation/EmailInformation.styled";
import GoogleDriveInfo from "./GoogleDriveInfo/GoogleDriveInfo";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";

const ArchivingDetails = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const [selectedProvider, setSelectedProvider] = useState(
    archivingProviders[0]
  );
  const onFileChange = (file) => {
    props?.onFileChange(file);
  };
  return (
    <ArchivingDetailsContainer>
      <CheckboxMainContainer>
        <Checkbox
          name="automaticallyUploadToDrive"
          label={t("companySettings.archive.autoSave")}
          checked={formik?.values?.automaticallyUploadToDrive}
          onChange={() => {
            formik?.setFieldValue?.(
              "automaticallyUploadToDrive",
              !formik?.values?.automaticallyUploadToDrive
            );
          }}
        />
      </CheckboxMainContainer>
      <FormContainer onSubmit={formik?.handleSubmit}>
        <ContentLeft>
          <AutocompleteField
            options={archivingProviders}
            label={t("companySettings.archive.dropdown")}
            disabled
            title={t("companySettings.archive.dropdown")}
            onChange={(value) => setSelectedProvider(value)}
            dropdownLabel={["name"]}
            value={selectedProvider}
          />
        </ContentLeft>
        <ContentRight>
          {selectedProvider?.id === 1 && (
            <GoogleDriveInfo formik={formik} onFileChange={onFileChange} />
          )}
        </ContentRight>
      </FormContainer>
    </ArchivingDetailsContainer>
  );
};

ArchivingDetails.propTypes = {
  formik: PropTypes.object,
  onFileChange: PropTypes.any,
};
export default ArchivingDetails;
