import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Logout } from "../../assets/images/svg/logout.svg";
import selectedTheme from "../../themes";
import { LinkContainer } from "../Link/Link.styled";

export const UserPopoverContainer = styled(Box)`
  padding: 32px;
  border-radius: 12px;
  background: white;
`;
export const UserPopoverItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const UserPopoverSingleItemContainer = styled(LinkContainer)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
`;
export const LogoutIcon = styled(Logout)``;
export const UserPopoverItemText = styled(Box)`
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${selectedTheme.colors.userPopoverItemColor};
`;
