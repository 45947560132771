import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { HeaderProfile } from "./HeaderProfileImage.styled";
import PopoverComponent from "../../MUI/PopoverComponent";
import UserPopover from "../../Popovers/UserPopover";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyUser } from "../../../store/actions/user/userActions";
import { selectMyUser } from "../../../store/selectors/usersSelectors";

const HeaderProfileImage = () => {
  const [showUserPopover, setShowUserPopover] = useState(false);
  const [UserPopoverAnchorEl, setUserPopoverAnchorEl] = useState(null);
  const myUser = useSelector(selectMyUser);
  const dispatch = useDispatch();

  const userInitials = useMemo(() => {
    if (myUser?.firstName) {
      return `${myUser?.firstName[0]}${myUser?.lastName[0]}`;
    }
    return "";
  }, [myUser]);

  const closePopover = () => {
    setShowUserPopover(false);
    setUserPopoverAnchorEl(null);
  };

  //   const toggleUserPopover = (event) => {
  //     setShowUserPopover((prevState) => !prevState);
  //     setUserPopoverAnchorEl((prevState) =>
  //       prevState ? null : event.currentTarget
  //     );
  //   };
  useEffect(() => {
    dispatch(fetchMyUser());
  }, []);
  return (
    <>
      <HeaderProfile
        onClick={(event) => {
          setShowUserPopover(true);
          setUserPopoverAnchorEl(event?.currentTarget);
        }}
      >
        {/* <ProfileImage /> */}
        {userInitials}
      </HeaderProfile>
      <PopoverComponent
        anchorEl={UserPopoverAnchorEl}
        open={showUserPopover}
        onClose={closePopover}
        content={<UserPopover closePopover={closePopover} />}
      />
    </>
  );
};

HeaderProfileImage.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfileImage;
