import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  InfoContainer,
  TooltipContainer,
  TypesRendererContainer,
  TypesRendererContentContainer,
  TypesRendererContentTitle,
} from "./FindingTypesRenderer.styled";
import Tooltip from "../../../../Tooltip/Tooltip";

const FindingTypesRenderer = (props) => {
  const ref = useRef(null);
  const [toRender, setToRender] = useState({
    data: props?.value,
    isSliced: false,
  });
  const renderChildren = (doSlice) => {
    const data = doSlice ? toRender?.data : props?.value;
    return data?.map((item, index) => (
      <TypesRendererContentContainer key={index}>
        <TypesRendererContentTitle onTooltip={!doSlice}>
          {item.caption}
        </TypesRendererContentTitle>
        {doSlice &&
          index === toRender?.data?.length - 1 &&
          toRender?.isSliced && <InfoContainer>...</InfoContainer>}
      </TypesRendererContentContainer>
    ));
  };

  useEffect(() => {
    if (ref?.current?.clientHeight > 42)
      setToRender((prevState) => {
        return { data: prevState?.data?.slice?.(0, 2), isSliced: true };
      });
  }, [ref?.current?.clientHeight]);

  return (
    <Tooltip
      title={<TooltipContainer>{renderChildren(false)}</TooltipContainer>}
    >
      <TypesRendererContainer ref={ref}>
        {renderChildren(true)}
      </TypesRendererContainer>
    </Tooltip>
  );
};

FindingTypesRenderer.propTypes = {
  value: PropTypes.array,
};

export default FindingTypesRenderer;
