import styled from "styled-components";
import { Box, Grid } from "@mui/material";

export const AppLayoutContainer = styled(Box)`
  transition-duration: 0.2s;
`;

export const Sidebar = styled(Grid)`
  min-width: 73px;
  height: 100vh;
  @media (max-width: 600px) {
    position: fixed;
    left: 0;
    top: 0;
    /* transition: all 2s ease-in;
    width: ${(props) => (props?.$isSidebarOpened ? "80vw" : "0")} !important;
    position: fixed;
    top: 0;
    left: -73px; */
    width: 0 !important;
    min-width: 0 !important;
    z-index: 1500;
    /* & * {
      display: ${(props) => !props?.$isSidebarOpened && "none"};
    } */
  }
`;

export const Content = styled(Grid)`
  padding: 72px 26px 16px 26px;
  transition-duration: 0.2s;
  height: 100vh;
`;
