import { Box, TextareaAutosize, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";

export const SetStandardModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;

  z-index: ${(props) => "15" + props.rank || "151"};
  max-width: 500px;
  @media (min-width: 601px) {
    max-height: 900px !important;
    /* height: 95%; */
  }
  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  & * {
    text-align: left;
  }
`;

export const TextareaLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  color: ${selectedTheme.colors.gray};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `}
`;
export const TextareaInput = styled(TextareaAutosize)`
  width: 412px;
  border-radius: 6px;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  resize: vertical;
  padding: 8px 12px;
  ${(props) =>
    props?.disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledFieldBackground};
      color: ${selectedTheme.colors.disabledFieldText};
    `}
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const TextareaContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CheckboxContainer = styled(Box)`
  display: flex;
  padding: 20px 0px;
  gap: 8px;
  width: 210px;
  height: 24px;
`;

export const DeleteBtnContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 16px 0px;
  width: 100%;
`;

export const DeleteBtn = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  cursor: pointer;
`;

export const DeleteBtnLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.deleteBtnLabel};
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
`;
