import React from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  SingleInputContainer,
  SingleInputTitle,
  TextareaInput,
} from "../SetMeasuringInstrumentModal.styled";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { useTranslation } from "react-i18next";

const BenchmarkingField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  return (
    <SingleInputContainer>
      <SingleInputTitle
      // $required={
      //   !formik?.values?.[newMeasuringInstrumentFormikConstants.benchmarking]
      // }
      >
        {t("measuringInstruments.modal.fields.benchmarking.label")}
      </SingleInputTitle>
      <TextareaInput
        id={newMeasuringInstrumentFormikConstants?.benchmarking}
        minRows={3}
        maxRows={6}
        onChange={formik?.handleChange}
        value={
          formik?.values?.[newMeasuringInstrumentFormikConstants.benchmarking]
        }
        placeholder={t(
          "measuringInstruments.modal.fields.benchmarking.placeholder"
        )}
        disabled={props?.isReadOnly}
      />
      {formik?.errors?.[newMeasuringInstrumentFormikConstants.benchmarking] &&
        formik?.touched?.[newMeasuringInstrumentFormikConstants.benchmarking] && (
          <ErrorMessage>
            {formik?.errors?.[newMeasuringInstrumentFormikConstants.benchmarking]}
          </ErrorMessage>
        )}
    </SingleInputContainer>
  );
};

BenchmarkingField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default BenchmarkingField;
