import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectChecklistTemplateNegativeConclusion,
  selectChecklistTemplatePositiveConclusion,
} from "../../../../store/selectors/scanningObjectsSelectors";
import ConclusionField from "./ConclusionField";
import { ConclusionWrapper } from "./TemplateConclusionEditor.styled";
import {
  POSITIVE_CONCLUSION,
  NEGATIVE_CONCLUSION,
} from "../../../../constants/findingConclusionConstants";

const TemplateConclusionEditor = () => {
  const positiveConclusion = useSelector(
    selectChecklistTemplatePositiveConclusion
  );
  const negativeConclusion = useSelector(
    selectChecklistTemplateNegativeConclusion
  );

  const { t } = useTranslation();
  return (
    <ConclusionWrapper>
      <ConclusionField
        placeholder={t("scanningObjects.positiveConclusion")}
        title={t("scanningObjects.positiveConclusion")}
        type={POSITIVE_CONCLUSION}
        conclusion={positiveConclusion}
      />
      <ConclusionField
        placeholder={t("scanningObjects.negativeConclusion")}
        title={t("scanningObjects.negativeConclusion")}
        type={NEGATIVE_CONCLUSION}
        conclusion={negativeConclusion}
      />
    </ConclusionWrapper>
  );
};

export default TemplateConclusionEditor;
