import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { CAPTION_KEY } from "../../constants/queryConstants";
import {
  attemptFetchCreateStandard,
  attemptFetchDeleteStandard,
  attemptFetchEditStandard,
  attemptFetchSingleStandard,
  attemptFetchStandards,
} from "../../request/standardsRequest";
import {
  SINGLE_STANDARD_FETCH,
  STANDARDS_CREATE_FETCH,
  STANDARDS_DELETE_FETCH,
  STANDARDS_EDIT_FETCH,
  STANDARDS_FETCH,
} from "../actions/standards/standardsActionConstants";
import {
  fetchCreateStandardError,
  fetchCreateStandardSuccess,
  fetchDeleteStandardError,
  fetchDeleteStandardSuccess,
  fetchEditStandardError,
  fetchEditStandardSuccess,
  fetchSingleStandardError,
  fetchSingleStandardSuccess,
  fetchStandardsError,
  fetchStandardsSuccess,
  setSingleStandard,
  setStandards,
  setStandardsTotal,
} from "../actions/standards/standardsActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchStandards({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, [CAPTION_KEY]);
    const data = yield call(attemptFetchStandards, queryObject);
    yield put(
      setStandards({
        data: data?.data?.standards,
      })
    );
    yield put(setStandardsTotal(data?.data?.count));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchStandardsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchStandardsError(errorMessage));
  }
}

function* fetchSingleStandard({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleStandard, payload.standardId);
    yield put(setSingleStandard(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchSingleStandardSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleStandardError(errorMessage));
  }
}
function* fetchCreateStandard({ payload }) {
  try {
    yield call(attemptFetchCreateStandard, payload.data);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchCreateStandardSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateStandardError(errorMessage));
  }
}
function* fetchEditStandard({ payload }) {
  try {
    yield call(attemptFetchEditStandard, {
      standardId: payload.standardId,
      data: { ...payload.data, id: payload.standardId },
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchEditStandardSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditStandardError(errorMessage));
  }
}

function* fetchDeleteSingleStandard({ payload }) {
  try {
    yield call(attemptFetchDeleteStandard, {
      standardId: payload.standardId,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchDeleteStandardSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteStandardError(errorMessage));
  }
}

export default function* standardsSaga() {
  yield all([
    takeLatest(STANDARDS_FETCH, fetchStandards),
    takeLatest(SINGLE_STANDARD_FETCH, fetchSingleStandard),
    takeLatest(STANDARDS_CREATE_FETCH, fetchCreateStandard),
    takeLatest(STANDARDS_EDIT_FETCH, fetchEditStandard),
    takeLatest(STANDARDS_DELETE_FETCH, fetchDeleteSingleStandard),
  ]);
}
