import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import EditIcon from "../../../../../Icon/Icons/EditIcon";
import TextInputField from "../../../../../TextInputField/TextInputField";

export const FindingTableSingleInputLabelContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
  flex: 1;
`;
export const FindingTableSingleInputContainer = styled(TextInputField)`
  margin-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  & input {
    width: 100%;
  }
  & > div {
    width: 100%;
  }
  & > div:nth-child(2) > div {
    width: 100%;
  }
`;
export const FindingTableSingleInputTitlesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 20px);
`;
export const FindingTableSingleInputTitle = styled(Typography)`
  color: ${selectedTheme.colors.labelTextColor};
  font-feature-settings: "dlig" on, "salt" on;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;
export const FindingTableSingleInputSubtitle = styled(Typography)`
  color: ${selectedTheme.colors.orange};
  font-feature-settings: "dlig" on, "salt" on;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;
export const FindingTableColumnEditIcon = styled(EditIcon)`
  cursor: pointer;
  min-width: 18px;
  min-height: 18px;
  & path {
    /* stroke: ${selectedTheme.colors.inputBorder}; */
  }
`;
