import {
  SINGLE_SCANNING_OBJECT_SET,
  SCANNING_OBJECTS_CLEAR,
  SCANNING_OBJECTS_SET,
  SCANNING_OBJECTS_SET_TOTAL,
  SINGLE_SCANNING_OBJECT_CLEAR,
  SINGLE_SCANNING_OBJECT_CHECKLIST_TEMPLATES_SET,
  SINGLE_SCANNING_OBJECT_SECTIONS_SET,
  SINGLE_SCANNING_OBJECT_SECTION_QUESTIONS_SET,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_SET,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_SET,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_SET,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_ADD,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_EDIT,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_ADD,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_ADD,
  SINGLE_SCANNING_OBJECT_INSTRUMENTS_ADD,
  SINGLE_SCANNING_OBJECT_INSTRUMENTS_DELETE,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_DELETE,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_DELETE,
  SINGLE_SCANNING_OBJECT_SINGLE_SECTION_MOVE,
  SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_MOVE,
  SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_TOGGLE,
  SINGLE_SCANNING_OBJECT_STANDARDS_ADD,
  SINGLE_SCANNING_OBJECT_STANDARDS_DELETE,
  SINGLE_SCANNING_OBJECT_DEFAULT_STANDARDS_ADD,
  SCANNING_OBJECTS_SET_CONCLUSION,
  SCANNING_OBJECTS_CHANGED_SET,
  SINGLE_QUESTION_MOVE_ON_DRAG_AND_DROP,
  SINGLE_SCANNING_OBJECT_TYPE_SET,
} from "../../actions/scanningObjects/scanningObjectsActionConstants";
import {
  POSITIVE_CONCLUSION,
  NEGATIVE_CONCLUSION,
} from "../../../constants/findingConclusionConstants";
import createReducer from "../../utils/createReducer";
import { findingTypesAsArray } from "../../../constants/findingTypeConstants";

const initialState = {
  scanningObjects: {
    loaded: false,
    data: [],
  },
  totalScanningObjects: 0,
  singleScanningObject: {
    name: "",
    checklistTemplates: [],
    type: findingTypesAsArray[0],
  },
  scanningObjectsChanged: false,
};

export default createReducer(
  {
    [SCANNING_OBJECTS_SET]: setScanningObjects,
    [SCANNING_OBJECTS_SET_TOTAL]: setScanningObjectsTotal,
    [SCANNING_OBJECTS_CLEAR]: clearScanningObjects,
    [SINGLE_SCANNING_OBJECT_SET]: setSingleScanningObject,
    [SINGLE_SCANNING_OBJECT_CLEAR]: clearSingleScanningObject,
    [SINGLE_SCANNING_OBJECT_CHECKLIST_TEMPLATES_SET]:
      setSingleScanningObjectChecklistTemplates,
    [SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_SET]:
      setSingleScanningObjectSelectedChecklistTemplate,
    [SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_ADD]:
      addSingleScanningObjectSelectedChecklistTemplate,
    [SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_EDIT]:
      editSingleScanningObjectSelectedChecklistTemplate,
    [SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_TOGGLE]:
      toggleSingleScanningObjectSelectedChecklistTemplate,
    [SINGLE_SCANNING_OBJECT_SECTIONS_SET]: setSingleScanningObjectSections,
    [SINGLE_SCANNING_OBJECT_INSTRUMENTS_ADD]:
      addSingleScanningObjectInstruments,
    [SINGLE_SCANNING_OBJECT_INSTRUMENTS_DELETE]:
      deleteSingleScanningObjectInstruments,
    [SINGLE_SCANNING_OBJECT_DEFAULT_STANDARDS_ADD]:
      addSingleScanningObjectDefaultStandards,
    [SINGLE_SCANNING_OBJECT_STANDARDS_ADD]: addSingleScanningObjectStandards,
    [SINGLE_SCANNING_OBJECT_STANDARDS_DELETE]:
      deleteSingleScanningObjectStandards,
    [SINGLE_SCANNING_OBJECT_SINGLE_SECTION_SET]:
      setSingleScanningObjectSingleSection,
    [SINGLE_SCANNING_OBJECT_SINGLE_SECTION_ADD]:
      addSingleScanningObjectSingleSection,
    [SINGLE_SCANNING_OBJECT_SINGLE_SECTION_MOVE]:
      moveSingleScanningObjectSingleSection,
    [SINGLE_SCANNING_OBJECT_SINGLE_SECTION_DELETE]:
      deleteSingleScanningObjectSingleSection,
    [SINGLE_SCANNING_OBJECT_SECTION_QUESTIONS_SET]:
      setSingleScanningObjectSectionQuestions,
    [SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_SET]:
      setSingleScanningObjectSectionSingleQuestion,
    [SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_ADD]:
      addSingleScanningObjectSectionSingleQuestion,
    [SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_DELETE]:
      deleteSingleScanningObjectSectionSingleQuestion,
    [SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_MOVE]:
      moveSingleScanningObjectSectionSingleQuestion,
    [SINGLE_QUESTION_MOVE_ON_DRAG_AND_DROP]: moveSingleQuestionOnDragAndDrop,
    [SCANNING_OBJECTS_SET_CONCLUSION]: setScanningObjectConclusion,
    [SCANNING_OBJECTS_CHANGED_SET]: setScanningObjectChanged,
    [SINGLE_SCANNING_OBJECT_TYPE_SET]: setSingleScanningObjectType,
  },
  initialState
);
function setSingleScanningObjectChecklistTemplates(state, { payload }) {
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: payload,
    },
  };
}

function setSingleScanningObjectType(state, { payload }) {

  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      type: payload,
    },
  };
}

function setSingleScanningObjectSelectedChecklistTemplate(state, { payload }) {
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ].map((item) => {
    let isDefault = false;
    if (item?.id === payload) isDefault = true;
    return {
      ...item,
      isDefault,
    };
  });
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: prevChecklistTemplates,
    },
  };
}

function toggleSingleScanningObjectSelectedChecklistTemplate(
  state,
  { payload }
) {
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentChecklistTemplateIndex = prevChecklistTemplates.findIndex(
    (singleTemplate) => singleTemplate?.id === payload.templateId
  );
  let currentChecklistTemplate =
    prevChecklistTemplates[currentChecklistTemplateIndex];
  if (currentChecklistTemplate?.isDefault) {
    currentChecklistTemplate.isDefault = false;

    currentChecklistTemplate.changed = true;
    prevChecklistTemplates.splice(currentChecklistTemplateIndex, 1);
    prevChecklistTemplates = [
      ...prevChecklistTemplates.slice(0, currentChecklistTemplateIndex),
      currentChecklistTemplate,
      ...prevChecklistTemplates.slice(currentChecklistTemplateIndex),
    ];
  } else {
    let currentActiveChecklistTemplateIndex = prevChecklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
    if (currentActiveChecklistTemplateIndex !== -1) {
      let currentActiveChecklistTemplate =
        prevChecklistTemplates[currentActiveChecklistTemplateIndex];
      currentActiveChecklistTemplate.isDefault = false;
      currentActiveChecklistTemplate.changed = true;
      prevChecklistTemplates.splice(currentActiveChecklistTemplateIndex, 1);
      prevChecklistTemplates = [
        ...prevChecklistTemplates.slice(0, currentActiveChecklistTemplateIndex),
        currentActiveChecklistTemplate,
        ...prevChecklistTemplates.slice(currentActiveChecklistTemplateIndex),
      ];
    }

    currentChecklistTemplate.isDefault = true;
    currentChecklistTemplate.changed = true;
    prevChecklistTemplates.splice(currentChecklistTemplateIndex, 1);
    prevChecklistTemplates = [
      ...prevChecklistTemplates.slice(0, currentChecklistTemplateIndex),
      currentChecklistTemplate,
      ...prevChecklistTemplates.slice(currentChecklistTemplateIndex),
    ];
  }
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: prevChecklistTemplates,
    },
  };
}

function editSingleScanningObjectSelectedChecklistTemplate(state, { payload }) {
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentChecklistTemplateIndex = prevChecklistTemplates.findIndex(
    (template) => template?.id === payload?.templateId
  );
  let currentChecklistTemplate = { ...payload?.template, changed: true };

  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentChecklistTemplateIndex),
        currentChecklistTemplate,
        ...prevChecklistTemplates.slice(currentChecklistTemplateIndex),
      ],
    },
  };
}
function addSingleScanningObjectSelectedChecklistTemplate(state, { payload }) {
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...state.singleScanningObject.checklistTemplates,
        {
          ...payload,
          changed: true,
          added: true,
        },
      ],
    },
  };
}

function setSingleScanningObjectSections(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.sections = payload;
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function addSingleScanningObjectInstruments(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.measuringInstruments = [
    ...currentTemplate.measuringInstruments,
    payload,
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function deleteSingleScanningObjectInstruments(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.measuringInstruments =
    currentTemplate.measuringInstruments.filter(
      (singleInstrument) => singleInstrument?.id !== payload
    );
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function addSingleScanningObjectDefaultStandards(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
  };
  currentTemplate.standards = [...payload];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function addSingleScanningObjectStandards(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.standards = [...currentTemplate.standards, payload];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function deleteSingleScanningObjectStandards(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.standards = currentTemplate.standards.filter(
    (singleInstrument) => singleInstrument?.id !== payload
  );
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function setSingleScanningObjectSingleSection(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.section?.id
  );
  currentTemplate.sections.splice(currentSectionIndex, 1);
  currentTemplate.sections = [
    ...currentTemplate.sections.slice(0, currentSectionIndex),
    payload.section,
    ...currentTemplate.sections.slice(currentSectionIndex),
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}
function addSingleScanningObjectSingleSection(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.sections = [...currentTemplate.sections, payload.section];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}
function deleteSingleScanningObjectSingleSection(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  currentTemplate.sections = currentTemplate.sections.filter(
    (singleSection) => singleSection?.id !== payload
  );
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}
function moveSingleScanningObjectSingleSection(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };

  const indexOfSection = currentTemplate?.sections.findIndex(
    (singleSection) => singleSection?.id === payload?.sectionId
  );

  const section = currentTemplate?.sections[indexOfSection];

  const newIndexOfSection = payload?.backwards
    ? indexOfSection - 1
    : indexOfSection + 1;
  const newAllSections = currentTemplate?.sections;
  newAllSections.splice(indexOfSection, 1);
  currentTemplate.sections = [
    ...newAllSections.slice(0, newIndexOfSection),
    section,
    ...newAllSections.slice(newIndexOfSection),
  ];

  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}
function setSingleScanningObjectSectionQuestions(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.sectionId
  );
  let prevSections = [
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex]
      .sections,
  ];
  let currentSection = prevSections[currentSectionIndex];
  currentSection.questions = payload.questions;
  currentTemplate.sections.splice(currentSectionIndex, 1);
  currentTemplate.sections = [
    ...currentTemplate.sections.slice(0, currentSectionIndex),
    currentSection,
    ...currentTemplate.sections.slice(currentSectionIndex),
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function setSingleScanningObjectSectionSingleQuestion(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.sectionId
  );
  let prevSections = [
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex]
      .sections,
  ];
  let currentSection = prevSections[currentSectionIndex];
  let prevQuestions = currentSection.questions;
  let questionIndex = prevQuestions.findIndex(
    (singleQuestion) => singleQuestion?.id === payload?.question?.id
  );
  prevQuestions.splice(questionIndex, 1);
  currentSection.questions = [
    ...prevQuestions.slice(0, questionIndex),
    payload.question,
    ...prevQuestions.slice(questionIndex),
  ];
  currentTemplate.sections.splice(currentSectionIndex, 1);
  currentTemplate.sections = [
    ...currentTemplate.sections.slice(0, currentSectionIndex),
    currentSection,
    ...currentTemplate.sections.slice(currentSectionIndex),
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}
function addSingleScanningObjectSectionSingleQuestion(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.sectionId
  );
  let prevSections = [
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex]
      .sections,
  ];
  let currentSection = prevSections[currentSectionIndex];
  let prevQuestions = currentSection.questions;
  currentSection.questions = [...prevQuestions, payload.question];
  currentTemplate.sections.splice(currentSectionIndex, 1);
  currentTemplate.sections = [
    ...currentTemplate.sections.slice(0, currentSectionIndex),
    currentSection,
    ...currentTemplate.sections.slice(currentSectionIndex),
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}
function deleteSingleScanningObjectSectionSingleQuestion(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.sectionId
  );
  let prevSections = [
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex]
      .sections,
  ];
  let currentSection = prevSections[currentSectionIndex];
  let prevQuestions = currentSection.questions;
  currentSection.questions = prevQuestions.filter(
    (singleQuestion) => singleQuestion?.id !== payload.questionId
  );
  currentTemplate.sections.splice(currentSectionIndex, 1);
  currentTemplate.sections = [
    ...currentTemplate.sections.slice(0, currentSectionIndex),
    currentSection,
    ...currentTemplate.sections.slice(currentSectionIndex),
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function moveSingleScanningObjectSectionSingleQuestion(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let prevChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.sectionId
  );
  let prevSections = [
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex]
      .sections,
  ];
  let currentSection = prevSections[currentSectionIndex];
  let prevQuestions = [...currentSection?.questions];

  const firstQuestion = prevQuestions[payload.firstIndex];
  const secondQuestion = prevQuestions[payload.secondIndex];

  prevQuestions.splice(payload.firstIndex, 1);
  let newSectionQuestions = [
    ...prevQuestions.slice(0, payload.firstIndex),
    secondQuestion,
    ...prevQuestions.slice(payload.firstIndex),
  ];

  newSectionQuestions.splice(payload.secondIndex, 1);
  newSectionQuestions = [
    ...newSectionQuestions.slice(0, payload.secondIndex),
    firstQuestion,
    ...newSectionQuestions.slice(payload.secondIndex),
  ];

  currentSection.questions = [...newSectionQuestions];

  currentTemplate.sections.splice(currentSectionIndex, 1);
  currentTemplate.sections = [
    ...currentTemplate.sections.slice(0, currentSectionIndex),
    currentSection,
    ...currentTemplate.sections.slice(currentSectionIndex),
  ];
  prevChecklistTemplates.splice(currentTemplateIndex, 1);
  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [
        ...prevChecklistTemplates.slice(0, currentTemplateIndex),
        currentTemplate,
        ...prevChecklistTemplates.slice(currentTemplateIndex),
      ],
    },
  };
}

function moveSingleQuestionOnDragAndDrop(state, { payload }) {
  let currentTemplateIndex =
    state.singleScanningObject.checklistTemplates.findIndex(
      (singleTemplate) => singleTemplate?.isDefault
    );
  let currentTemplate = {
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex],
    changed: true,
  };
  let currentSectionIndex = currentTemplate?.sections?.findIndex(
    (singleSection) => singleSection?.id === payload.sectionId
  );
  let prevSections = [
    ...state.singleScanningObject.checklistTemplates[currentTemplateIndex]
      .sections,
  ];
  let currentSection = prevSections[currentSectionIndex];

  let firstIndex = payload.firstIndex;
  let secondIndex = payload.secondIndex;
  if (payload.firstIndex < payload.secondIndex) {
    secondIndex = payload.secondIndex - 1;
  }

  const dragIteme = currentSection?.questions?.splice(firstIndex, 1)[0];
  currentSection?.questions?.splice(secondIndex, 0, dragIteme);

  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [...currentSection.questions, currentTemplate],
    },
  };
}

function setSingleScanningObject(state, { payload }) {
  return {
    ...state,
    singleScanningObject: payload,
  };
}
function setScanningObjects(state, { payload }) {
  return {
    ...state,
    scanningObjects: payload,
  };
}
function setScanningObjectsTotal(state, { payload }) {
  return {
    ...state,
    totalScanningObjects: payload,
  };
}
function clearScanningObjects(state) {
  return {
    ...state,
    scanningObjects: initialState.scanningObjects,
    totalScanningObjects: initialState.totalScanningObjects,
  };
}
function clearSingleScanningObject(state) {
  return {
    ...state,
    singleScanningObject: initialState.singleScanningObject,
  };
}

function setScanningObjectConclusion(state, { payload }) {
  const tmpChecklistTemplates = [
    ...state.singleScanningObject.checklistTemplates,
  ];
  const tmpChecklistTemplate = tmpChecklistTemplates.find(
    (x) => x.isDefault === true
  );

  if (payload.type === POSITIVE_CONCLUSION) {
    tmpChecklistTemplate.positiveConclusionTemplate = payload.conclusion;
    tmpChecklistTemplate.changed = true;
  }

  if (payload.type === NEGATIVE_CONCLUSION) {
    tmpChecklistTemplate.negativeConclusionTemplate = payload.conclusion;
    tmpChecklistTemplate.changed = true;
  }

  return {
    ...state,
    singleScanningObject: {
      ...state.singleScanningObject,
      checklistTemplates: [...tmpChecklistTemplates],
    },
  };
}

function setScanningObjectChanged(state, { payload }) {
  return {
    ...state,
    scanningObjectsChanged: payload,
  };
}
