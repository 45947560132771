import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../TextInputField/TextInputField";

const LastNameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="lastName"
        label={t("users.lastName")}
        placeholder={t("users.newUserPlaceholders.lastName")}
        value={formik?.values?.lastName}
        onChange={formik?.handleChange}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.lastName &&
                formik?.touched?.lastName &&
                "40px !important",
              width:
                formik?.errors?.lastName &&
                formik?.touched?.lastName &&
                "314px !important",
            },
          },
        }}
        error={formik?.touched?.lastName && formik?.errors?.lastName}
        helperText={formik?.touched?.lastName && formik?.errors?.lastName}
        requiredField={props?.isRequired}
        disabledField={props?.isReadOnly}
      />
    </>
  );
};

LastNameField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default LastNameField;
