import React from "react";
import { ReactComponent as TaskIcon } from "../assets/images/svg/task-square.svg";
import { ReactComponent as UsersIcon } from "../assets/images/svg/users.svg";
import HomeIcon from "../components/Icon/Icons/HomeIcon";
import DirectBoxIcon from "../components/Icon/Icons/DirectBoxIcon";
import {
  CLIENTS_PAGE,
  STANDARDS_PAGE,
  USERS_PAGE,
  MEASURING_INSTRUMENTS_PAGE,
  SCANNING_OBJECTS_PAGE,
  STUFFS_PAGE,
  RESEARCH_PAGE,
  FINDINGS_PAGE,
} from "./pages";
import { Trans } from "react-i18next";
import i18n from "../i18n";
import { ADMIN_ROLE, MANAGER_ROLE, OPERATOR_ROLE } from "./rolesConstants";
import MeasuringInstrumentsSidebarIcon from "../components/Icon/Icons/MeasuringInstrumentsSiderbarIcon";
import ScanningSubjectIcon from "../components/Icon/Icons/ScanningSubjectsIcon";
import ClientsIcon from "../components/Icon/Icons/ClientsIcon";
import PersonelIcon from "../components/Icon/Icons/PersonelIcon";
import StaffIcon from "../components/Icon/Icons/StaffIcon";
import FindingIcon from "../components/Icon/Icons/FindingIcon";

export const SIDEBAR_NAVIGATION = [
  {
    title: i18n.t("sidebarNavigation.home"),
    icon: <HomeIcon />,
    roles: [OPERATOR_ROLE, MANAGER_ROLE, ADMIN_ROLE],
    route: "/home",
  },
  {
    title: i18n.t("sidebarNavigation.scanning"),
    icon: <DirectBoxIcon />,
    route: RESEARCH_PAGE,
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
    subnavigationRoutes: [
      {
        title: i18n.t("sidebarNavigation.standards"),
        route: STANDARDS_PAGE,
        icon: <TaskIcon />,
      },
      {
        title: (
          <Trans i18nKey={i18n.t("sidebarNavigation.scanningInstruments")} />
        ),
        icon: <MeasuringInstrumentsSidebarIcon />,
        route: MEASURING_INSTRUMENTS_PAGE,
      },
      {
        title: i18n.t("sidebarNavigation.scanningObjects"),
        route: SCANNING_OBJECTS_PAGE,
        icon: <ScanningSubjectIcon />,
      },
    ],
  },
  {
    title: i18n.t("sidebarNavigation.findingsPlural"),
    icon: <FindingIcon />,
    route: FINDINGS_PAGE,
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
  },
  {
    title: i18n.t("sidebarNavigation.clients"),
    icon: <ClientsIcon />,
    route: CLIENTS_PAGE,
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
  },
  {
    title: i18n.t("sidebarNavigation.personal"),
    icon: <PersonelIcon />,
    // route: USERS_PAGE,
    subnavigationRoutes: [
      {
        title: i18n.t("sidebarNavigation.users"),
        route: USERS_PAGE,
        roles: [ADMIN_ROLE],
        icon: <UsersIcon />,
      },
      {
        title: i18n.t("sidebarNavigation.staff"),
        route: STUFFS_PAGE,
        roles: [OPERATOR_ROLE, MANAGER_ROLE],
        icon: <StaffIcon />,
      },
      // {
      //   title: i18n.t("sidebarNavigation.privilege"),
      //   icon: <PersonalIcon />,
      //   roles: [ADMIN_ROLE],
      //   // route: "/privilegije"
      // },
    ],
  },
];
