import React from "react";
import PropTypes from "prop-types";
import { SidebarUpperContentContainer } from "./SidebarUpperContent.styled";
import SidebarNavigationItem from "../SidebarNavigationItem/SidebarNavigationItem";
import { SIDEBAR_NAVIGATION } from "../../../constants/sidebarNavigation";
import SidebarLogo from "../SidebarLogo/SidebarLogo";
import WithPermissions from "../../WithPermissions/WithPermissions";
import rolesConstants from "../../../constants/rolesConstants";

const SidebarUpperContent = () => {
  return (
    <SidebarUpperContentContainer>
      <SidebarLogo />
      {SIDEBAR_NAVIGATION.map((item, index) => {
        const roles =
          item?.roles ||
          rolesConstants?.map?.((singleRole) => singleRole?.roleName);
        return (
          <WithPermissions key={index} roles={roles}>
            <SidebarNavigationItem
              item={item}
              $parentRoles={roles}
            />
          </WithPermissions>
        );
      })}
    </SidebarUpperContentContainer>
  );
};

SidebarUpperContent.propTypes = {
  children: PropTypes.node,
};

export default SidebarUpperContent;
