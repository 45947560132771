import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../TextInputField/TextInputField";

const CompanyEmailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="email"
        label={t("companySettings.email")}
        value={formik?.values?.email}
        onChange={formik?.handleChange}
        error={formik?.touched?.email && formik?.errors?.email}
        helperText={formik?.touched?.email && formik?.errors?.email}
        requiredField={props?.isRequired}
      />
    </>
  );
};

CompanyEmailField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default CompanyEmailField;
