import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import newStuffValidationSchema from "../../../../validations/newStuffValidationSchema";
import TextInputField from "../../../TextInputField/TextInputField";
import { ErrorMessage } from "../NewStuffForm.styled";

const EmailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="email"
        label={t("stuffs.email")}
        placeholder={t("stuffs.newStuffPlaceholders.email")}
        value={formik?.values?.email}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.email &&
                formik?.touched?.email &&
                "40px !important",
              width:
                formik?.errors?.email &&
                formik?.touched?.email &&
                "314px !important",
            },
          },
        }}
        onChange={formik?.handleChange}
        error={formik?.errors?.email && formik?.touched?.email}
        helperText={formik?.errors?.email && formik?.touched?.email}
        requiredField={
          newStuffValidationSchema?.fields?.email?.exclusiveTests?.required
        }
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.email && formik?.touched?.email && (
        <ErrorMessage>{formik?.errors?.email}</ErrorMessage>
      )}
    </>
  );
};

EmailField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default EmailField;
