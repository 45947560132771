import * as Yup from "yup";
import i18n from "../i18n";
import { findingFormikConstants } from "../initialValues/findingInitialValues";

export default Yup.object().shape({
  [findingFormikConstants.clientId]: Yup.object().shape({
    value: Yup.number()
      .typeError(i18n.t("findings.form.client.required"))
      .test(
        "required-check",
        i18n.t("findings.form.client.required"),
        (value) => typeof value === "number" && value > 0
      ),
  }),
  [findingFormikConstants.location]: Yup.object().shape({
    value: Yup.string().test(
      "required-check",
      i18n.t("findings.form.location.required"),
      (value) => value?.length > 0
    ),
  }),
  [findingFormikConstants.dateOfChecking]: Yup.object().shape({
    value: Yup.string().test(
      "required-check",
      i18n.t("findings.form.dateOfChecking.required"),
      (value) => value?.length > 0
    ),
  }),
  [findingFormikConstants.dateOfFinding]: Yup.object().shape({
    value: Yup.string(),
  }),
  // .required(
  //   i18n.t("findings.form.dateOfFinding.required")
  // )
  [findingFormikConstants.staffId]: Yup.object().shape({
    value: Yup.number()
      .typeError(i18n.t("findings.form.staff.required"))
      .test(
        "required-check",
        i18n.t("findings.form.staff.required"),
        (value) => typeof value === "number" && value > 0
      ),
  }),
  [findingFormikConstants.description]: Yup.object().shape({
    value: Yup.string(),
  }),
  [findingFormikConstants.note]: Yup.object().shape({
    value: Yup.string(),
  }),
  [findingFormikConstants.facility]: Yup.object().shape({
    value: Yup.string().nullable(),
  }),
});
