import * as Yup from "yup";
import i18n from "../i18n";

export default Yup.object().shape({
  clientName: Yup.string()
    .trim()
    .max(255, i18n.t("clients.clientNameNumberOfCharacters"))
    .required(i18n.t("clients.clientNameRequired")),
  pib: Yup.string()
    .trim()
    .required(i18n.t("clients.pibRequired"))
    .min(9, i18n.t("clients.pibNumberOfCharacters"))
    .max(9, i18n.t("clients.pibNumberOfCharacters")),
  address: Yup.string()
    .trim()
    .max(255, i18n.t("clients.addressNumberOfCharacters"))
    .required(i18n.t("clients.addressRequired")),
  phoneNumber: Yup.string().max(
    255,
    i18n.t("clients.phoneNumberNumberOfCharacters")
  ),
  idNumber: Yup.string()
    .trim()
    .required(i18n.t("clients.idNumberRequired"))
    .min(8, i18n.t("clients.idNumberNoOfCharacters"))
    .max(8, i18n.t("clients.idNumberNoOfCharacters")),
  contactEmail: Yup.string()
    .email(i18n.t("clients.emailFormat"))
    .max(255, i18n.t("clients.contactEmailNumberOfCharacters")),

  distributionEmails: Yup.array().of(
    Yup.string()
      .email(i18n.t("clients.emailFormat"))
      .max(255, i18n.t("clients.distributionEmailNumberOfCharacters"))
  ),
});
