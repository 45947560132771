import {
  CLEAR_USERS,
  GET_USERS_ERROR,
  GET_USERS_FETCH,
  GET_USERS_SUCCESS,
  CREATE_USER_ERROR,
  CREATE_USER_FETCH,
  CREATE_USER_SUCCESS,
  EDIT_USER_ERROR,
  EDIT_USER_FETCH,
  EDIT_USER_SUCCESS,
  SINGLE_USER_ERROR,
  SINGLE_USER_FETCH,
  SINGLE_USER_SUCCESS,
  MY_USER_ERROR,
  MY_USER_FETCH,
  MY_USER_SUCCESS,
  MY_USER_SET,
  USER_CHANGE_PASSWORD_ERROR,
  USER_CHANGE_PASSWORD_FETCH,
  USER_CHANGE_PASSWORD_SUCCESS,
  SET_TOTAL_USERS,
  SET_USER,
  SET_USERS,
  SET_USER_ERROR,
  SINGLE_USER_SET,
  GET_USERS_CLEAR,
  SET_USERS_ERROR_MESSAGE,
  BLOCK_USER_FETCH,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_ERROR,
  UNBLOCK_USER_FETCH,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_ERROR,
} from "./userActionConstants";

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setUserError = (payload) => ({
  type: SET_USER_ERROR,
  payload,
});

export const fetchUsers = (payload) => ({
  type: GET_USERS_FETCH,
  payload,
});
export const fetchUsersSuccess = (payload) => ({
  type: GET_USERS_SUCCESS,
  payload,
});
export const fetchUsersError = (payload) => ({
  type: GET_USERS_ERROR,
  payload,
});

export const fetchUsersClear = () => ({
  type: GET_USERS_CLEAR,
});

export const fetchCreateUser = (payload) => ({
  type: CREATE_USER_FETCH,
  payload,
});
export const fetchCreateUserSuccess = (payload) => ({
  type: CREATE_USER_SUCCESS,
  payload,
});
export const fetchCreateUserError = (payload) => ({
  type: CREATE_USER_ERROR,
  payload,
});

export const fetchEditUser = (payload) => ({
  type: EDIT_USER_FETCH,
  payload,
});
export const fetchEditUserSuccess = (payload) => ({
  type: EDIT_USER_SUCCESS,
  payload,
});
export const fetchEditUserError = (payload) => ({
  type: EDIT_USER_ERROR,
  payload,
});

export const fetchBlockUser = (payload) => ({
  type: BLOCK_USER_FETCH,
  payload,
});
export const fetchBlockUserSuccess = (payload) => ({
  type: BLOCK_USER_SUCCESS,
  payload,
});
export const fetchBlockUserError = (payload) => ({
  type: BLOCK_USER_ERROR,
  payload,
});

export const fetchUnblockUser = (payload) => ({
  type: UNBLOCK_USER_FETCH,
  payload,
});
export const fetchUnblockUserSuccess = (payload) => ({
  type: UNBLOCK_USER_SUCCESS,
  payload,
});
export const fetchUnblockUserError = (payload) => ({
  type: UNBLOCK_USER_ERROR,
  payload,
});

export const fetchSingleUser = (payload) => ({
  type: SINGLE_USER_FETCH,
  payload,
});
export const fetchSingleUserSuccess = (payload) => ({
  type: SINGLE_USER_SUCCESS,
  payload,
});
export const fetchSingleUserError = (payload) => ({
  type: SINGLE_USER_ERROR,
  payload,
});

export const fetchMyUser = (payload) => ({
  type: MY_USER_FETCH,
  payload,
});
export const fetchMyUserSuccess = (payload) => ({
  type: MY_USER_SUCCESS,
  payload,
});
export const fetchMyUserError = (payload) => ({
  type: MY_USER_ERROR,
  payload,
});
export const setMyUser = (payload) => ({
  type: MY_USER_SET,
  payload,
});

export const fetchChangeUserPassword = (payload) => ({
  type: USER_CHANGE_PASSWORD_FETCH,
  payload,
});
export const fetchChangeUserPasswordSuccess = (payload) => ({
  type: USER_CHANGE_PASSWORD_SUCCESS,
  payload,
});
export const fetchChangeUserPasswordError = (payload) => ({
  type: USER_CHANGE_PASSWORD_ERROR,
  payload,
});

export const setSingleUser = (payload) => ({
  type: SINGLE_USER_SET,
  payload,
});
export const setUsersErrorMessage = (payload) => ({
  type: SET_USERS_ERROR_MESSAGE,
  payload,
});
export const setUsers = (payload) => ({
  type: SET_USERS,
  payload,
});
export const setTotalUsers = (payload) => ({
  type: SET_TOTAL_USERS,
  payload,
});
export const clearUsers = () => ({
  type: CLEAR_USERS,
});
