import React, { useState, useRef, useCallback } from "react";
import { Container } from "./EmptyFindingScanningSection.styled";
import { useDispatch } from "react-redux";
import { replaceFindingSubjectOrderNumber } from "../../../../../../store/actions/findings/findingsActions";
import { FINDING_SUBJECT_DND } from "../../../../../../constants/dndConstants";
import { useDrop } from "react-dnd";

const EmptyFindingScanningSection = () => {
  const [canDrop, setCanDrop] = useState(false);
  const dispatch = useDispatch();
  const containerRef = useRef();

  const onDrop = (dropItem) => {
    dispatch(
      replaceFindingSubjectOrderNumber({
        firstOrderNumber: dropItem?.orderNumber,
        secondOrderNumber: 0,
      })
    );
  };

  const hoverTimeoutFunction = useCallback(
    () => setCanDrop(false),
    [setCanDrop]
  );

  const [{ handlerId }, drop] = useDrop(
    {
      accept: FINDING_SUBJECT_DND,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop: onDrop,
      hover(item, monitor) {
        if (!containerRef.current) {
          return;
        }
        const dragIndex = item?.orderNumber - 1;
        const hoverIndex = 0;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = containerRef.current?.getBoundingClientRect?.();
        // const hoverBound = hoverBoundingRect.top;
        const clientOffset = monitor.getClientOffset?.();
        // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // console.log(hoverBound);
        // console.log(clientOffset.y);
        // console.log(hoverClientY);
        setCanDrop(
          clientOffset?.y > hoverBoundingRect?.top &&
            clientOffset?.y < hoverBoundingRect?.bottom
        );
        if (hoverTimeout) clearTimeout(hoverTimeout);
        hoverTimeout = setTimeout(hoverTimeoutFunction, 200);
      },
    },
    []
  );
  drop(containerRef);
  let hoverTimeout;

  return (
    <Container
      data-handler-id={handlerId}
      ref={containerRef}
      $canDrop={canDrop}
    />
  );
};

export default EmptyFindingScanningSection;
