import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererLicensePreviewContainer,
  ActionRendererEditStuffIconContainer,
  ActionRendererDeleteStuffIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import LicensePreviewIcon from "../../../../Icon/Icons/LicensePreviewIcon";
import LicensePreviewDisableIcon from "../../../../Icon/Icons/LicensePreviewDisableIcon";
import {
  closeLoadingModal,
  closeModal,
  toggleImagePreviewModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import { EDIT_STUFF_PAGE } from "../../../../../constants/pages";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import { fetchImages } from "../../../../../store/actions/images/imagesActions";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import { fetchDeleteStuffs } from "../../../../../store/actions/stuffs/stuffsActions";
import { makeToastMessage } from "../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../constants/toastConstants";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleApiResponseSuccessLicense = (payload) => {
    props?.dispatch(
      toggleImagePreviewModal({
        title:
          i18n.t("stuffs.modal.previewLicense.title") +
          " " +
          props?.value?.stuffs,
        image: payload?.imagesArray[0],
        rank: 1,
      })
    );
  };
  const handleShowLicensePreview = () => {
    props?.dispatch(
      fetchImages({
        images: [props?.value.license],
        handleApiResponseSuccess: handleApiResponseSuccessLicense,
      })
    );
  };

  const handleApiResponseSuccessDelete = () => {
    props?.dispatch(closeModal());
    makeToastMessage(
      {
        title: props?.value.t("toast.success.deletedTitle", {
          typeOfData: props?.value.t("stuffs.typeOfData"),
        }),
        description: props?.value.t("toast.success.deletedDescription", {
          name: props?.value.stuffs,
          typeOfData: props?.value.t("stuffs.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    if (props?.value?.totalData === 1) props?.value.tableRef.current.resetPage();
    else props?.value.tableRef.current.forceRefetch();
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleDeleteStuff = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteStuffs({
        stuffId: props?.value?.stuffId,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("stuffs.modal.delete.title"),
        description: {
          first: i18n.t("stuffs.modal.delete.description.1"),
          second: i18n.t("stuffs.modal.delete.description.2"),
        },
        onSubmit: handleDeleteStuff,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer $mobile={props?.isMobile}>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip
          title={
            props?.value?.license === ""
              ? i18n.t("stuffs.tooltip.noLicense")
              : i18n.t("stuffs.tooltip.licencePreview")
          }
        >
          <ActionRendererLicensePreviewContainer
            onClick={
              props?.value?.license === "" ? "" : handleShowLicensePreview
            }
          >
            {props?.value?.license === "" ? (
              <LicensePreviewDisableIcon />
            ) : (
              <LicensePreviewIcon />
            )}
          </ActionRendererLicensePreviewContainer>
        </Tooltip>
      </WithPermissions>
      <Tooltip title={i18n.t("stuffs.editStuff")}>
        <ActionRendererEditStuffIconContainer
          to={replaceInRoute(EDIT_STUFF_PAGE, {
            stuffId: props?.value?.stuffId,
          })}
        >
          <EditIcon />
        </ActionRendererEditStuffIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("stuffs.deleteStuff")}>
          <ActionRendererDeleteStuffIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteStuffIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  isMobile: PropTypes.bool,
};

export default ActionRenderer;
