export default {
  authentications: {
    login: "v1/Users/authenticate",
    logout: "v1/Users/authenticate/logout",
  },
  users: {
    getUsers: "v1/Users",
    createUser: "v1/Users",
    getSingleUser: "v1/Users/{userId}",
    editUser: "v1/Users/{userId}",
    changeUserPassword: "v1/Users/{userId}/changePassword",
    blockUser: "v1/Users/{userId}/block",
    unblockUser: "v1/Users/{userId}/unblock",
  },
  standards: {
    getStandards: "v1/Standards",
    getSingleStandard: "v1/Standards/{standardId}",
    createStandard: "v1/Standards",
    editStandard: "v1/Standards/{standardId}",
    deleteStandard: "v1/Standards/{standardId}",
  },
  clients: {
    getClients: "v1/Clients",
    createClient: "v1/Clients",
    editClient: "v1/Clients/{clientId}",
    getSingleClient: "v1/Clients/{clientId}",
    deleteClient: "v1/Clients/{clientId}",
  },
  measuringInstruments: {
    getMeasuringInstruments: "v1/MeasuringInstruments",
    getSingleMeasuringInstrument:
      "v1/MeasuringInstruments/{measuringInstrumentId}",
    createMeasuringInstrument: "v1/MeasuringInstruments",
    editMeasuringInstrument: "v1/MeasuringInstruments/{measuringInstrumentId}",
    deleteMeasuringInstruments:
      "v1/MeasuringInstruments/{measuringInstrumentId}",
  },
  findings: {
    getFindings: "v1/Findings",
    getSingleFinding: "v1/Findings/{findingId}",
    getFindingPreview: "v1/Findings/{findingId}/preview",
    createFinding: "v1/Findings",
    editFinding: "v1/Findings/{findingId}",
    printFinding: "v1/Findings/{findingId}/pdf",
    editSingleItem: "v1/Findings/items/{findingId}",
    changeState: "v1/Findings/ChangeState",
    sendFindingMails: "v1/Findings/SendEmail",
    fetchFindingSubject: "v1/Findings/TemplateSubject/{subjectId}",
    changeSectionNotes: "v1/Findings/sections/{findingId}",
    changeSubjectConclusions: "v1/Findings/subjects/{findingId}",
    patchFinding: "v1/Findings/{findingId}",
    copyFinding: "v1/Findings/{findingId}/copy",
    findingTypes: "v1/Findings/FindingTypes",
  },
  scanningObjects: {
    getScanningObjects: "v1/ScanningSubjects",
    getSingleScanningObject: "v1/ScanningSubjects/{scanningObjectId}",
    createScanningObject: "v1/ScanningSubjects",
    editScanningObject: "v1/ScanningSubjects/{scanningObjectId}",
    deleteScanningObject: "v1/ScanningSubjects/{scanningObjectId}",
    copyScanningObject:
      "v1/ScanningSubjects/CopyScanningSubject/{scanningObjectId}",
  },
  checklistTemplate: {
    createChecklistTemplate: "v1/ChecklistTemplates",
    editChecklistTemplate: "v1/ChecklistTemplates/{checklistTemplateId}",
  },
  stuffs: {
    getStuffs: "v1/Staff",
    createStuff: "v1/Staff",
    editStuff: "v1/Staff/{stuffId}",
    getSingleStuff: "v1/Staff/{stuffId}",
    deleteStuff: "v1/Staff/{stuffId}",
  },
  companies: {
    getCompanies: "v1/Companies",
    createCompany: "v1/Companies",
    getSingleCompany: "v1/Companies/{companyId}",
    editCompany: "v1/Companies/{companyId}",
    createAPRImages: "v1/Companies/AddAPRImage",
    editAPRImages: "v1/Companies/AddAPRImage/{companyId}",
    emailVerification: "v1/Companies/{companyId}/ValidateEmailInfo",
    uploadJsonSecret: "v1/Companies/uploadJsonSecret/{companyId}",
    validateDriveGet: "v1/Companies/validateDrive/{companyId}",
    validateDrivePost: "v1/Companies/{companyId}/ValidateDrive",
  },
  images: {
    postImages: "v1/Images",
    getImages: "v1/Images",
  },
};
