import React from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  SingleInputContainer,
} from "../SetMeasuringInstrumentModal.styled";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../../Field/AutocompleteField/AutocompleteField";

const FindingTypesField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  return (
    <SingleInputContainer>
      <AutocompleteField
        options={props?.data}
        label={t("standards.modal.chooseTypes")}
        title={t("standards.modal.findingTypes")}
        onChange={(query) =>
          formik?.setFieldValue?.(
            newMeasuringInstrumentFormikConstants.findingTypes,
            query
          )
        }
        onDelete={(value) =>
          formik?.setFieldValue?.(
            newMeasuringInstrumentFormikConstants.findingTypes,
            formik?.values?.[
              newMeasuringInstrumentFormikConstants.findingTypes
            ]?.filter?.((item) => item?.id !== value?.id)
          )
        }
        isMultiple
        dropdownLabel={["caption"]}
        value={
          formik?.values?.[newMeasuringInstrumentFormikConstants.findingTypes]
        }
        disabled={props?.isReadOnly}
      />
      {formik?.errors?.[newMeasuringInstrumentFormikConstants.findingTypes] &&
        formik?.touched?.[newMeasuringInstrumentFormikConstants.findingTypes] && (
          <ErrorMessage>
            {formik?.errors?.[newMeasuringInstrumentFormikConstants.findingTypes]}
          </ErrorMessage>
        )}
    </SingleInputContainer>
  );
};

FindingTypesField.propTypes = {
  formik: PropTypes.object,
  data: PropTypes.array,
  isReadOnly: PropTypes.bool,
};

export default FindingTypesField;
