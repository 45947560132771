import React from "react";
import PropTypes from "prop-types";
import {
  ActionsContainer,
  CopyFindingIcon,
  EditFindingNumber,
  ExportFindingIcon,
  EyeIcon,
  FindingDetailsContainer,
  FindingTitleDetails,
  FindingTypeContainer,
  NewFindingTitleContainer,
  SendMailIcon,
  TitleContainer,
  UndoFindingIcon,
} from "./NewFindingTitle.styled";
import Title from "../../../Typography/Title/Title";
import Tooltip from "../../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import FindingsActionButtons from "../../FindingsActionButtons/FindingsActionButtons";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useSelector } from "react-redux";
import { selectIsFindingSettingsChanged } from "../../../../store/selectors/findingsSelectors";

const NewFindingTitle = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  return (
    <NewFindingTitleContainer>
      <FindingDetailsContainer>
        <FindingTitleDetails>
          <TitleContainer>
            <Title title={props?.title} />
            {props?.isEditing && (
              <EditFindingNumber>{`#${props?.number || ""}`}</EditFindingNumber>
            )}
          </TitleContainer>
          {props?.isEditing && (
            <ActionsContainer>
              <Tooltip
                title={
                  isFindingChanged ? t("common.saveFirst") : t("findings.copy")
                }
              >
                <CopyFindingIcon
                  $disabledIcon={isFindingChanged}
                  onClick={props?.onCopyClick}
                />
              </Tooltip>
              <Tooltip
                title={
                  isFindingChanged ? t("common.saveFirst") : t("common.pdf")
                }
              >
                <ExportFindingIcon
                  $disabledIcon={props?.disableExport}
                  onClick={props?.onExportClick}
                />
              </Tooltip>
              {!isMobile && (
                <Tooltip
                  title={
                    isFindingChanged
                      ? t("common.saveFirst")
                      : t("findingPreview.title")
                  }
                >
                  <EyeIcon
                    $disabledIcon={props?.disableExport}
                    onClick={props?.onPreviewClick}
                  />
                </Tooltip>
              )}
              {props?.enableSendMail && (
                <Tooltip title={t("findings.forwardToMail")}>
                  <SendMailIcon
                    $disabledIcon={props?.disableExport}
                    onClick={props?.onSendMailClick}
                  />
                </Tooltip>
              )}
              {!props?.hideUndo && (
                <Tooltip title={t("findings.undoCancelled")}>
                  <UndoFindingIcon
                    $disabledIcon={props?.hideUndo}
                    onClick={props?.onUndoClick}
                  />
                </Tooltip>
              )}
            </ActionsContainer>
          )}
        </FindingTitleDetails>
        <FindingTypeContainer>{props?.findingType}</FindingTypeContainer>
      </FindingDetailsContainer>
      {!isMobile && (
        <FindingsActionButtons
          disabledSaveButton={props?.disabledSaveButton}
          handleClickSubmitButton={props?.handleClickSubmitButton}
        />
      )}
    </NewFindingTitleContainer>
  );
};

NewFindingTitle.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  number: PropTypes.string,
  isEditing: PropTypes.bool,
  onCopyClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
  disableExport: PropTypes.bool,
  enableSendMail: PropTypes.bool,
  onExportClick: PropTypes.func,
  handleClickSubmitButton: PropTypes.func,
  hideUndo: PropTypes.bool,
  disabledSaveButton: PropTypes.bool,
  onUndoClick: PropTypes.func,
  onSendMailClick: PropTypes.func,
  findingType: PropTypes.string,
};

export default NewFindingTitle;
