import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { closeDeleteModal } from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import {
  DeleteModalDescription,
  SetDeleteModalContainer,
  CancelButton,
  SubmitButton,
  DescriptionContainer,
} from "./SetDeleteModal.styled";
import BackdropComponent from "../../MUI/BackdropComponent";

const SetDeleteModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeDeleteModal());
  };

  const handleSubmit = () => {
    props?.onSubmit();
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SetDeleteModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <DescriptionContainer>
          <DeleteModalDescription>
            {props?.description.first}
          </DeleteModalDescription>
          <DeleteModalDescription>
            {props?.description.second}
          </DeleteModalDescription>
        </DescriptionContainer>
        <ButtonsContainer>
          <SubmitButton onClick={handleSubmit}>
            {t("common.remove").toUpperCase()}
          </SubmitButton>
          <CancelButton onClick={handleCloseModal}>
            {t("common.cancel")}
          </CancelButton>
        </ButtonsContainer>
      </SetDeleteModalContainer>
    </>
  );
};

SetDeleteModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.object,
  onSubmit: PropTypes.func,
};
SetDeleteModal.defaultProps = {
  title: "Promeni lozinku",
  onSubmit: () => {},
};

export default SetDeleteModal;
