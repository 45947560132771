import React from "react";
import PropTypes from "prop-types";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { DateTextField, SingleInputTitle } from "./DateField.styled";
import {
  constructDate,
  formatDate,
} from "../../../../util/helpers/dateHelpers";
const DateField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();

  return (
    <>
      <SingleInputTitle>
        {t("measuringInstruments.modal.fields.date.label")}
      </SingleInputTitle>
      <ReactDatePicker
        placeholderText={t(
          "measuringInstruments.modal.fields.date.placeholder"
        )}
        disabled={props?.disabled}
        minDate={new Date()}
        selected={
          formik?.values?.[newMeasuringInstrumentFormikConstants.date]
            ? new Date(
                constructDate(
                  formik?.values?.[newMeasuringInstrumentFormikConstants.date]
                )
              )
            : null
        }
        dateFormat="dd.MM.yyyy"
        onChange={(date) =>
          formik?.setFieldValue?.(
            newMeasuringInstrumentFormikConstants.date,
            formatDate(date)
          )
        }
        customInput={
          <DateTextField
            date
            disabledField={props?.disabled}
            requiredField={false}
            name={newMeasuringInstrumentFormikConstants.date}
            placeholder={t(
              "measuringInstruments.modal.fields.date.placeholder"
            )}
            value={formik?.values?.[newMeasuringInstrumentFormikConstants.date]}
            onChange={formik?.handleChange}
            error={
              formik?.touched?.[newMeasuringInstrumentFormikConstants.date] &&
              formik?.errors?.[newMeasuringInstrumentFormikConstants.date]
            }
            helperText={
              formik?.errors?.[newMeasuringInstrumentFormikConstants.date] &&
              formik?.touched?.[newMeasuringInstrumentFormikConstants.date]
            }
            fullWidth
          />
        }
      />
    </>
  );
};

DateField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DateField;
