import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/Findings/FindingsTable/Renderers/ActionRenderer/ActionRenderer";
import StatusRenderer from "../../components/Findings/FindingsTable/Renderers/StatusRenderer/StatusRenderer";
import TypeRenderer from "../../components/Findings/FindingsTable/Renderers/TypeRenderer/TypeRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  number: {
    id: 0,
    i18key: "findings.table.number.columnTitle",
    style: {
      minWidth: "140px",
    },
    backendProperty: "findingNumber",
    renderer: UserRenderer,
    redirect: true,
    setMaxHeight: true,
  },
  date: {
    id: 1,
    i18key: "findings.table.createdAtUtc.columnTitle",
    backendProperty: "date",
    style: {
      minWidth: "130px",
    },
    setMaxHeight: true,
    // showLabel: true,
  },
  client: {
    id: 2,
    i18key: "findings.table.client.columnTitle",
    backendProperty: "Client.Name",
    style: {
      minWidth: "140px",
    },
    setMaxHeight: true,
  },
  location: {
    id: 3,
    i18key: "findings.table.location.columnTitle",
    style: {
      minWidth: "140px",
    },
    backendProperty: "location",
    setMaxHeight: true,
    // showLabel: true,
  },
  findingType: {
    id: 4,
    i18key: "findings.table.findingType.columnTitle",
    renderer: TypeRenderer,
    disableSort: true,
    style: {
      paddingRight: "0",
      minWidth: "230px",
    },
    backendProperty: "findingType",
    setMaxHeight: true,
  },
  status: {
    id: 5,
    i18key: "findings.table.status.columnTitle",
    renderer: StatusRenderer,
    disableSort: true,
    style: {
      textAlign: "center",
      justifyContent: "center",
      paddingRight: "0",
      minWidth: "160px",
    },
    backendProperty: "findingStateId",
    setMaxHeight: true,
  },
  staff: {
    id: 6,
    i18key: "findings.table.staff.columnTitle",
    style: {
      minWidth: "180px",
    },
    backendProperty: "Staff.FirstName",
    setMaxHeight: true,
  },
  lastChanges: {
    id: 7,
    i18key: "findings.table.lastChanges.columnTitle",
    backendProperty: "updatedAtUtc",
    renderer: LastChangesRenderer,
    hideOnMobile: true,
    style: {
      textAlign: "center",
      minWidth: "220px",
      justifyContent: "center",
    },
    setMaxHeight: true,
  },
  actions: {
    id: 8,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "135px",
      width: "135px",
      maxWidth: "135px",
      flex: "0 0",
      paddingLeft: "0",
      justifyContent: "right",
    },
    setMaxHeight: true,
  },
  header: {
    id: 9,
    i18key: "findings.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
};
