import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../TextInputField/TextInputField";

const BankAccountNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="bankAccountNumber"
        label={t("companySettings.bankAccountNumber")}
        // type="number"
        value={formik?.values?.bankAccountNumber}
        onChange={formik?.handleChange}
        error={
          formik?.touched?.bankAccountNumber && formik?.errors?.bankAccountNumber
        }
        helperText={
          formik?.touched?.bankAccountNumber && formik?.errors?.bankAccountNumber
        }
        requiredField={props?.isRequired}
      />
    </>
  );
};

BankAccountNumberField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default BankAccountNumberField;
