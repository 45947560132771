import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatDateTime, formatDate } from "../../../util/helpers/dateHelpers";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../../constants/rolesConstants";
import {
  FINDINGS_EDIT_PAGE,
  FINDINGS_NEW_PAGE,
} from "../../../constants/pages";
import findingsTableConstants from "../../../constants/tableConstants/findingsTableConstants";
import {
  selectFindingTypes,
  selectFindingsData,
  selectTotalFindings,
} from "../../../store/selectors/findingsSelectors";
import {
  clearFindings,
  fetchFindings,
} from "../../../store/actions/findings/findingsActions";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import FindingsFilterComponent from "./FilterComponents/FindingsFilterComponent";
import { useState } from "react";
import DropdownFilterComponent from "./FilterComponents/DropdownFilterComponent/DropdownFilterComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectClientsData } from "../../../store/selectors/clientsSelector";
import { selectStuffsData } from "../../../store/selectors/stuffsSelector";
import PickerFilterComponent from "./FilterComponents/PickerFilterComponent/PickerFilterComponent";
import FindingBreadIcon from "../../Icon/Icons/FindingBreadIcon";
import SearchFilterComponent from "./FilterComponents/SearchFilterComponent/SearchFilterComponent";
import { statusTableConstantsAsArray } from "../../../constants/findingStatusConstants";
import {
  closeModal,
  toggleSetFindingTypesModal,
} from "../../../store/actions/modal/modalActions";

const FindingsTable = () => {
  const history = useHistory();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const clients = useSelector(selectClientsData);
  const stuffs = useSelector(selectStuffsData);
  const findingTypes = useSelector(selectFindingTypes);
  const { hasRole } = useAuth();
  const [customPayload, setCustomPayload] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedStuff, setSelectedStuff] = useState(null);
  const [selectedFindingType, setSelectedFindingType] = useState(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    isSelectAll: false,
    selected: [],
  });
  const [searchNumber, setSearchNumber] = useState({
    value: null,
    isValid: false,
  });
  const [keys, setKeys] = useState(5);

  useEffect(() => {
    setCustomPayload({
      searchNumber: searchNumber?.isValid ? searchNumber?.value : null,
      date: { start: startingDate, end: endingDate },
      client: selectedClient,
      stuff: selectedStuff,
      findingType: selectedFindingType,
      status: selectedCheckbox?.selected,
    });
    if (tableRef?.current) {
      tableRef?.current?.resetPage();
    }
  }, [
    startingDate,
    endingDate,
    selectedClient,
    selectedStuff,
    selectedFindingType,
    selectedCheckbox,
  ]);

  useEffect(() => {
    if (searchNumber?.isValid || !searchNumber?.value) {
      setCustomPayload({
        searchNumber: searchNumber?.isValid ? searchNumber?.value : null,
        date: { start: startingDate, end: endingDate },
        client: selectedClient,
        stuff: selectedStuff,
        findingType: selectedFindingType,
        status: selectedCheckbox?.selected,
      });
      if (tableRef?.current) {
        tableRef?.current?.resetPage();
      }
    }
  }, [searchNumber]);

  const hideAddAndUpdate = useMemo(() => {
    return !(hasRole(OPERATOR_ROLE) || hasRole(MANAGER_ROLE));
  }, [hasRole]);

  const handleSubmit = (value) => {
    history?.push(FINDINGS_NEW_PAGE, {
      findingType: value,
    });
    dispatch(closeModal());
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetFindingTypesModal({
        title: t("findings.createFindingTitle"),
        rank: 0,
        handleSubmit,
      })
    );
  };

  const handleRedirect = (findingId) =>
    replaceInRoute(FINDINGS_EDIT_PAGE, {
      findingId: findingId,
    });

  const refetch = useCallback(() => {
    if (tableRef?.current) {
      tableRef?.current?.forceRefetch();
    }
  }, []);

  //  useEffect(() => {
  //    if (location?.state?.name) {
  //      const isEditing = location.state?.isEditing;
  //      const titleI18Key = isEditing
  //        ? "toast.success.editedTitle"
  //        : "toast.success.createdTitle";
  //      const descriptionI18Key = isEditing
  //        ? "toast.success.editedDescription"
  //        : "toast.success.createdDescription";
  //      makeToastMessage(
  //        {
  //          title: t(titleI18Key, {
  //            typeOfData: t("findings.typeOfData"),
  //          }),
  //          description: t(descriptionI18Key, {
  //            name: location.state?.name,
  //            typeOfData: t("findings.typeOfData").toLowerCase(),
  //          }),
  //        },
  //        {
  //          containerId: APPLICATION_TOAST_CONTAINER_ID,
  //        }
  //      );
  //      let currentState = { ...location?.state };
  //      delete currentState.name;
  //      delete currentState.isEditing;
  //      history.replace({
  //        state: currentState,
  //      });
  //    }
  //    if (location?.state?.refetch) {
  //      tableRef?.current?.forceRefetch();
  //    }
  //  }, [location]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(findingsTableConstants).map((property) => ({
      ...findingsTableConstants[property],
      field: t(findingsTableConstants[property].i18key),
      propertyName: property,
      backendProperty:
        findingsTableConstants[property].backendProperty || property,
      style: findingsTableConstants[property]?.style,
      renderer: findingsTableConstants[property]?.renderer || TextRenderer,
      rendererProps: {
        refetch,
      },
    }));
    if (hideAddAndUpdate) return columnsObject?.slice?.(0, -1);
    return columnsObject;
  }, [findingsTableConstants, t, hasRole]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.map?.((singleData) => {
        let mappedDataObject = {
          number: `#${singleData?.findingNumber || singleData?.number}`,
          date: formatDate(singleData?.date),
          client: singleData?.clientName,
          location: singleData?.location,
          findingType: singleData?.findingType,
          status:
            singleData?.findingStateId === 0 ? 1 : singleData?.findingStateId,
          staff: `${singleData?.staff?.firstName} ${singleData?.staff?.lastName}`,
          lastChanges: {
            date: formatDateTime(
              singleData?.updatedAtUtc || new Date().toString()
            ),
            author: `${singleData?.updatedByUser?.firstName || ""} ${
              singleData?.updatedByUser?.lastName || ""
            }`,
          },
          id: singleData?.id,
          actions: {
            findingId: singleData?.id,
            date: formatDateTime(
              singleData?.createdAtUtc || new Date().toString()
            ),
            client: singleData?.client?.name,
            location: singleData?.location,
            findingNumber: singleData?.findingNumber,
            staff: `${singleData?.staff?.firstName} ${singleData?.staff?.lastName}`,
            lastChanges: {
              date: formatDateTime(
                singleData?.updatedAtUtc || new Date().toString()
              ),
              author: `${singleData?.updatedByUser?.firstName || ""} ${
                singleData?.updatedByUser?.lastName || ""
              }`,
            },
            status:
              singleData?.findingStateId === 0 ? 1 : singleData?.findingStateId,
          },
        };
        if (hideAddAndUpdate) delete mappedDataObject?.actions;
        return mappedDataObject;
      });
    },
    [hasRole]
  );

  const handleChangeCheckbox = (checked) => {
    if (selectedCheckbox?.isSelectAll) {
      setSelectedCheckbox({
        isSelectAll: false,
        selected: [checked],
      });
    } else {
      if (selectedCheckbox?.selected?.find?.((selected) => selected === checked)) {
        setSelectedCheckbox((prevState) => {
          return {
            isSelectAll: false,
            selected: prevState?.selected?.filter?.(
              (oldSelected) => oldSelected !== checked
            ),
          };
        });
      } else {
        setSelectedCheckbox((prevState) => {
          return {
            isSelectAll: false,
            selected: [...prevState?.selected, checked],
          };
        });
      }
    }
  };

  const handleSelectAllCheckboxes = () => {
    setSelectedCheckbox({
      isSelectAll: true,
      selected: statusTableConstantsAsArray?.map?.((entity) => entity?.id),
    });
  };

  const handleResetFilter = () => {
    setStartingDate("");
    setEndingDate("");
    setSelectedClient(null);
    setSelectedStuff(null);
    setSelectedFindingType(null);
    setKeys((prevVal) => prevVal + 1);
    setSelectedCheckbox({
      isSelectAll: false,
      selected: [],
    });
    setSearchNumber({
      value: null,
      isValid: false,
    });
  };

  const filterComponents = [
    <SearchFilterComponent
      key={0}
      label={t("findings.filter.findingId")}
      placeholder={t("findings.filter.placeholder.input")}
      icon={<FindingBreadIcon />}
      value={searchNumber?.value}
      setSearch={setSearchNumber}
    />,
    <FindingsFilterComponent
      startingDate={startingDate}
      setStartingDate={setStartingDate}
      endingDate={endingDate}
      setEndingDate={setEndingDate}
      key={1}
    />,
    <DropdownFilterComponent
      key={keys}
      data={clients}
      label={t("findings.filter.client")}
      placeholder={t("findings.filter.placeholder.client")}
      selectedData={selectedClient}
      setSelectedData={setSelectedClient}
      dropdownLabel={["name"]}
    />,
    <DropdownFilterComponent
      key={keys + 1}
      data={stuffs}
      label={t("findings.filter.stuff")}
      placeholder={t("findings.filter.placeholder.stuff")}
      selectedData={selectedStuff}
      setSelectedData={setSelectedStuff}
      dropdownLabel={["firstName", "lastName"]}
    />,
    <DropdownFilterComponent
      key={keys + 2}
      data={findingTypes}
      label={t("findings.filter.findingType")}
      placeholder={t("findings.filter.placeholder.findingType")}
      selectedData={selectedFindingType}
      setSelectedData={setSelectedFindingType}
      dropdownLabel={["caption"]}
    />,
    <PickerFilterComponent
      key={4}
      data={statusTableConstantsAsArray}
      label={t("findings.filter.state")}
      selected={selectedCheckbox?.selected}
      isSelectAll={selectedCheckbox?.isSelectAll}
      selectAll={handleSelectAllCheckboxes}
      setSelected={handleChangeCheckbox}
      icon={<FindingBreadIcon />}
    />,
  ];

  return (
    <Table
      showFilter
      filterResetFunc={handleResetFilter}
      hideSearch
      sortDesc
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("findings.table.searchPlaceholder")}
      dataSelector={selectFindingsData}
      totalDataSelector={selectTotalFindings}
      customPayload={customPayload}
      dispatchFunction={fetchFindings}
      mapDataFunction={mapDataFunction}
      redirectHrefFunction={handleRedirect}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearFindings}
      filterComponents={filterComponents}
      ref={tableRef}
      stickyActions
    />
  );
};

FindingsTable.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default FindingsTable;
