import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../TextInputField/TextInputField";
import Tooltip from "../../../Tooltip/Tooltip";

const CompanyAddressField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={formik?.values?.address?.length > 41 ? formik?.values?.address : ""}
    >
      <TextInputField
        name="address"
        label={t("companySettings.address")}
        value={formik?.values?.address}
        onChange={formik?.handleChange}
        error={formik?.touched?.address && formik?.errors?.address}
        helperText={formik?.touched?.address && formik?.errors?.address}
      />
    </Tooltip>
  );
};

CompanyAddressField.propTypes = {
  formik: PropTypes.object,
};

export default CompanyAddressField;
