import React from "react";
import PropTypes from "prop-types";
import {
  FindingCancelButton,
  FindingsButtonsContainer,
  FindingSubmitButton,
} from "./FindingsActionButtons.styled";
import { useTranslation } from "react-i18next";
import history from "../../../store/utils/history";

const FindingsActionButtons = (props) => {
  const { t } = useTranslation();
  const handleClickCancelButton = () => {
    if (props?.handleClickCancelButton) {
      props?.handleClickCancelButton();
    } else history.goBack();
  };
  const handleClickSubmitButton = () => {
    if (props?.handleClickSubmitButton) {
      props?.handleClickSubmitButton();
    }
  };
  return (
    <FindingsButtonsContainer>
      <FindingCancelButton onClick={handleClickCancelButton}>
        {t("clients.cancel")}
      </FindingCancelButton>
      <FindingSubmitButton
        type="submit"
        variant="contained"
        onClick={handleClickSubmitButton}
        disabled={props?.disabledSaveButton}
      >
        {t("common.save")}
      </FindingSubmitButton>
    </FindingsButtonsContainer>
  );
};

FindingsActionButtons.propTypes = {
  children: PropTypes.node,
  handleClickCancelButton: PropTypes.func,
  handleClickSubmitButton: PropTypes.func,
  disabledSaveButton: PropTypes.bool,
};

export default FindingsActionButtons;
