import {
  CLEAR_COMPANIES,
  MY_COMPANY_SET,
  SET_COMPANIES,
  SET_TOTAL_COMPANIES,
  SINGLE_COMPANY_SET,
} from "../../actions/companies/companiesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  companies: {
    loaded: false,
    data: [],
  },
  totalCompanies: 0,
  singleCompany: {},
  myCompany: {},
};

export default createReducer(
  {
    [SET_COMPANIES]: setCompanies,
    [SINGLE_COMPANY_SET]: setSingleCompany,
    [MY_COMPANY_SET]: setMyCompany,
    [SET_TOTAL_COMPANIES]: setTotalCompanies,
    [CLEAR_COMPANIES]: clearCompanies,
  },
  initialState
);
function setCompanies(state, { payload }) {
  return {
    ...state,
    companies: payload,
  };
}
function setTotalCompanies(state, { payload }) {
  return {
    ...state,
    totalCompanies: payload,
  };
}
function clearCompanies() {
  return initialState;
}
function setSingleCompany(state, { payload }) {
  return {
    ...state,
    singleCompany: payload,
  };
}
function setMyCompany(state, { payload }) {
  return {
    ...state,
    myCompany: payload,
  };
}
