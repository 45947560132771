import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import Link from "../../../Link/Link";

export const SidebarNavigationItemDetailsContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 12px;

  ${(props) =>
    props?.$isActive &&
    css`
      background-color: ${selectedTheme.colors.sidebar.activeItemBackground};
    `}

  &:hover {
    background-color: ${(props) =>
      !props?.$cancelHover &&
      !props?.$isActive &&
      selectedTheme.colors.sidebar.hoveredItem};
  }
`;

export const SidebarNavigationItemIconContainer = styled(Box)`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 12px;
  & path {
    stroke: ${(props) =>
      props?.$changeIconColor &&
      (props?.$isActive
        ? selectedTheme.colors.sidebar.iconActiveColor
        : selectedTheme.colors.sidebar.iconNotActiveColor)};
  }
  &:hover {
    background-color: ${(props) =>
      !props?.$isSidebarOpened && selectedTheme.colors.sidebar.hoveredIcon};
  }
`;

export const SidebarNavigationItemTitleContainer = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  font-weight: 600;
  color: ${(props) =>
    props?.$isActive
      ? selectedTheme.colors.sidebar.activeItemTitle
      : selectedTheme.colors.sidebar.titleColor};
`;

export const SidebarSubnavigationContainer = styled(Box)`
  position: relative;
`;
