import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../../themes";
import SortButton from "../../../../Button/SortButton/SortButton";

export const TableSingleColumnContainer = styled(Box)`
  padding: 12px 24px;
  font-family: "Inter";
  font-weight: 600;
  cursor: ${(props) => !props?.column?.disableSort && "pointer"};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: ${(props) => (props?.column?.mobile ? "none" : "flex")};
  align-items: center;
  color: ${selectedTheme.colors.dashboardTitleColor};
  gap: 12px;
  position: relative;
  width: 100%;
  background-color: ${selectedTheme.colors.dashboardHeaderColor};
  ${(props) =>
    props.column.setMaxHeight &&
    css`
      min-height: 64px;
      max-height: 64px;
    `}
  @media (max-width: 600px) {
    display: ${(props) => (props?.column?.mobile ? "flex" : "none")};
    /* max-width: none !important; */
  }
`;
export const SortingButton = styled(SortButton)`
  width: 0;
`;
