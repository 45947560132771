import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import { EditStuffPageContainer } from "./EditStuffPage.styled";
import {
  fetchEditStuff,
  fetchSingleStuff,
} from "../../../store/actions/stuffs/stuffsActions";
import NewStuffForm from "../../../components/Stuffs/NewStuff/NewStuffForm";
import { selectSingleStuff } from "../../../store/selectors/stuffsSelector";
import LastChanges from "../../../components/LastChanges/LastChanges";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { SINGLE_STUFF_SCOPE } from "../../../store/actions/stuffs/stuffsActionConstants";
import useAuth from "../../../hooks/useAuth";
import { useMemo } from "react";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";

const EditStuffPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const prefilledData = useSelector(selectSingleStuff);
  const isLoading = useSelector(
    selectIsLoadingByActionType(SINGLE_STUFF_SCOPE)
  );
  const { hasRole } = useAuth();

  const readOnlyPage = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  useEffect(() => {
    dispatch(fetchSingleStuff({ stuffId: routeMatch.params?.stuffId }));
  }, [routeMatch]);
  return (
    <EditStuffPageContainer>
      <NewStuffForm
        prefilledData={prefilledData}
        dispatchFunction={fetchEditStuff}
        title={t("stuffs.editStuffTitle")}
        submitText={t("common.save")}
        isEditing
        isLoading={isLoading ? true : false}
        readOnly={readOnlyPage}
      />
      {isLoading ? (
        <></>
      ) : (
        <LastChanges
          created={{
            date: formatDateTime(
              prefilledData?.createdAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.createdByUser?.firstName || ""} ${
              prefilledData?.createdByUser?.lastName || ""
            }`,
          }}
          lastChanges={{
            date: formatDateTime(
              prefilledData?.updatedAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.updatedByUser?.firstName || ""} ${
              prefilledData?.updatedByUser?.lastName || ""
            }`,
          }}
        />
      )}
    </EditStuffPageContainer>
  );
};

EditStuffPage.propTypes = {
  children: PropTypes.node,
};

export default EditStuffPage;
