import React, { useEffect } from "react";
import { useState } from "react";
import { ConclusionTextareaField } from "./ConclusionField.styled";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  setScanningObjectsChanged,
  setScanningObjectsConclusion,
} from "../../../../store/actions/scanningObjects/scanningObjectsActions";

const ConclusionField = ({ placeholder, title, type, conclusion }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleClickConclusion = () => {
    dispatch(setScanningObjectsChanged(true));
  };

  const handleBlurConclusion = (newAnswer) => {
    setValue(newAnswer);
    dispatch(setScanningObjectsChanged(true));
    dispatch(
      setScanningObjectsConclusion({ type: type, conclusion: newAnswer })
    );
  };

  useEffect(() => {
    setValue(conclusion === null ? "" : conclusion);
  }, [conclusion]);

  return (
    <ConclusionTextareaField
      placeholder={placeholder}
      title={title}
      value={value}
      onBlur={handleBlurConclusion}
      onClickFnc={handleClickConclusion}
      isRichText
    />
  );
};

ConclusionField.propTypes = {
  placeholder: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  conclusion: PropTypes.string,
};

export default ConclusionField;
