import React from "react";
import PropTypes from "prop-types";
import SettingsContent from "../../components/Settings/SettingsContent";

const SettingsPage = () => {
  return <SettingsContent />;
};

SettingsPage.propTypes = {
  children: PropTypes.node,
};

export default SettingsPage;
