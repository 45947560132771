import { Box, Icon } from "@mui/material";
import styled from "styled-components";
import { InputRightElement as InputIconElement } from "../../TextInputField/TextInputField.styled";

export const InputContainer = styled(Box)`
  width: 100%;
  position: relative;
  margin-top: -10px;
  & ${InputIconElement} {
    top: ${(props) => !props?.$isError && 0};
  }
`;
export const InputRightElement = styled(Icon)`
  right: 6px;
  top: 0;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  width: 26px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  /* position: absolute; */
  & svg {
    width: 23px !important;
    height: 23px !important;
  }
`;
