import React from "react";
import PropTypes from "prop-types";
import {
  Line,
  SidebarNavigationItemsTooltipContainer,
  SidebarNavigationItemsTooltipItem,
  SidebarNavigationItemsTooltipTitle,
} from "./SidebarNavigationItemsTooltip.styled";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { useMemo } from "react";
import { selectIsSidebarOpened } from "../../../../../store/selectors/sidebarSelector";
import { useSelector } from "react-redux";

const SidebarNavigationItemsTooltip = (props) => {
  const isSidebarOpened = useSelector(selectIsSidebarOpened);

  const hasRoutes = useMemo(
    () => (props?.items ? true : false),
    [props?.items]
  );


  return (
    <SidebarNavigationItemsTooltipContainer
      $fixedWidth={hasRoutes}
      to={props?.route || props?.items?.[0]?.route || ""}
      $isSidebarOpened={isSidebarOpened}
      $hasItems={props?.items}
    >
      <SidebarNavigationItemsTooltipTitle $textWrap={hasRoutes}>
        {props?.title}
      </SidebarNavigationItemsTooltipTitle>
      {props?.items && (
        <>
          <Line />
          {props?.items?.map((singleItem, index) => {
            const roles = singleItem?.roles || props?.$parentRoles;
            return (
              <WithPermissions roles={roles} key={index}>
                <SidebarNavigationItemsTooltipItem
                  to={singleItem?.route || ""}
                >
                  {singleItem?.title}
                </SidebarNavigationItemsTooltipItem>
              </WithPermissions>
            );
          })}
        </>
      )}
    </SidebarNavigationItemsTooltipContainer>
  );
};

SidebarNavigationItemsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  items: PropTypes.array,
  $parentRoles: PropTypes.array,
  route: PropTypes.string,
};

export default SidebarNavigationItemsTooltip;
