import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";

export const FindingsButtonsContainer = styled(Box)`
  display: flex;
  gap: 14px;
  justify-content: center;
  @media (max-width: 600px) {
    margin-top: 46px;
  }
`;
export const FindingButton = styled(PrimaryButton)`
  border-radius: 8px;
  & button {
    width: 120px;
    padding: 10px 18px;
    line-height: 24px;
  }
  @media (max-width: 600px) {
    flex: 1;
    & > button {
      width: 100%;
    }
  }
`;
export const FindingSubmitButton = styled(FindingButton)`
  background-color: ${(props) =>
    props?.disabled
      ? selectedTheme.colors.disabledButtonBackground
      : selectedTheme.colors.orange};
  color: white;
  box-shadow: none;
  & button {
    background-color: ${(props) =>
      props?.disabled
        ? selectedTheme.colors.disabledButtonBackground
        : selectedTheme.colors.orange} !important;
    color: white !important;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;
export const FindingCancelButton = styled(FindingButton)`
  background-color: white !important;
  color: ${selectedTheme.colors.deviceColor};
  & button {
    border: 1px solid ${selectedTheme.colors.inputBorderColor};
    background-color: white !important;
    color: ${selectedTheme.colors.deviceColor};
    box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  }
`;
