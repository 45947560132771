import LastChangesRenderer from "../../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import UserRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  name: {
    id: 0,
    i18key: "dashboard.table.name.columnTitle",
    renderer: UserRenderer,
    backendProperty: "name",
    disableSort: true,
    style: {
      //   flex: 1,
      maxWidth: "calc(100% / 2",
    },
  },
  lastChanges: {
    id: 1,
    backendProperty: "updatedAtUtc",
    i18key: "scanningObjects.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    disableSort: true,
    style: {
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "calc(100% / 2)",
    },
  },
};
