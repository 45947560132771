import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  AddFile,
  FilePickerContainer,
  FilePickerContentContainer,
  FilePickerDescription,
  FilePickerDescriptionText,
  FilePickerLabel,
  GreenTickIcon,
  UploadIcon,
} from "./FilePicker.styled";
import { useDropzone } from "react-dropzone";

const FilePicker = (props) => {
  const fileInputRef = useRef(null);

  // Drag and drop File
  const { getRootProps } = useDropzone({
    accept: {
      "application/*": [".json"],
    },
    noClick: true,
    onDrop: (acceptedFiles) => {
      if (!props?.disabled) {
        handleFile(acceptedFiles[0]);
      }
    },
  });

  const handleChange = () => {
    if (!props?.disabled) {
      fileInputRef.current.value = "";
      fileInputRef.current?.click?.();
    }
  };

  const handleFile = (file) => {
    if (file?.type !== "application/json") return;
    let reader = new FileReader();
    reader?.readAsText(file);
    reader.onload = () => {
      props?.dispatchFunction(file);
    };
    reader.onerror = (error) => {
      console.dir(error);
    };
  };

  return (
    <FilePickerContainer onClick={handleChange}>
      {props?.label && (
        <FilePickerLabel $required={props?.required}>
          {props?.label}
        </FilePickerLabel>
      )}
      <FilePickerContentContainer
        $hasFile={props?.fileUploaded}
        component="form"
        $disabled={props?.disabled}
        {...getRootProps()}
      >
        {!props?.fileUploaded ? (
          <FilePickerDescription>
            <UploadIcon />
            <FilePickerDescriptionText>
              {props?.descriptionText}
            </FilePickerDescriptionText>
          </FilePickerDescription>
        ) : (
          <FilePickerDescription>
            <GreenTickIcon />
            <FilePickerDescriptionText>
              {props?.addedFileText}
            </FilePickerDescriptionText>
          </FilePickerDescription>
        )}

        <AddFile
          type="file"
          ref={fileInputRef}
          onInput={(event) => handleFile(event.target.files?.[0])}
          accept=".json"
        />
        {props?.children}
      </FilePickerContentContainer>
    </FilePickerContainer>
  );
};
FilePicker.propTypes = {
  children: PropTypes.node,
  fileUploaded: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  smallerButtons: PropTypes.bool,
  required: PropTypes.bool,
  dispatchFunction: PropTypes.func,
  addedFileText: PropTypes.string,
};

export default FilePicker;
