import React from "react";
import PropTypes from "prop-types";
import {
  GeneralSettingsContainer,
  LeftContainer,
  LicenseImagePicker,
  LogoImagePicker,
  MiniRightContainer,
  RightContainer,
  SignatureImagePicker,
  StampImagePicker,
} from "./GeneralSettings.styled";
import CompanyNameField from "./CompanyNameField/CompanyNameField";
import CompanyTinNumberField from "./CompanyTinNumberField/CompanyTinNumberField";
import IdentificationNumberField from "./IdentificationNumberField/IdentificationNumberField";
import BankAccountNumberField from "./BankAccountNumberField/BankAccountNumberField";
import CompanyPhoneNumberField from "./CompanyPhoneNumberField/CompanyPhoneNumberField";
import CompanyAddressField from "./CompanyAddressField/CompanyAddressField";
import CompanyEmailField from "./CompanyEmailField/CompanyEmailField";
import DistributionEmails from "./DistributionEmails/DistributionEmails";
import { Trans, useTranslation } from "react-i18next";
import APRImagePickers from "./APRImagePickers/APRImagePickers";
import settingsValidations from "../../../validations/settingsValidations";
import CompanyDirectorField from "./CompanyDirector/CompanyDirector";

const GeneralSettings = (props) => {
  const { t } = useTranslation();

  const formik = props?.formik;
  return (
    <GeneralSettingsContainer>
      <LeftContainer>
        <CompanyNameField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.companyName?.exclusiveTests?.required
          }
        />
        <CompanyTinNumberField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.companyPIB?.exclusiveTests?.required
          }
        />
        <IdentificationNumberField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.identificationNumber?.exclusiveTests
              ?.required
          }
        />
        <BankAccountNumberField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.bankAccountNumber?.exclusiveTests?.required
          }
        />
        <CompanyPhoneNumberField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.phoneNumber?.exclusiveTests?.required
          }
        />
        <CompanyAddressField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.address?.exclusiveTests?.required
          }
        />
        <CompanyDirectorField
          formik={formik}
          isRequired={
            settingsValidations?.fields?.companyDirector?.exclusiveTests?.required
          }
        />
        <CompanyEmailField
          formik={formik}
          isRequired={settingsValidations?.fields?.email?.exclusiveTests?.required}
        />
        <DistributionEmails formik={formik} />
      </LeftContainer>

      <RightContainer>
        <LogoImagePicker
          smallerButtons
          width={183}
          height={120}
          maxWidth={50}
          maxHeight={50}
          minWidth={50}
          minHeight={50}
          label={t("companySettings.logo")}
          image={formik?.values?.logo !== "logo" ? formik?.values?.logo : ""}
          setImage={(newImage) => formik?.setFieldValue?.("logo", newImage)}
          descriptionText={<Trans i18nKey="companySettings.choseLogo" />}
        />
        <MiniRightContainer>
          <SignatureImagePicker
            smallerButtons
            width={183}
            height={120}
            maxWidth={180}
            maxHeight={120}
            minWidth={50}
            minHeight={50}
            label={t("companySettings.signature")}
            image={
              formik?.values?.signature !== "signature"
                ? formik?.values?.signature
                : ""
            }
            setImage={(newImage) =>
              formik?.setFieldValue?.("signature", newImage)
            }
            descriptionText={
              <Trans i18nKey="companySettings.chooseSignature" />
            }
          />
          <StampImagePicker
            smallerButtons
            width={183}
            height={120}
            maxWidth={180}
            maxHeight={120}
            minWidth={50}
            minHeight={50}
            label={t("companySettings.stamp")}
            image={
              formik?.values?.stamp !== "stamp" ? formik?.values?.stamp : ""
            }
            setImage={(newImage) => formik?.setFieldValue?.("stamp", newImage)}
            descriptionText={<Trans i18nKey="companySettings.chooseStamp" />}
          />
        </MiniRightContainer>
        <LicenseImagePicker
          name="companyLicense"
          label={t("companySettings.companyLicense")}
          image={formik?.values?.license}
          height={120}
          width={320}
          setImage={(newImage) => formik?.setFieldValue?.("license", newImage)}
          descriptionText={
            <Trans i18nKey="companySettings.choseCompanyLicense" />
          }
        />
        <APRImagePickers formik={formik} />
      </RightContainer>
    </GeneralSettingsContainer>
  );
};

GeneralSettings.propTypes = {
  formik: PropTypes.object,
};

export default GeneralSettings;
