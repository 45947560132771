import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererChangePasswordIconContainer,
  ActionRendererContainer,
  ActionRendererDeleteProfileIconContainer,
  ActionRendererEditProfileIconContainer,
} from "./ActionRenderer.styled";
import SetPasswordIcon from "../../../../../Icon/Icons/SetPasswordIcon";
import EditIcon from "../../../../../Icon/Icons/EditIcon";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
  toggleSetPasswordModal,
  toggleSetUserModal,
} from "../../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../../i18n";
import { EDIT_USER } from "../../../../../../constants/pages";
import { replaceInRoute } from "../../../../../../util/helpers/routeHelpers";
import {
  fetchChangeUserPassword,
  fetchBlockUser,
  fetchUnblockUser,
} from "../../../../../../store/actions/user/userActions";
import Tooltip from "../../../../../Tooltip/Tooltip";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../../constants/toastConstants";
import DeactivateIcon from "../../../../../Icon/Icons/DeactivateIcon";
import ActivateIcon from "../../../../../Icon/Icons/ActivateIcon";

const ActionRenderer = (props) => {
  const handleChangeUserPassword = (values) => {
    props?.dispatch(
      fetchChangeUserPassword({
        userId: props?.value?.userId,
        newPassword: values?.password,
        handleApiResponseSuccess: handleApiResponseSuccessChangePassword,
      })
    );
  };
  const handleApiResponseSuccessChangePassword = () => {
    makeToastMessage(
      {
        title: i18n.t("changePassword.changedPasswordSuccess"),
        description: i18n.t(
          "changePassword.changedPasswordSuccessDescription",
          {
            name: props?.value?.firstName + " " + props?.value?.lastName,
          }
        ),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };
  const handleChangePassword = () => {
    props?.dispatch(
      toggleSetPasswordModal({
        title: i18n.t("changePassword.changePasswordTitle", {
          name: props?.value?.firstName + " " + props?.value?.lastName,
        }),
        onSubmit: handleChangeUserPassword,
        rank: 1,
      })
    );
  };

  const handleApiResponseSuccess = () => {
    props?.dispatch(closeModal());
    makeToastMessage(
      {
        title: props?.value.t(
          props?.value?.isActiveTab
            ? "toast.success.deactivateTitle"
            : "toast.success.activateTitle",
          {
            typeOfData: props?.value.t("users.typeOfData"),
          }
        ),
        description: props?.value.t(
          props?.value?.isActiveTab
            ? "toast.success.deactivateDescription"
            : "toast.success.activateDescription",
          {
            name: props?.value.firstName + " " + props?.value.lastName,
            typeOfData: props?.value.t("users.typeOfDataPlural").toLowerCase(),
          }
        ),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    if (props?.value?.totalData === 1) props?.value?.tableRef?.current?.resetPage?.();
    else props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleApiResponseError = (errorMessage) => {
    props?.dispatch(closeLoadingModal());
    makeErrorToastMessage(
      {
        title: i18n.t("apiErrors.error"),
        description: errorMessage,
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleDeactivateUser = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchBlockUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleDeactivate = () => {
    props?.dispatch(
      toggleSetUserModal({
        title: i18n.t("users.modal.deactivate.title"),
        description: i18n.t("users.modal.deactivate.description"),
        buttonTitle: i18n.t("common.deactivate"),
        onSubmit: handleDeactivateUser,
        rank: 1,
      })
    );
  };

  const handleActivateUser = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchUnblockUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleActivate = () => {
    props?.dispatch(
      toggleSetUserModal({
        title: i18n.t("users.modal.activate.title"),
        description: i18n.t("users.modal.activate.description"),
        buttonTitle: i18n.t("common.activate"),
        onSubmit: handleActivateUser,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer $mobile={props?.isMobile}>
      {props?.value?.isActiveTab && (
        <>
          {!props?.rendererProps?.hideChangePassword && (
            <Tooltip title={i18n.t("users.tooltip.changePassword")}>
              <ActionRendererChangePasswordIconContainer
                onClick={handleChangePassword}
              >
                <SetPasswordIcon />
              </ActionRendererChangePasswordIconContainer>
            </Tooltip>
          )}
        </>
      )}
      <Tooltip title={i18n.t("users.tooltip.changeUser")}>
        <ActionRendererEditProfileIconContainer
          to={{
            pathname: replaceInRoute(EDIT_USER, {
              userId: props?.value?.userId,
            }),
            state: {
              ...props?.value?.routeState,
              isDeactive: !props?.value?.isActiveTab,
            },
          }}
        >
          <EditIcon />
        </ActionRendererEditProfileIconContainer>
      </Tooltip>
      <Tooltip
        title={
          props?.value?.isActiveTab
            ? i18n.t("users.deactivateUser")
            : i18n.t("users.activateUser")
        }
      >
        <ActionRendererDeleteProfileIconContainer
          onClick={
            props?.value?.isActiveTab ? handleDeactivate : handleActivate
          }
        >
          {props?.value?.isActiveTab ? <DeactivateIcon /> : <ActivateIcon />}
        </ActionRendererDeleteProfileIconContainer>
      </Tooltip>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  isMobile: PropTypes.bool,
};

export default ActionRenderer;
