import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { closeUserModal } from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import {
  UserModalDescription,
  SetUserModalContainer,
  CancelButton,
  SubmitButton,
} from "./SetUserModal.styled";
import BackdropComponent from "../../MUI/BackdropComponent";

const SetUserModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeUserModal());
  };

  const handleSubmit = () => {
    props?.onSubmit();
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SetUserModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <UserModalDescription>{props?.description}</UserModalDescription>
        <ButtonsContainer>
          <SubmitButton onClick={handleSubmit}>
            {props?.buttonTitle?.toUpperCase()}
          </SubmitButton>
          <CancelButton onClick={handleCloseModal}>
            {t("common.cancel")}
          </CancelButton>
        </ButtonsContainer>
      </SetUserModalContainer>
    </>
  );
};

SetUserModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
  buttonTitle: PropTypes.string,
};

export default SetUserModal;
