import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SetDeleteModalContainer = styled(Box)`
  padding: 32px;
  position: absolute;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: ${(props) => "15" + props.rank || "151"};
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const DeleteModalDescription = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */
  letter-spacing: -0.02em;
  text-align: left;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.titleColor};
`;

export const CancelButton = styled(Box)`
  padding: 10px 24px;
  cursor: pointer;
  flex: 1;
  height: 48px;
  border-radius: 6px;
  background: ${selectedTheme.colors.saveButtonBackground};
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: white;
`;

export const SubmitButton = styled(Box)`
  padding: 10px 24px;
  cursor: pointer;
  flex: 1;
  height: 48px;
  background: white;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  border-radius: 6px;
  color: ${selectedTheme.colors.deleteButtonText};
`;
