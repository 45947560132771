import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_CLIENT = createSetType("SET_CLIENT");
export const SET_CLIENT_ERROR = "SET_CLIENT_ERROR";

export const GET_CLIENTS_SCOPE = "GET_CLIENTS_SCOPE";
export const GET_CLIENTS_FETCH = createFetchType(GET_CLIENTS_SCOPE);
export const GET_CLIENTS_SUCCESS = createSuccessType(GET_CLIENTS_SCOPE);
export const GET_CLIENTS_ERROR = createErrorType(GET_CLIENTS_SCOPE);
export const GET_CLIENTS_CLEAR = createClearType(GET_CLIENTS_SCOPE);

export const SINGLE_CLIENT_SCOPE = "SINGLE_CLIENT_SCOPE";
export const SINGLE_CLIENT_FETCH = createFetchType(SINGLE_CLIENT_SCOPE);
export const SINGLE_CLIENT_SUCCESS = createSuccessType(SINGLE_CLIENT_SCOPE);
export const SINGLE_CLIENT_ERROR = createErrorType(SINGLE_CLIENT_SCOPE);
export const SINGLE_CLIENT_SET = createSetType(SINGLE_CLIENT_SCOPE);

const CREATE_CLIENT_SCOPE = "CREATE_CLIENT_SCOPE";
export const CREATE_CLIENT_FETCH = createFetchType(CREATE_CLIENT_SCOPE);
export const CREATE_CLIENT_SUCCESS = createSuccessType(CREATE_CLIENT_SCOPE);
export const CREATE_CLIENT_ERROR = createErrorType(CREATE_CLIENT_SCOPE);

const EDIT_CLIENT_SCOPE = "EDIT_CLIENT_SCOPE";
export const EDIT_CLIENT_FETCH = createFetchType(EDIT_CLIENT_SCOPE);
export const EDIT_CLIENT_SUCCESS = createSuccessType(EDIT_CLIENT_SCOPE);
export const EDIT_CLIENT_ERROR = createErrorType(EDIT_CLIENT_SCOPE);

const DELETE_CLIENT_SCOPE = "DELETE_CLIENT_SCOPE";
export const DELETE_CLIENT_FETCH = createFetchType(DELETE_CLIENT_SCOPE);
export const DELETE_CLIENT_SUCCESS = createSuccessType(DELETE_CLIENT_SCOPE);
export const DELETE_CLIENT_ERROR = createErrorType(DELETE_CLIENT_SCOPE);

export const SET_CLIENTS = createSetType("SET_CLIENTS");
export const SET_TOTAL_CLIENTS = createSetType("SET_TOTAL_CLIENTS");
export const CLEAR_CLIENTS = createClearType("CLEAR_CLIENTS");
