import {
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
  patchRequest,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchUsers = (payload) =>
  getRequest(apiEndpoints.users.getUsers, payload);

export const attemptCreateUser = (payload) =>
  postRequest(apiEndpoints.users.createUser, payload);

export const attemptEditUser = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.users.editUser, { userId: payload.userId }),
    payload.data
  );

export const attemptChangeUserPassword = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.users.changeUserPassword, {
      userId: payload.userId,
    }),
    payload.data
  );

export const attemptFetchSingleUser = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.users.getSingleUser, { userId: payload })
  );

export const attemptBlockUser = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.blockUser, { userId: payload.userId })
  );

export const attemptUnblockUser = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.unblockUser, { userId: payload.userId })
  );
