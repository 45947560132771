import React from "react";
import PropTypes from "prop-types";
import { FindingScanningSectionContentContainer } from "./FindingScanningSectionContent.styled";
import { useState } from "react";
import deviceConstants from "../../../../../constants/deviceConstants";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { useEffect } from "react";
import FindingTabChooser from "../../FindingTabChooser/FindingTabChooser";
import FindingScanningQuestions from "./FindingScanningQuestions/FindingScanningQuestions";
import { useSelector } from "react-redux";
import {
  selectFindingSubject,
  selectFindingSubjectOld,
  selectFindingSubjectSection,
  selectFindingSubjectSectionOld,
} from "../../../../../store/selectors/findingsSelectors";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { FindingScanningLeftContainer } from "../FindingScanning.styled";
import FindingScanningSectionChooser from "../FindingScanningSectionChooser/FindingScanningSectionChooser";
import { useMemo } from "react";
import FindingBreadCrumbs from "./FindingBreadCrumbs/FindingBreadCrumbs";

const FindingScanningSectionContent = forwardRef((props, ref) => {
  const [selectedSectionId, setSelectedSectionId] = useState();
  const { isMobile } = useIsMobile();
  const chosenSection = useSelector(
    selectFindingSubjectSection(selectedSectionId)
  );
  const chosenSectionOld = useSelector(
    selectFindingSubjectSectionOld(selectedSectionId)
  );
  const [selectedDevice, setSelectedDevice] = useState(deviceConstants.desktop);
  const chosenScanningSubject = useSelector(
    selectFindingSubject(props?.selectedScanningSubjectId || 0)
  );
  const chosenScanningSubjectOld = useSelector(
    selectFindingSubjectOld(props?.selectedScanningSubjectId || 0)
  );

  useImperativeHandle(ref, () => ({
    setSelectedSectionId,
  }));

  useEffect(() => {
    if (isMobile != null) {
      if (isMobile) {
        if (selectedDevice !== deviceConstants.mobile)
          setSelectedDevice(deviceConstants.mobile);
      } else {
        if (selectedDevice !== deviceConstants.desktop)
          setSelectedDevice(deviceConstants.desktop);
      }
    }
  }, [isMobile]);

  const breadcrumbs = useMemo(() => {
    let breadcrumbsToReturn = [
      {
        name:
          chosenScanningSubject?.scanningSubject?.name ||
          chosenScanningSubject?.name,
        clickFunction: () => props?.setSelectedScanningSubjectId(),
      },
    ];
    if (selectedSectionId != null) {
      breadcrumbsToReturn.push({
        name: chosenSection?.title,
        clickFunction: () => setSelectedSectionId(),
      });
    }
    return breadcrumbsToReturn;
  }, [chosenScanningSubject, chosenSection, selectedSectionId]);

  useEffect(() => {
    if (isMobile) {
      setSelectedSectionId();
    } else {
      setSelectedSectionId(chosenScanningSubject?.sections?.[0]?.id);
    }
  }, [props?.selectedScanningSubjectId, isMobile]);

  return (
    <FindingScanningSectionContentContainer $selectedDevice={selectedDevice}>
      {isMobile && (
        <FindingBreadCrumbs
          breadcrumbs={breadcrumbs}
          subjectId={chosenScanningSubject?.id}
        />
      )}
      {isMobile ? (
        selectedSectionId == null && (
          <FindingScanningLeftContainer
            $chosenScanningSubject={chosenScanningSubject}
          >
            <FindingScanningSectionChooser
              style={{ marginTop: "18px" }}
              // sections={props?.item?.data?.sections}
              stateId={props?.stateId}
              sections={chosenScanningSubject?.sections}
              notEditable
              scanningSection
              chosenScanningSubjectId={
                selectedSectionId != null && chosenSection?.id
              }
              handleChangeSection={setSelectedSectionId}
            />
          </FindingScanningLeftContainer>
        )
      ) : (
        <FindingTabChooser
          tabs={chosenScanningSubject?.sections}
          selectedTab={chosenSection}
          handleChangeTab={setSelectedSectionId}
          isEditing
          insideScanningSubjects
          subjectId={props?.selectedScanningSubjectId}
        />
      )}
      {selectedSectionId != null &&
        chosenScanningSubject?.sections?.length !== 0 &&
        props?.selectedScanningSubjectId != null && (
          <FindingScanningQuestions
            {...props}
            questions={chosenSection?.items}
            section={chosenSection}
            sectionOld={chosenSectionOld}
            chosenScanningSubject={chosenScanningSubject}
            chosenScanningSubjectOld={chosenScanningSubjectOld}
            chosenScanningSubjectId={props?.selectedScanningSubjectId}
            subjectNote={chosenScanningSubject?.conclusion}
          />
        )}
    </FindingScanningSectionContentContainer>
  );
});

FindingScanningSectionContent.displayName = "FindingScanningSectionContent";

FindingScanningSectionContent.propTypes = {
  questions: PropTypes.array,
  answerQuestion: PropTypes.func,
  handleChangeTab: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedTab: PropTypes.object,
  goBackToSections: PropTypes.func,
  setSelectedScanningSubjectId: PropTypes.func,
  disabled: PropTypes.bool,
  selectedScanningSubjectId: PropTypes.number,
  stateId: PropTypes.number,
};

export default FindingScanningSectionContent;
