import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererEditProfileIconContainer,
  ActionRendererDeleteProfileIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import { EDIT_CLIENT_PAGE } from "../../../../../constants/pages";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import { fetchDeleteClient } from "../../../../../store/actions/clients/clientsActions";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../constants/toastConstants";
import { makeToastMessage } from "../../../../../store/utils/makeToastMessage";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccessDelete = () => {
    props?.dispatch(closeModal());
    makeToastMessage(
      {
        title: props?.value?.t?.("toast.success.deletedTitle", {
          typeOfData: props?.value?.t?.("clients.typeOfData"),
        }),
        description: props?.value?.t?.("toast.success.deletedDescription", {
          name: props?.value?.clients,
          typeOfData: props?.value?.t?.("clients.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    if (props?.value?.totalData === 1) props?.value?.tableRef?.current?.resetPage?.();
    else props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleDeleteClient = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteClient({
        clientId: props?.value?.clientId,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("clients.modal.delete.title"),
        description: {
          first: i18n.t("clients.modal.delete.description.1"),
          second: i18n.t("clients.modal.delete.description.2"),
        },
        onSubmit: handleDeleteClient,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("clients.editClient")}>
        <ActionRendererEditProfileIconContainer
          to={replaceInRoute(EDIT_CLIENT_PAGE, {
            clientId: props?.value?.clientId,
          })}
        >
          <EditIcon />
        </ActionRendererEditProfileIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("clients.deleteClient")}>
          <ActionRendererDeleteProfileIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteProfileIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
};

export default ActionRenderer;
