import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";
import XIcon from "../Icon/Icons/XIcon";
import Title from "../Typography/Title/Title";
import { TOAST_SUCCESS } from "../../constants/toastConstants";

export const ToastComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 18px 48px;
  width: 100%;
  height: fit-content;
  min-height: 93px;
  border-radius: 12px;
  box-shadow: 0 4px 12px ${hexToRGB(selectedTheme.colors.white, 0.18)};
`;
export const ToastComponentTitle = styled(Title)`
  color: ${(props) =>
    props.type === TOAST_SUCCESS
      ? selectedTheme.colors.toastSuccess
      : selectedTheme.colors.toastError};
  font-size: 14px;
  line-height: 20px;
`;
export const ToastComponentDescription = styled(Box)`
  display: flex;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${selectedTheme.colors.borderColor};
  word-break: break-all;
  white-space: ${props => props?.pre && "pre"};
`;
export const CloseButton = styled(XIcon)`
  position: absolute;
  top: 24px;
  right: 24px;
`;
export const IconContainer = styled(Box)`
  position: absolute;
  top: 16px;
  left: 16px;
  & svg {
    width: 24px;
    height: 24px;
  }
  & path {
    stroke: ${(props) =>
      props.type === TOAST_SUCCESS
        ? selectedTheme.colors.toastSuccess
        : selectedTheme.colors.toastError};
  }
`;
