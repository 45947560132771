import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../TextInputField/TextInputField";
import EyeStrikedIcon from "../../Icon/Icons/EyeStriked";
import EyeIcon from "../../Icon/Icons/Eye";
import { InputContainer, InputRightElement } from "./PasswordField.styled";

const PasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <InputContainer
        $isError={formik?.touched?.password && formik?.errors?.password}
      >
        <TextInputField
          name="password"
          label={t("users.password")}
          placeholder={t("users.newUserPlaceholders.password")}
          type={!showPassword && "password"}
          value={formik?.values?.password}
          onChange={formik?.handleChange}
          inputProps={{
            sx: {
              input: {
                paddingRight: "44px !important",
                width: "310px !important",
              },
            },
          }}
          error={formik?.touched?.password && formik?.errors?.password}
          helperText={formik?.touched?.password && formik?.errors?.password}
          requiredField={props?.isRequired}
          icon={
            <InputRightElement
              onClick={() => setShowPassword((prevValue) => !prevValue)}
            >
              {showPassword ? <EyeStrikedIcon /> : <EyeIcon />}
            </InputRightElement>
          }
        />
      </InputContainer>
    </>
  );
};

PasswordField.propTypes = {
  formik: PropTypes.node,
  isRequired: PropTypes.bool,
};

export default PasswordField;
