import {
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
  deleteRequest,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchMeasuringInstruments = (payload) =>
  getRequest(
    apiEndpoints.measuringInstruments.getMeasuringInstruments,
    payload
  );

export const attemptFetchSingleMeasuringInstrument = (payload) =>
  getRequest(
    replaceInUrl(
      apiEndpoints.measuringInstruments.getSingleMeasuringInstrument,
      {
        measuringInstrumentId: payload,
      }
    )
  );
export const attemptFetchCreateMeasuringInstrument = (payload) =>
  postRequest(
    apiEndpoints.measuringInstruments.createMeasuringInstrument,
    payload
  );

export const attemptFetchEditMeasuringInstrument = (payload) =>
  putRequest(
    replaceInUrl(
      apiEndpoints.measuringInstruments.getSingleMeasuringInstrument,
      {
        measuringInstrumentId: payload.measuringInstrumentId,
      }
    ),
    payload.data
  );

export const attemptFetchDeleteMeasuringInstrument = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.measuringInstruments.deleteMeasuringInstruments, {
      measuringInstrumentId: payload.measuringInstrumentId,
    })
  );
