import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectSingleClient } from "../../../store/selectors/clientsSelector";
import {
  fetchEditClient,
  fetchSingleClient,
} from "../../../store/actions/clients/clientsActions";
import { useRouteMatch } from "react-router";
import NewClientForm from "../../../components/Clients/NewClient/NewClientForm";
import { useTranslation } from "react-i18next";
import { EditClientPageContainer } from "./EditClientPage.styled";
import LastChanges from "../../../components/LastChanges/LastChanges";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { SINGLE_CLIENT_SCOPE } from "../../../store/actions/clients/clientsActionConstants";
import useAuth from "../../../hooks/useAuth";
import { useMemo } from "react";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";

const EditClientPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const prefilledData = useSelector(selectSingleClient);
  const isLoading = useSelector(
    selectIsLoadingByActionType(SINGLE_CLIENT_SCOPE)
  );

  const { hasRole } = useAuth();

  const readOnlyPage = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  useEffect(() => {
    dispatch(fetchSingleClient({ clientId: routeMatch.params?.clientId }));
  }, [routeMatch]);
  return (
    <EditClientPageContainer>
      <NewClientForm
        prefilledData={prefilledData}
        dispatchFunction={fetchEditClient}
        title={t("clients.editClientTitle")}
        submitText={t("common.save")}
        isEditing
        isLoading={isLoading ? true : false}
        readOnly={readOnlyPage}
      />
      {isLoading ? (
        <></>
      ) : (
        <LastChanges
          created={{
            date: formatDateTime(
              prefilledData?.createdAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.createdByUser?.firstName || ""} ${
              prefilledData?.createdByUser?.lastName || ""
            }`,
          }}
          lastChanges={{
            date: formatDateTime(
              prefilledData?.updatedAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.updatedByUser?.firstName || ""} ${
              prefilledData?.updatedByUser?.lastName || ""
            }`,
          }}
        />
      )}
    </EditClientPageContainer>
  );
};

EditClientPage.propTypes = {
  children: PropTypes.node,
};

export default EditClientPage;
