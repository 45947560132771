import Title from "../Typography/Title/Title";
import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../Icon/Icon";

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0px;
  /* margin-bottom: ${(props) => !props?.$noMargin && "62px"}; */
  width: 100%;
`;

export const PageeTitle = styled(Title)``;

export const DeleteIconContainer = styled(Icon)`
  cursor: pointer;
  margin-left: 16px;
`;
