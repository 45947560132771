import * as Yup from "yup";
import i18n from "../i18n";
import { newMeasuringInstrumentFormikConstants } from "../initialValues/newMeasuringInstrumentInitialValues";

export default Yup.object().shape({
  [newMeasuringInstrumentFormikConstants.findingTypes]: Yup.array(),
  [newMeasuringInstrumentFormikConstants.name]: Yup.string()
    .trim()
    .max(255, i18n.t("standards.modal.validationMessage"))
    .required(i18n.t("measuringInstruments.errors.nameRequired")),
  [newMeasuringInstrumentFormikConstants.factoryNumber]: Yup.string(),
  [newMeasuringInstrumentFormikConstants.manufacturer]: Yup.string(),
  [newMeasuringInstrumentFormikConstants.benchmarking]: Yup.string(),
  [newMeasuringInstrumentFormikConstants.date]: Yup.string(),
  [newMeasuringInstrumentFormikConstants.calibrationCertificateUrl]:
    Yup.string(),
});
