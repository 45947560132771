import { PROFILE_PAGE } from "./pages";

export default (gotToProfilePage, profileIcon, logoutFunction, logoutIcon) => [
  {
    id: 0,
    i18key: "userPopover.profile",
    onClick: gotToProfilePage,
    icon: profileIcon,
    link: PROFILE_PAGE,
  },
  {
    id: 1,
    i18key: "userPopover.logout",
    onClick: logoutFunction,
    icon: logoutIcon,
  },
];
