import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
  toggleSetPasswordModal,
  toggleSetUserModal,
} from "../../store/actions/modal/modalActions";
import {
  ButtonsContainer,
  DeactivateIconContainer,
  DeactivateIconCustom,
  ErrorMessage,
  Line,
  NewUserAcountInfo,
  NewUserBasicInfo,
  NewUserContainerForm,
  NewUserInfoHeader,
  NewUserInputsContainer,
  PageeTitle,
  PageTitleContainer,
  PasswordButton,
  PasswordButtonContainer,
  PasswordValidationIcon,
  SetPasswordIcon,
  TickIcon,
  TitleButtonsContainer,
} from "./NewUserForm.styled";
import { useFormik } from "formik";
import { newUserInitialValues } from "../../initialValues/newUserInitialValues";
import newUserValidationSchema from "../../validations/newUserValidationSchema";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import history from "../../store/utils/history";
import { useLocation, useRouteMatch } from "react-router-dom";
import { EDIT_USER, USERS_PAGE } from "../../constants/pages";
import FirstNameField from "./FirstNameField/FirstNameField";
import LastNameField from "./LastNameField/LastNameField";
import RolePicker from "./RolePicker/RolePicker";
import EmailField from "./EmailField/EmailField";
import {
  fetchChangeUserPassword,
  fetchBlockUser,
} from "../../store/actions/user/userActions";
import CircularLoader from "../Loader/CircularLoader/CircularLoader";
import ErrorIcon from "../Icon/Icons/ErrorIcon";
import {
  ButtonsAreaContainer,
  RequiredFieldsText,
} from "../Clients/NewClient/NewClientForm.styled";
import { PasswordValidationText } from "../Modals/SetPasswordModal/SetPasswordModal.styled";
import PasswordField from "./PasswordField/PasswordField";
import ConfirmPasswordField from "./ConfirmPasswordField/ConfirmPasswordField";
import Tooltip from "../Tooltip/Tooltip";
import { makeToastMessage } from "../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../constants/toastConstants";
import useIsMobile from "../../hooks/useIsMobile";
import { replaceInRoute } from "../../util/helpers/routeHelpers";
import { useMemo } from "react";

const NewUserForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const userId = routeMatch.params?.userId;
  const location = useLocation();
  const { isMobile } = useIsMobile();

  const handleChangePassword = () => {
    dispatch(
      toggleSetPasswordModal({
        title: t("changePassword.changePasswordTitle", {
          name: formik?.values?.firstName + " " + formik?.values?.lastName,
        }),
        onSubmit: (values) => {
          formik?.setFieldValue?.("password", values?.password);
          formik?.setFieldValue?.("confirmPassword", values?.password);
        },
        rank: 1,
      })
    );
  };

  const handleApiResponseSuccess = (payload) => {
    dispatch(closeModal());
    if (payload?.isBlocked) {
      history.goBack();
      history.replace(USERS_PAGE, {
        forceRefetch: true,
        name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
        isBlocked: payload?.isBlocked || false,
        ...location?.state,
      });
    } else {
      const titleI18Key = props?.isEditing
        ? "toast.success.editedTitle"
        : "toast.success.createdTitle";
      const descriptionI18Key = props?.isEditing
        ? "toast.success.editedDescription"
        : "toast.success.createdDescription";
      makeToastMessage(
        {
          title: t(titleI18Key, {
            typeOfData: t("users.typeOfData"),
          }),
          description: t(descriptionI18Key, {
            name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
            typeOfData: t("users.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      if (!props?.isEditing) {
        history.replace({
          pathname: replaceInRoute(EDIT_USER, { userId: payload }),
          state: {
            isDeactive: false,
          },
        });
      } else {
        history.replace({
          pathname: replaceInRoute(EDIT_USER, { userId: userId }),
          state: {
            isDeactive: false,
          },
        });
      }
    }
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const userData = {
      firstname: values?.firstName?.replace?.(/  +/g, " ")?.trim?.(),
      lastname: values?.lastName?.replace?.(/  +/g, " ")?.trim?.(),
      email: values?.email?.replace?.(/  +/g, " ")?.trim?.(),
      password: values?.password,
      createdDate: values?.createdDate,
      roles: values?.userRole,
    };
    const userId = routeMatch?.params?.userId;
    if (props?.isEditing && values?.password) {
      dispatch(
        fetchChangeUserPassword({
          userId: userId,
          newPassword: values?.password,
        })
      );
    }
    setTimeout(() => {
      dispatch(
        props?.dispatchFunction({
          userData,
          userId,
          handleApiResponseSuccess,
          handleApiResponseError,
        })
      );
    }, 500);
  };
  const formik = useFormik({
    initialValues: newUserInitialValues(props?.prefilledData),
    validationSchema: newUserValidationSchema(props?.isEditing),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleDeactivateUser = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchBlockUser({
        userId: routeMatch?.params?.userId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleDeactivate = () => {
    dispatch(
      toggleSetUserModal({
        title: t("users.modal.deactivate.title"),
        description: t("users.modal.deactivate.description"),
        buttonTitle: t("common.deactivate"),
        onSubmit: handleDeactivateUser,
        rank: 1,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <NewUserContainerForm onSubmit={formik?.handleSubmit}>
      <TitleButtonsContainer>
        <PageTitleContainer>
          <PageeTitle>{props?.title}</PageeTitle>
          {props?.isEditing && !props?.isLoading && !props?.isDeactive && (
            <Tooltip title={t("users.deactivateUser")}>
              <DeactivateIconContainer onClick={handleDeactivate}>
                <DeactivateIconCustom />
              </DeactivateIconContainer>
            </Tooltip>
          )}
        </PageTitleContainer>
        {!props?.isDeactive && !isMobile && (
          <ButtonsAreaContainer>
            <ButtonsContainer>
              <PrimaryButton onClick={() => history.goBack()}>
                {t("users.cancel")}
              </PrimaryButton>
              <PrimaryButton
                type="submit"
                variant="contained"
                disabled={isSavingEnabled}
              >
                {t("common.save")}
              </PrimaryButton>
            </ButtonsContainer>
            <RequiredFieldsText>
              {t("common.requiredFieldsText")}
            </RequiredFieldsText>
          </ButtonsAreaContainer>
        )}
      </TitleButtonsContainer>
      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <NewUserInputsContainer>
            <NewUserBasicInfo>
              <NewUserInfoHeader>{t("users.basicInfo")}</NewUserInfoHeader>
              <Line />
              <FirstNameField
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.firstName
                    ?.exclusiveTests?.required
                }
                isReadOnly={props?.isDeactive}
              />
              <LastNameField
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.lastName
                    ?.exclusiveTests?.required
                }
                isReadOnly={props?.isDeactive}
              />
              <RolePicker
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.userRole
                    ?.exclusiveTests?.min
                }
                isReadOnly={props?.isDeactive}
              />
            </NewUserBasicInfo>
            <NewUserAcountInfo>
              <NewUserInfoHeader>{t("users.accountInfo")}</NewUserInfoHeader>
              <Line />
              <EmailField
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.email
                    ?.exclusiveTests?.required
                }
                isReadOnly={props?.isDeactive}
              />
              {props?.isEditing ? (
                <>
                  {!props?.isDeactive && (
                    <PasswordButtonContainer>
                      <PasswordValidationIcon>
                        {formik?.errors?.password && formik?.touched?.password && (
                          <ErrorIcon />
                        )}
                        {formik?.values?.password && <TickIcon />}
                      </PasswordValidationIcon>
                      <PasswordButton
                        variant="contained"
                        onClick={handleChangePassword}
                        value={formik?.values?.password}
                      >
                        {t("users.setPassword")}
                        <SetPasswordIcon />
                      </PasswordButton>
                    </PasswordButtonContainer>
                  )}
                  {formik?.errors?.password && formik?.touched?.password && (
                    <ErrorMessage $rightAlign>
                      {formik?.errors?.password}
                    </ErrorMessage>
                  )}
                </>
              ) : (
                <>
                  <PasswordField formik={formik} isRequired={true} />
                  <ConfirmPasswordField formik={formik} />
                  <PasswordValidationText style={{ marginTop: "34px" }}>
                    <Trans
                      i18nKey={"changePassword.validationPasswordDescription"}
                    />
                  </PasswordValidationText>
                </>
              )}
            </NewUserAcountInfo>
          </NewUserInputsContainer>
          {!props?.isDeactive && isMobile && (
            <ButtonsAreaContainer>
              <ButtonsContainer>
                <PrimaryButton onClick={() => history.goBack()}>
                  {t("users.cancel")}
                </PrimaryButton>
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  disabled={isSavingEnabled}
                >
                  {t("common.save")}
                </PrimaryButton>
              </ButtonsContainer>
              <RequiredFieldsText>
                {t("common.requiredFieldsText")}
              </RequiredFieldsText>
            </ButtonsAreaContainer>
          )}
        </>
      )}
    </NewUserContainerForm>
  );
};

NewUserForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  dispatchFunction: PropTypes.func,
  prefilledData: PropTypes.any,
  userId: PropTypes.any,
  submitText: PropTypes.string,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDeactive: PropTypes.bool,
};

export default NewUserForm;
