import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import Select from "../../Select/Select";
import { SelectLabel } from "../../Select/Select.styled";
import TextInputField from "../../TextInputField/TextInputField";
import Title from "../../Typography/Title/Title";

export const FindingQuestionCardContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${(props) => props?.$mobile && "70%"};
  max-width: calc(100vw - 700px);
  gap: ${(props) => (props?.$mobile ? "32px" : "26px")};
  @media (max-width: 600px) {
    max-width: none;
    width: 100%;
    gap: 18px;
  }
`;
export const FindingQuestionCardRadioContainer = styled(Box)`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-self: ${(props) => props?.$mobile && "flex-start"};
  @media (max-width: 600px) {
    align-self: center;
  }
`;
export const FindingQuestionInputField = styled(TextInputField)`
  margin-top: 6px;
  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & input {
    width: 100%;
  }
`;

export const FindingQuestionLabel = styled(Title)`
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
  ${(props) =>
    props?.selectType &&
    css`
      margin-bottom: 8px;
    `}
`;

export const FindingQuestionDescriptionContainer = styled(Box)`
  display: flex;
  height: fit-content;
  gap: 6px;
  flex-direction: ${(props) => (props?.$mobile ? "column" : "row")};
`;
export const FindingQuestionCardRadioTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.profilePictureText};
`;
export const FindingQuestionCardRadioButtonsContainer = styled(Box)`
  display: flex;
  gap: 8px;
`;
export const FindingQuestionCardRadioButton = styled(Box)`
  width: 88px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${selectedTheme.colors.white};
  color: ${selectedTheme.colors.deviceColor};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  line-height: 20px;
  ${(props) =>
    props?.$disabled &&
    css`
      cursor: initial;
    `}
  ${(props) =>
    props?.$selected &&
    css`
      background-color: ${selectedTheme.colors.orange};
      color: ${selectedTheme.colors.white};
      border-color: ${selectedTheme.colors.orangeBorder};
    `}
`;
export const FindingSelect = styled(Select)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & * {
    max-height: 48px;
  }
  & ${SelectLabel} {
    max-height: none;
  }
`;
export const FindingInputContainer = styled(Box)`
  flex: 1;
  width: 100%;
`;
