import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { DistributionEmailsModalContainer } from "./DistributionEmailsModal.styled";
import {
  ButtonsContainer,
  SubmitButton as CancelButton,
  CloseButton,
  ModalTitle,
} from "../Modal.styled";
import EmailsList from "./EmailsList/EmailsList";
import BackdropComponent from "../../MUI/BackdropComponent";

const DistributionEmailsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <DistributionEmailsModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <EmailsList emails={props?.emails} />
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.close")}
          </CancelButton>
        </ButtonsContainer>
      </DistributionEmailsModalContainer>
    </>
  );
};

DistributionEmailsModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  emails: PropTypes.array,
};
DistributionEmailsModal.defaultProps = {
  onSubmit: () => {},
};

export default DistributionEmailsModal;
