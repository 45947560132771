import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import Link from "../../../../Link/Link";

export const SidebarNavigationItemsTooltipTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: ${selectedTheme.colors.sidebar.titleColor};
  text-transform: uppercase;
  z-index: 100;
  white-space: ${(props) => !props?.$textWrap && "nowrap"};
`;

export const Line = styled(Box)`
  width: 88px;
  height: 1px;
  background-color: ${selectedTheme.colors.sidebar.titleColor};
  margin-bottom: 8px;
`;

export const SidebarNavigationItemsTooltipItem = styled(Link)`
  padding: 8px 16px;
  border-radius: 12px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  font-weight: 600;
  color: ${selectedTheme.colors.sidebar.titleColor};
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: ${selectedTheme.colors.sidebar.hoveredItem};
  }
`;

export const SidebarNavigationItemsTooltipContainer = styled(Link)`
  width: ${(props) => (props?.$fixedWidth ? "251px" : "fit-content")};
  background-color: ${selectedTheme.colors.sidebar.background};
  z-index: 1500;
  padding: 24px;
  border-radius: 0 12px 12px 0;
  position: absolute;
  left: 100%;
  top: 0;
  ${(props) => !props.$hasItems && `display: none;`}
`;
