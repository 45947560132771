import { Box, Button, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import Switch from "../../Switch/Switch";
import Title from "../../Typography/Title/Title";
import { CheckboxLabel } from "../../NewUser/Checkbox/Checkbox.styled";

export const EmailInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-top: 24px;
`;
export const FormContainer = styled.form`
  display: flex;
  gap: 80px;
  width: fit-content;
  padding: 0px 24px 24px 24px;
  border: 1px solid ${selectedTheme.colors.tableBorder};
  border-radius: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 32px;
  }
`;

export const ContentLeft = styled(Box)`
  margin-top: -8px;
`;

export const ContentRight = styled(Box)`
  margin-top: -10px;
`;

export const EmailContainer = styled(Box)`
  padding-bottom: 5px;
`;

export const SSLSwitch = styled(Switch)`
  margin-top: 40px;
  margin-left: 0px;
`;

export const SwitchLabel = styled(Title)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.requiredField &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `};
`;

export const VerificationContent = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px;
  gap: 32px;
  margin-top: 35px;
`;

export const CheckboxMainContainer = styled(Box)`
  & ${CheckboxLabel} {
    font-weight: 600;
    color: ${selectedTheme.colors.labelTextColor};
  }
`;

export const VerificationTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 11px;
  & svg {
    width: 17px;
    height: 17px;
    margin-top: 2px;
  }
`;
export const VerificationText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 200px;
  color: ${selectedTheme.colors.labelTextColor};
`;
export const VerificationButton = styled(Button)`
  background-color: ${(props) =>
    props.disabled
      ? selectedTheme.colors.disabledButtonBackground
      : selectedTheme.colors.primaryButtonDarkColor};
  color: ${selectedTheme.colors.white};
  text-transform: none;
  padding: 8px 14px;
  gap: 8px;
  width: 85px;
  height: 36px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  &:hover {
    background-color: ${selectedTheme.colors.orangeLightHover};
  }
`;
export const PasswordButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 42px;
`;
export const PasswordButtonLabel = styled(Box)`
  margin-right: 10px;
  font-family: ${selectedTheme.fonts.mainText};
  display: flex;
  align-items: center;
`;
export const PasswordButton = styled(Button)`
  color: black;
  text-transform: none;
  padding: 8px 14px;
  gap: 8px;
  height: 36px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  border: 1px solid black;
`;

export const ErrorMessageContainer = styled(Box)`
  display: flex;
  justify-content: end;
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  text-align: ${(props) => props?.$rightAlign && "right"};
  margin-top: 5px;
`;
