import React from "react";
import PropTypes from "prop-types";
import {
  FormContainer,
  SearchEndIcon,
  SearchInputContainer,
  SubmitButton,
} from "./SearchInput.styled";
import { useRef } from "react";

const SearchInput = (props) => {
  const searchRef = useRef(null);
  const handleSearch = (event) => {
    event.preventDefault();
    props?.onChange(searchRef?.current?.value);
  };
  return (
    <FormContainer component="form" onSubmit={handleSearch} flex={1}>
      <SearchInputContainer
        placeholder={props?.placeholder}
        type={props?.type}
        InputProps={{
          endAdornment: (
            <SubmitButton as="button" type="submit">
              <SearchEndIcon onClick={handleSearch} />
            </SubmitButton>
          ),
        }}
        inputRef={searchRef}
      />
    </FormContainer>
  );
};

SearchInput.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};
SearchInput.defaultProps = {
  placeholder: "",
};

export default SearchInput;
