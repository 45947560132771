import * as Yup from "yup";
import i18n from "../i18n";
import { setPasswordFormikConstants } from "../initialValues/setPasswordInitialValues";

export default Yup.object().shape({
  [setPasswordFormikConstants.password]: Yup.string().required(
    i18n.t("changePassword.blankPasswordError")
  ),
  [setPasswordFormikConstants.confirmPassword]: Yup.string()
    .oneOf(
      [Yup.ref(setPasswordFormikConstants.password), null],
      i18n.t("changePassword.differentPasswordsError")
    )
    .required(i18n.t("changePassword.blankPasswordError"))
    .matches(
      /^(?=.{6,})/, //eslint-disable-line
      i18n.t(i18n.t("changePassword.passwordLength"))
    ),
});
