import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import TextInputField from "../../../../TextInputField/TextInputField";

export const LabelContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
`;
export const FieldLabel = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.labelTextColor};
  font-family: ${selectedTheme.fonts.mainText};
`;

export const SearchFilterComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  width: 100%;
`;

export const NumberSearchField = styled(TextInputField)`
  margin: 0;
  width: 100%;
  text-align: left;

  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & input {
    width: 100%;
    height: 26px;
  }
`;
