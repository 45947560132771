import React from "react";
import PropTypes from "prop-types";
import {
  LoginContainer,
} from "./LoginContent.styled";
import LoginHeader from "../LoginHeader/LoginHeader";
import LoginForm from "../LoginForm/LoginForm";

const LoginContent = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const handleApiResponseSuccess = () => {
//     history.push({
//       pathname: HOME_PAGE,
//       state: {
//         from: history.location.pathname,
//       },
//     });
//   };

//   const handleSubmit = (values) => {
//     const { username: Username, password: Password } = values;
//     makeToastMessage("Poruka");
//     dispatch(clearLoginErrors());
//     dispatch(
//       fetchUser({
//         Username,
//         Password,
//         handleApiResponseSuccess,
//       })
//     );
//   };

//   const formik = useFormik({
//     initialValues: {
//       username: "",
//       password: "",
//     },
//     validationSchema: Yup.object().shape({
//       username: Yup.string().required(t("login.usernameRequired")),
//       password: Yup.string().required(t("login.passwordRequired")),
//     }),
//     onSubmit: handleSubmit,
//     validateOnBlur: true,
//     enableReinitialize: true,
//   });
  return (
    <LoginContainer>
      <LoginHeader />
      <LoginForm />
    </LoginContainer>
  );
};

LoginContent.propTypes = {
  children: PropTypes.node,
};

export default LoginContent;
