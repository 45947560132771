import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import { scanningSubjectFormikConstants } from "../../../../initialValues/scanningSubjectInitialValues";
import { NameInputContainer } from "../NewScanningObjectContent.styled";

const ScanningObjectNameInput = (props) => {
  const { t } = useTranslation();
  // const handleChangeName = (event) => {
  //   props?.onChange?.(event);
  // };

  return (
    <NameInputContainer>
      <TextInputField
        label={t("common.name")}
        value={props?.formik?.values?.[scanningSubjectFormikConstants.name]}
        error={props?.formik?.errors?.[scanningSubjectFormikConstants.name]}
        name={scanningSubjectFormikConstants.name}
        onChange={props?.formik?.handleChange}
        placeholder={t("scanningObjects.newScanningObject.namePlaceholder")}
        requiredField={true}
        disabledField={props?.isReadOnly}
      />
    </NameInputContainer>
  );
};

ScanningObjectNameInput.propTypes = {
  isReadOnly: PropTypes.bool,
  formik: PropTypes.object,
};
ScanningObjectNameInput.defaultProps = {
  value: "",
};

export default ScanningObjectNameInput;
