import { Box, Table } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import selectedTheme from "../../../themes";

export const TablePreviewModalContainer = styled(Box)`
  padding: 32px;
  position: absolute;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: ${(props) => "15" + props.rank || "151"};
  @media (max-width: 600px) {
    width: 90vw;
    max-height: 100vh;
  }
`;
export const TablePreviewContainer = styled(Table)`
  max-width: 700px;
  min-width: 700px;
  width: 700px;
  position: relative;
  border-collapse: separate;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px
      ${hexToRGB(selectedTheme.colors.shadowColor, 0.06)},
    0px 1px 3px 0px ${hexToRGB(selectedTheme.colors.shadowColor, 0.1)};
  @media (max-width: 600px) {
    width: 100%;
    overflow: auto;
  }
`;
export const ButtonsContainer = styled(Box)``;
