import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import LeftArrowIcon from "../Icon/Icons/LeftArrowIcon";
import RightArrowIcon from "../Icon/Icons/RightArrowIcon";

export const PagingContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;
export const LeftArrow = styled(LeftArrowIcon)``;
export const RightArrow = styled(RightArrowIcon)``;
export const ArrowText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  @media (max-width: 600px) {
    display: ${(props) => !props?.$showNameOnMobile && "none"};
  }
`;
export const Arrow = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
export const PageNumber = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background: ${(props) =>
    props.$current ? selectedTheme.colors.pagingSelected : "white"};
  &:hover {
    color: ${selectedTheme.colors.tableBlueColor};
    background: ${(props) =>
      !props.$current && selectedTheme.colors.tableRowSelectedBackgroundColor};
  }
`;
export const ThreeDots = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
`;
