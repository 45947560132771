import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleTablePreviewModal } from "../../../store/actions/modal/modalActions";
import {
  SubmitButton as CancelButton,
  CloseButton,
  ModalTitle,
} from "../Modal.styled";
import {
  TablePreviewModalContainer,
  ButtonsContainer,
  TablePreviewContainer,
} from "./TablePreviewModal.styled";
import BackdropComponent from "../../MUI/BackdropComponent";
import FindingItemTableHeader from "../../Cards/FindingQuestionCard/FindingTableInput/FindingItemTable/FindingItemTableHeader/FindingItemTableHeader";
import FindingItemTableRow from "../../Cards/FindingQuestionCard/FindingTableInput/FindingItemTable/FindingItemTableRow/FindingItemTableRow";
import { useMemo } from "react";

const TablePreviewModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  console.log(props);
  const handleCloseModal = () => {
    dispatch(toggleTablePreviewModal());
  };

  const columns = useMemo(() => {
    return props?.columns?.map?.((singleColumn) => ({
      ...singleColumn,
      answers: [{ answer: "" }],
    }));
  }, [props?.columns]);

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="absolute"
        />
      ) : (
        <></>
      )}
      <TablePreviewModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{t("tablePreview.title")}</ModalTitle>
        <TablePreviewContainer>
          <FindingItemTableHeader
            columns={columns}
            readonly
            otherRows={
              <>
                {columns?.[0]?.answers?.map?.((singleAnswer, index) => (
                  <FindingItemTableRow
                    key={index}
                    readonly
                    rowIndex={index}
                    lastRow
                    data={columns?.map?.(
                      (singleColumn) => singleColumn?.answers?.[index]
                    )}
                  />
                ))}
              </>
            }
          />
        </TablePreviewContainer>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.close")}
          </CancelButton>
        </ButtonsContainer>
      </TablePreviewModalContainer>
    </>
  );
};

TablePreviewModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  columns: PropTypes.array,
};

export default TablePreviewModal;
