import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import {
  FindingSettingsFormColumn,
  FindingSettingsFormContainer,
} from "./FindingSettingsForm.styled";
import findingInitialValues, {
  findingFormikConstants,
} from "../../../../../initialValues/findingInitialValues";
import { useFormik } from "formik";
//import findingValidationSchema from "../../../../../validations/findingValidationSchema";
import ClientField from "./ClientField/ClientField";
import LocationField from "./LocationField/LocationField";
import StaffField from "./StaffField/StaffField";
import DescriptionField from "./DescriptionField/DescriptionField";
import { useDispatch, useSelector } from "react-redux";
import { setFindingsChanged } from "../../../../../store/actions/findings/findingsActions";
import DateOfCheckingField from "./DateOfCheckingField/DateOfCheckingField";
import DateOfFindingField from "./DateOfFindingField/DateOfFindingField";
import { useMemo } from "react";
import {
  selectIsFindingSettingsChanged,
  selectSingleFindingIsEditing,
  // selectSingleFindingSettings,
} from "../../../../../store/selectors/findingsSelectors";
import findingValidationSchema from "../../../../../validations/findingValidationSchema";
import { selectStuffsData } from "../../../../../store/selectors/stuffsSelector";
import { selectClientsData } from "../../../../../store/selectors/clientsSelector";
import { statusTableConstants } from "../../../../../constants/findingStatusConstants";
import FacilityField from "./FacilityField/FacilityField";

const FindingSettingsForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  // const prefilledData = useSelector(selectSingleFindingSettings);
  const isEditing = useSelector(selectSingleFindingIsEditing);
  const staff = useSelector(selectStuffsData);
  const clients = useSelector(selectClientsData);

  useEffect(() => {
    if (
      Object.keys(props?.prefilledData)?.length > 4 &&
      props?.stateId === statusTableConstants.inProgress.id
    ) {
      if (
        !staff?.some?.(
          (singleStaff) =>
            singleStaff?.id === props?.prefilledData?.staffId?.value
        ) &&
        props?.prefilledData?.staffId != null
      ) {
        formik?.setFieldValue?.(findingFormikConstants.staffId, {
          edited: true,
          value: null,
        });
      }
      if (
        !clients?.some?.(
          (singleClient) =>
            singleClient?.id === props?.prefilledData?.clientId?.value
        ) &&
        props?.prefilledData?.clientId != null
      ) {
        formik?.setFieldValue?.(findingFormikConstants.clientId, {
          edited: true,
          value: null,
        });
      }
    }
  }, [props?.stateId, staff, clients, props?.prefilledData]);

  const initialValues = useMemo(
    () => findingInitialValues(props?.prefilledData),
    [isEditing, props?.prefilledData, props?.stateId]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: findingValidationSchema,
    validateOnBlur: true,
    onSubmit: props?.handleFormikSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik?.dirty && !isFindingChanged) {
      dispatch(setFindingsChanged(true));
    }
  }, [formik?.dirty]);

  useImperativeHandle(ref, () => ({
    formik: () => formik,
  }));

  return (
    <FindingSettingsFormContainer
      component="form"
      onSubmit={formik?.handleSubmit}
    >
      <FindingSettingsFormColumn>
        <DateOfFindingField disabled={props?.disabled} formik={formik} />
        <DateOfCheckingField
          disabled={props?.disabled}
          formik={formik}
          isEditing={props?.isEditing}
        />
        <ClientField
          disabled={props?.disabled}
          formik={formik}
          stateId={props?.stateId}
        />
        <LocationField disabled={props?.disabled} formik={formik} />
      </FindingSettingsFormColumn>
      <FindingSettingsFormColumn>
        <StaffField
          disabled={props?.disabled}
          formik={formik}
          stateId={props?.stateId}
        />
        <FacilityField disabled={props?.disabled} formik={formik} />
        <DescriptionField disabled={props?.disabled} formik={formik} />
      </FindingSettingsFormColumn>
    </FindingSettingsFormContainer>
  );
});

FindingSettingsForm.displayName = "FindingSettingsForm";

FindingSettingsForm.propTypes = {
  children: PropTypes.node,
  handleFormikSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  prefilledData: PropTypes.object,
  stateId: PropTypes.number,
};

export default FindingSettingsForm;
