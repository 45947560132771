import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FindingInputContainer,
  FindingQuestionCardContainer,
  FindingQuestionCardRadioButton,
  FindingQuestionCardRadioButtonsContainer,
  FindingQuestionCardRadioContainer,
  FindingQuestionCardRadioTitle,
  FindingQuestionDescriptionContainer,
  FindingQuestionInputField,
  FindingQuestionLabel,
} from "./FindingQuestionCard.styled";
import { useTranslation } from "react-i18next";
// import { FindingTextField } from "../../Findings/NewFinding/FindingSettings/FindingSettingsForm/FindingSettingsForm.styled";
import questionTypeConstants from "../../../constants/questionTypeConstants";
import {
  setFindingItem,
  setFindingsChanged,
} from "../../../store/actions/findings/findingsActions";
import { useDispatch, useSelector } from "react-redux";
import { isJsonString } from "../../../util/helpers/jsonHelper";
import TextareaInput from "../../Field/TextareaInput/TextareaInput";
import {
  selectFindingQuestionOld,
  selectFindingQuestionWithSameName,
  selectIsFindingSettingsChanged,
} from "../../../store/selectors/findingsSelectors";
import FindingQuestionImagePicker from "./FindingQuestionImagePicker/FindingQuestionImagePicker";
import useIsMobile from "../../../hooks/useIsMobile";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";
import {
  emptyHtml,
  makeParagraphInline,
} from "../../../util/helpers/htmlHelpers";
import FindingTableInput from "./FindingTableInput/FindingTableInput";

const FindingQuestionCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasResearchPassed, setHasResearchPassed] = useState(
    props?.question?.isInRange?.value
  );
  const [answer, setAnswer] = useState(props?.question?.answer?.value);
  const [description, setDescription] = useState({
    descriptionOne: props?.question?.descriptionOne?.value,
    descriptionTwo: props?.question?.descriptionTwo?.value,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const questionOld = useSelector(
    selectFindingQuestionOld(props?.question?.id)
  );
  const { isMobile } = useIsMobile();
  const showDescriptionField = useSelector(
    selectFindingQuestionWithSameName(props?.question?.id)
  );

  useEffect(() => {
    if (
      props?.question?.itemTypeId === questionTypeConstants.SELECT &&
      !isLoaded &&
      props?.question?.answer?.value?.length > 0
    ) {
      setAnswer(() => {
        let optionAnswer = props?.question?.options?.find(
          (singleOption) =>
            singleOption?.answer === props?.question?.answer?.value
        );
        return JSON.stringify({
          label: optionAnswer?.answer,
          id: optionAnswer?.id,
        });
      });
      setIsLoaded(true);
    }
  }, [props?.question]);

  const researchPassed = () => {
    if (!props?.disabled) {
      setHasResearchPassed(true);
      dispatch(
        setFindingItem({
          itemId: props?.question?.id,
          item: {
            ...props?.question,
            isInRange: {
              value: true,
              edited: !questionOld || !questionOld?.isInRange?.value,
            },
          },
        })
      );
    }
  };

  const makeFindingAbleToSave = () => {
    if (!isFindingChanged && !props?.disabled) dispatch(setFindingsChanged(true));
  };

  const researchNotPassed = () => {
    if (!props?.disabled) {
      setHasResearchPassed(false);
      dispatch(
        setFindingItem({
          itemId: props?.question?.id,
          item: {
            ...props?.question,
            isInRange: {
              value: false,
              edited: !questionOld || questionOld?.isInRange?.value,
            },
          },
        })
      );
    }
  };

  const answerQuestion = (newAnswer) => {
    console.log("ovo je pozvano")
    if (props?.disabled) return;
    let answerToSend = newAnswer != null ? newAnswer : answer;
    if (answerToSend?.target) {
      answerToSend = answerToSend?.target?.value;
    }
    if (answerToSend?.getCurrentContent) {
      answerToSend = draftToHtml(
        convertToRaw(answerToSend?.getCurrentContent?.())
      );
      if (answerToSend?.charCodeAt(answerToSend?.length - 1) === 10)
        answerToSend = answerToSend?.slice(0, -1);
      if (
        answerToSend?.slice(0, -1) === emptyHtml ||
        answerToSend === emptyHtml
      )
        answerToSend = "";
    }
    dispatch(
      setFindingItem({
        itemId: props?.question?.id,
        item: {
          ...props?.question,
          answer: {
            value: answerToSend,
            edited:
              !questionOld ||
              makeParagraphInline(answerToSend) !==
                makeParagraphInline(questionOld?.answer?.value),
          },
        },
      })
    );
    props?.changeConclusion();
  };

  const addDescription = (descriptionId) => {
    let descObj = {};
    descriptionId === 1
      ? (descObj.descriptionOne = {
          value: description?.descriptionOne,
          edited:
            !questionOld ||
            description?.descriptionOne !== questionOld?.descriptionOne?.value,
        })
      : (descObj.descriptionTwo = {
          value: description?.descriptionTwo,
          edited:
            !questionOld ||
            description?.descriptionTwo !== questionOld?.descriptionTwo?.value,
        });
    dispatch(
      setFindingItem({
        itemId: props?.question?.id,
        item: {
          ...props?.question,
          ...descObj,
        },
      })
    );
  };

  const onChangeHandler = (
    event,
    isAnswer = true,
    descriptionId = 1,
    immediateApply = false
  ) => {
    makeFindingAbleToSave();
    if (isAnswer) {
      if (event?.target?.value) {
        setAnswer(event?.target?.value);
      } else {
        let newAnswer = draftToHtml(convertToRaw(event?.getCurrentContent?.()));
        if (newAnswer?.charCodeAt(newAnswer?.length - 1) === 10)
          newAnswer = newAnswer?.slice(0, -1);
        if (newAnswer?.slice(0, -1) === emptyHtml) newAnswer = "";
        setAnswer(newAnswer);
      }
    } else
      descriptionId === 1
        ? setDescription((prevState) => ({
            ...prevState,
            descriptionOne: event?.target?.value,
          }))
        : setDescription((prevState) => ({
            ...prevState,
            descriptionTwo: event?.target?.value,
          }));

    if (immediateApply) answerQuestion(event);
  };

  const onChangeSelectHandler = (value, immediateApply = false) => {
    makeFindingAbleToSave();
    if (value)
      setAnswer(
        JSON.stringify({
          label: value?.label,
          id: value?.id,
        })
      );
    else setAnswer("");

    if (immediateApply) answerQuestion(value ? value?.label : "");
  };

  const onChangeImages = (newImages) => {
    dispatch(
      setFindingItem({
        itemId: props?.question?.id,
        item: {
          ...props?.question,
          images: newImages,
        },
      })
    );
  };

  return (
    <FindingQuestionCardContainer $mobile={props?.mobile}>
      <FindingInputContainer>
        {props?.question?.itemTypeId !== questionTypeConstants.IMAGE && (
          <>
            <FindingQuestionLabel
              $required={
                props?.question?.isMandatory &&
                (props?.question?.itemTypeId === questionTypeConstants.TABLE
                  ? props?.question?.columnAnswers?.length === 0
                  : answer === "" || answer === "<p></p>\n")
              }
              disabledField={props?.disabled}
              selectType={
                props?.question?.itemTypeId === questionTypeConstants.SELECT
              }
            >
              {props?.question?.question}
            </FindingQuestionLabel>
            <FindingQuestionDescriptionContainer
              $mobile={isMobile || props?.mobile}
            >
              {showDescriptionField && (
                <FindingQuestionInputField
                  value={description?.descriptionOne}
                  onChange={(event) => onChangeHandler(event, false, 1)}
                  onBlur={() => addDescription(1)}
                  disabledField={props?.disabled}
                />
              )}
              {showDescriptionField && (
                <FindingQuestionInputField
                  value={description?.descriptionTwo}
                  onChange={(event) => onChangeHandler(event, false, 2)}
                  onBlur={() => addDescription(2)}
                  disabledField={props?.disabled}
                />
              )}
            </FindingQuestionDescriptionContainer>
          </>
        )}
        {props?.question?.itemTypeId === questionTypeConstants.TABLE && (
          <FindingTableInput
            questionId={props?.question?.id}
            disabled={props?.disabled}
            mobile={props?.mobile}
          />
        )}
        {props?.question?.itemTypeId === questionTypeConstants.TEXT && (
          <TextareaInput
            placeholder={!props?.disabled && t("common.answer")}
            value={answer}
            // onChange={onChangeHandler}
            onBlur={answerQuestion}
            disabledField={props?.disabled}
            isRichText
          />
        )}
        {props?.question?.itemTypeId === questionTypeConstants.SELECT && (
          <AutocompleteField
            value={isJsonString(answer) ? JSON.parse(answer) : null}
            disabled={props?.disabled}
            label={!props?.disabled && t("findings.scanning.selectPlaceholder")}
            options={props?.question?.options?.map((singleOption) => ({
              label: singleOption?.answer,
              id: singleOption?.id,
            }))}
            dropdownLabel={["label"]}
            onChange={(value) => onChangeSelectHandler(value, true)}
            removeMaxWidth
          />
        )}
        {props?.question?.itemTypeId === questionTypeConstants.IMAGE && (
          <FindingQuestionImagePicker
            images={props?.question?.images}
            required={
              props?.question?.isMandatory &&
              props?.question?.images?.filter?.(
                (singleImage) => !singleImage?.removed
              )?.length === 0
            }
            disabled={props?.disabled}
            onChange={onChangeImages}
            questionName={props?.question?.question}
          />
        )}
      </FindingInputContainer>
      {props?.question?.isRateInRange && (
        <FindingQuestionCardRadioContainer $mobile={props?.mobile}>
          <FindingQuestionCardRadioTitle>
            {t("findings.scanning.researchPassed")}
          </FindingQuestionCardRadioTitle>

          <FindingQuestionCardRadioButtonsContainer>
            <FindingQuestionCardRadioButton
              $disabled={props?.disabled}
              $selected={hasResearchPassed}
              onClick={researchPassed}
            >
              {t("common.yes")?.toUpperCase()}
            </FindingQuestionCardRadioButton>
            <FindingQuestionCardRadioButton
              $disabled={props?.disabled}
              $selected={!hasResearchPassed}
              onClick={researchNotPassed}
            >
              {t("common.no")?.toUpperCase()}
            </FindingQuestionCardRadioButton>
          </FindingQuestionCardRadioButtonsContainer>
        </FindingQuestionCardRadioContainer>
      )}
    </FindingQuestionCardContainer>
  );
};

FindingQuestionCard.propTypes = {
  itemTypeId: PropTypes.number,
  question: PropTypes.object,
  options: PropTypes.array,
  isRateInRange: PropTypes.bool,
  mobile: PropTypes.bool,
  disabled: PropTypes.bool,
  changeConclusion: PropTypes.func,
};

FindingQuestionCard.defaultProps = {
  isRateInRange: true,
};

export default FindingQuestionCard;
