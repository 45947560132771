import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import Select from "../Select/Select";

export const TabChooserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  @media (max-width: 600px) {
    overflow-y: scroll;
    max-width: calc(100vw - 26px);
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;
export const Tab = styled(Box)`
  padding-bottom: 16px;
  white-space: nowrap;
  border-bottom: 3px solid
    ${(props) =>
      props?.selected ? selectedTheme.colors.pageTitleColor : "transparent"};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.pageTitleColor};
  cursor: pointer;
`;
export const TabsVerticalSeparator = styled(Box)`
  width: 1px;
  height: 23px;
  background-color: ${selectedTheme.colors.tabsSeparator};
`;
export const TabsHorizontalSeparator = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${selectedTheme.colors.questionSeparator};
  position: relative;
  top: -2px;
  z-index: -1;
`;
export const TabsOutterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const TabsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
  max-height: 40px;
  @media (max-width: 600px) {
    flex-wrap: nowrap;
  }
`;
export const OtherTabsSelector = styled(Select)`
  /* & > div:nth-child(2) {
    display: none;
  } */
  border-width: 0;
  max-height: 39px;
  & * {
    border-width: 0;
    max-height: 39px;
    box-shadow: none;
  }
  & > div:nth-child(2) > div:nth-child(1) {
    /* width: 14px; */
    /* overflow: hidden; */
    padding: 0;
  }
  & svg {
    display: none;
  }
`;
