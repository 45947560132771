import {
  BLOCK_USER_ERROR,
  CLEAR_USERS,
  CREATE_USER_ERROR,
  EDIT_USER_ERROR,
  GET_USERS_CLEAR,
  GET_USERS_ERROR,
  MY_USER_SET,
  SET_TOTAL_USERS,
  SET_USERS,
  SET_USERS_ERROR_MESSAGE,
  SINGLE_USER_ERROR,
  SINGLE_USER_SET,
  UNBLOCK_USER_ERROR,
  USER_CHANGE_PASSWORD_ERROR,
} from "../../actions/user/userActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  users: {
    loaded: false,
    data: [],
  },
  blockedUsers: [],
  activeUsers: [],
  totalActiveUsers: 0,
  totalBlockedUsers: 0,
  totalUsers: 0,
  singleUser: {},
  myUser: {},
  errorMessage: "",
};

export default createReducer(
  {
    [SET_USERS]: setUsers,
    [SINGLE_USER_SET]: setSingleUser,
    [SET_TOTAL_USERS]: setTotalUsers,
    [CLEAR_USERS]: clearUsers,
    [GET_USERS_CLEAR]: fetchUsersClear,
    [GET_USERS_ERROR]: setErrorMessage,
    [SINGLE_USER_ERROR]: setErrorMessage,
    [CREATE_USER_ERROR]: setErrorMessage,
    [USER_CHANGE_PASSWORD_ERROR]: setErrorMessage,
    [EDIT_USER_ERROR]: setErrorMessage,
    [BLOCK_USER_ERROR]: setErrorMessage,
    [UNBLOCK_USER_ERROR]: setErrorMessage,
    [SET_USERS_ERROR_MESSAGE]: setErrorMessage,
    [MY_USER_SET]: setMyUser,
  },
  initialState
);
function setUsers(state, { payload }) {
  if (payload.isActive)
    return {
      ...state,
      activeUsers: payload.data,
    };
  return {
    ...state,
    blockedUsers: payload.data,
  };
}
function setTotalUsers(state, { payload }) {
  if (payload.isActive)
    return {
      ...state,
      totalActiveUsers: payload.count,
    };
  return {
    ...state,
    totalBlockedUsers: payload.count,
  };
}
function clearUsers() {
  return initialState;
}
function fetchUsersClear(state) {
  return {
    ...state,
    totalUsers: initialState.totalUsers,
    users: initialState.users,
  };
}
function setSingleUser(state, { payload }) {
  return {
    ...state,
    singleUser: payload,
  };
}
function setMyUser(state, { payload }) {
  return {
    ...state,
    myUser: payload,
  };
}
function setErrorMessage(state, { payload }) {
  return {
    ...state,
    errorMessage: payload,
  };
}
