import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import { useTranslation } from "react-i18next";
import scanningObjectsTableConstants from "../../../constants/tableConstants/scanningObjectsTableConstants";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import {} from "../../../store/actions/measuringInstruments/measuringInstrumentsActions";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import {
  clearScanningObjects,
  fetchScanningObjects,
} from "../../../store/actions/scanningObjects/scanningObjectsActions";
import {
  selectScanningObjects,
  selectTotalScanningObjects,
} from "../../../store/selectors/scanningObjectsSelectors";
import {
  EDIT_SCANNING_OBJECTS_PAGE,
  NEW_SCANNING_OBJECTS_PAGE,
} from "../../../constants/pages";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import {
  closeModal,
  toggleSetFindingTypesModal,
} from "../../../store/actions/modal/modalActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectFindingTypes } from "../../../store/selectors/findingsSelectors";
import { fetchFindingTypes } from "../../../store/actions/findings/findingsActions";

const ScanningObjectsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef(null);
  const { hasRole } = useAuth();
  const findingTypes = useSelector(selectFindingTypes);

  useEffect(() => {
    if (findingTypes?.length === 0 && findingTypes != null) {
      dispatch(fetchFindingTypes());
    }
  }, [findingTypes]);

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleSubmit = (value) => {
    history.push(NEW_SCANNING_OBJECTS_PAGE, {
      findingType: value,
    });
    dispatch(closeModal());
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetFindingTypesModal({
        title: t("scanningObjects.newScanningObject.title"),
        rank: 0,
        handleSubmit,
      })
    );
  };

  useEffect(() => {
    if (history.location.state?.isDeleted) {
      makeToastMessage(
        {
          title: t("toast.success.deletedTitle", {
            typeOfData: t("scanningObjects.typeOfData"),
          }),
          description: t("toast.success.deletedDescription", {
            name: history.location.state?.name,
            typeOfData: t("scanningObjects.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      let currentState = { ...history?.location?.state };
      delete currentState.name;
      delete currentState.isDeleted;
      history.replace({
        state: currentState,
      });
    }
  }, [history.location.state]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(scanningObjectsTableConstants).map(
      (property) => ({
        ...scanningObjectsTableConstants[property],
        field: t(scanningObjectsTableConstants[property].i18key),
        propertyName: property,
        backendProperty:
          scanningObjectsTableConstants[property]?.backendProperty || property,
        style: scanningObjectsTableConstants[property]?.style,
        renderer:
          scanningObjectsTableConstants[property]?.renderer || TextRenderer,
      })
    );
    return columnsObject;
  }, [scanningObjectsTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.data?.map((singleData) => {
        let mappedDataObject = {
          name: singleData.name,
          pattern: singleData?.checklistTemplates?.some(
            (template) => template?.isDefault
          )
            ? t("scanningObjects.newScanningObject.templateEditor.active")
            : "", //
          findingType: findingTypes.find(
            (type) => type.id === singleData?.findingTypeId
          ),
          lastChanges: {
            date: formatDateTime(
              singleData?.updatedAtUtc || new Date().toString()
            ),
            author: `${singleData?.updatedByUser?.firstName || ""} ${
              singleData?.updatedByUser?.lastName || ""
            }`,
          },
          id: singleData?.id,
          actions: {
            id: singleData?.id,
            name: singleData.name,
            pattern: singleData?.serialNumber,
            lastChanges: {
              date: formatDateTime(
                singleData?.updatedAtUtc || new Date().toString()
              ),
              author: `${singleData?.updatedByUser?.firstName || ""} ${
                singleData?.updatedByUser?.lastName || ""
              }`,
            },
            totalData: newData?.data?.length,
            tableRef,
          },
        };
        return mappedDataObject;
      });
    },
    [findingTypes]
  );

  const handleRedirect = (scanningObjectId) =>
    replaceInRoute(EDIT_SCANNING_OBJECTS_PAGE, {
      scanningObjectId: scanningObjectId,
    });

  return (
    <Table
      ref={tableRef}
      hideAddButton={hideAddAndUpdate}
      tableColumns={tableColumns}
      searchPlaceholder={t("scanningObjects.table.searchPlaceholder")}
      dataSelector={selectScanningObjects}
      totalDataSelector={selectTotalScanningObjects}
      dispatchFunction={fetchScanningObjects}
      mapDataFunction={mapDataFunction}
      redirectHrefFunction={handleRedirect}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearScanningObjects}
    />
  );
};

ScanningObjectsTable.propTypes = {
  children: PropTypes.node,
};

export default ScanningObjectsTable;
