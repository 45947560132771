import { Box } from "@mui/material";
import styled from "styled-components";
import deviceConstants from "../../../../../constants/deviceConstants";
import selectedTheme from "../../../../../themes";
import GoBackButton from "../../../../Button/GoBackButton/GoBackButton";
import TextareaInput from "../../../../Field/TextareaInput/TextareaInput";

export const FindingScanningSectionContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) =>
    props?.$selectedDevice === deviceConstants.desktop ? "32px" : "22px"};
  padding: 12px 24px;
  @media (max-width: 600px) {
    padding: 0;
  }
`;
export const QuestionSeparator = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${selectedTheme.colors.questionSeparator};
`;

export const GoBackToSectionsButton = styled(GoBackButton)`
  @media (min-width: 600px) {
    display: none;
  }
`

export const NoteTextareaField = styled(TextareaInput)`
    /* margin-top: 32px; */
    & > textarea {
        margin-top: 0;
    }
`
