import React from "react";
import PropTypes from "prop-types";
import {
  TabChooserContainer,
  Tab,
  TabsContainer,
  TabsOutterContainer,
  TabsHorizontalSeparator,
} from "./TabChooser.styled";
import { useTranslation } from "react-i18next";

const TabChooser = (props) => {
  const { t } = useTranslation();

  return (
    <TabChooserContainer>
      <TabsOutterContainer>
        <TabsContainer>
          {props?.tabs?.map((singleTab) => {
            return (
              <Tab
                key={singleTab?.id}
                selected={props?.selectedTab === singleTab?.id}
                onClick={() =>
                  props?.handleChangeTab(singleTab?.id)
                }
              >
                {t(singleTab?.i18key)}
              </Tab>
            );
          })}
        </TabsContainer>
        <TabsHorizontalSeparator />
      </TabsOutterContainer>
    </TabChooserContainer>
  );
};

TabChooser.propTypes = {
  tabs: PropTypes.array,
  handleChangeTab: PropTypes.func,
  selectedTab: PropTypes.object,
  isEditing: PropTypes.bool,
};

export default TabChooser;
