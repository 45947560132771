import * as Yup from "yup";
import i18n from "../i18n";
import { renameColumnsFormikConstants } from "../initialValues/renameColumnsInitialValues";

export default (subtitleRequired) =>
  Yup.object().shape({
    [renameColumnsFormikConstants.columnTitle]: Yup.string()
      .trim()
      .required(i18n.t("table.renameColumns.titleRequired")),
    [renameColumnsFormikConstants.columnSubtitle]: subtitleRequired ? Yup.string()
      .trim()
      .required(i18n.t("table.renameColumns.subtitleRequired")) : Yup.string().trim(),
  });
