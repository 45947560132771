import React from "react";
import PropTypes from "prop-types";
import NewUserForm from "../../../components/NewUser/NewUserForm";
import { fetchCreateUser } from "../../../store/actions/user/userActions";
import { useTranslation } from "react-i18next";

const NewUserPage = () => {
  const { t } = useTranslation();
  // Add layout
  return (
    <NewUserForm
      dispatchFunction={fetchCreateUser}
      title={t("users.newUser")}
      submitText={t("common.save")}
    />
  );
};

NewUserPage.propTypes = {
  children: PropTypes.node,
};

export default NewUserPage;
