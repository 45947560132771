import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CheckboxContainer,
  ErrorMessage,
  InputContainer,
  InputField,
  InputLabel,
  InputRightElement,
  LoginButton,
  LoginFormContainer,
} from "./LoginForm.styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../store/utils/history";
import { HOME_PAGE } from "../../../constants/pages";
import {
  clearLoginErrors,
  fetchUser,
} from "../../../store/actions/login/loginActions";
import { useFormik } from "formik";
import loginInitialValues, {
  loginFormikConstants,
} from "../../../initialValues/loginInitialValues";
import loginValidationSchema from "../../../validations/loginValidationSchema";
import { selectLoginError } from "../../../store/selectors/loginSelectors";
import EyeStrikedIcon from "../../Icon/Icons/EyeStriked";
import EyeIcon from "../../Icon/Icons/Eye";
import { useEffect } from "react";

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(selectLoginError);
  const [showPassword, setShowPassword] = useState(false);
  const [triedToLogin, setTriedToLogin] = useState(false);

  const handleApiResponseSuccess = () => {
    dispatch(clearLoginErrors());
    history.push({
      pathname: HOME_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  useEffect(() => {
    dispatch(clearLoginErrors());
  }, []);

  const handleApiResponseError = () => {
    formik?.setFieldValue?.(loginFormikConstants.password, "");
    setTriedToLogin(true);
  };

  const handleSubmit = (values) => {
    const { userName, password } = values;
    dispatch(
      fetchUser({
        values: {
          userName,
          password,
        },
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleChangePassword = (event) => {
    formik?.handleChange(event);
    if (triedToLogin) setTriedToLogin(false);
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <LoginFormContainer component="form" onSubmit={formik?.handleSubmit}>
      <InputContainer>
        <InputLabel>{t("login.emailLabel")}</InputLabel>
        <InputField
          id={loginFormikConstants.username}
          onChange={formik?.handleChange}
          placeholder={t("login.emailPlaceholder")}
        />
        {formik?.errors?.[loginFormikConstants.username] &&
          formik?.touched?.[loginFormikConstants.username] && (
            <ErrorMessage>
              {formik?.errors?.[loginFormikConstants.username]}
            </ErrorMessage>
          )}
      </InputContainer>
      <InputContainer>
        <InputLabel>{t("login.passwordLabel")}</InputLabel>
        <InputField
          type={!showPassword && "password"}
          id={loginFormikConstants.password}
          value={formik?.values?.[loginFormikConstants.password]}
          onChange={handleChangePassword}
        />
        {formik?.errors?.[loginFormikConstants.password] &&
        formik?.touched?.[loginFormikConstants.password] &&
        !triedToLogin ? (
          <ErrorMessage>
            {formik?.errors?.[loginFormikConstants.password]}
          </ErrorMessage>
        ) : (
          error && (
            <ErrorMessage>{t("login.emailPasswordIncorect")}</ErrorMessage>
          )
        )}
        <InputRightElement
          onClick={() => setShowPassword((prevValue) => !prevValue)}
          isLogin={true}
        >
          {showPassword ? <EyeStrikedIcon /> : <EyeIcon />}
        </InputRightElement>
      </InputContainer>
      <CheckboxContainer>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              checked={formik?.values?.rememberMe}
              id={loginFormikConstants.rememberMe}
              onChange={formik?.handleChange}
            />
          }
          label={<InputLabel>{t("login.rememberMeLabel")}</InputLabel>}
        />
        <LoginButton component="button" type="submit">
          {t("login.loginButton")}
        </LoginButton>
      </CheckboxContainer>
    </LoginFormContainer>
  );
};

LoginForm.propTypes = {
  children: PropTypes.node,
};

export default LoginForm;
