import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import ExportIcon2 from "../Icon/Icons/ExportIcon2";
import { TableColumnsContainer } from "../Table/TableContent/TableColumns/TableColumns.styled";
import { TableSingleColumnContainer } from "../Table/TableContent/TableColumns/TableSingleColumn/TableSingleColumn.styled";
import { TableDataRowContainer } from "../Table/TableContent/TableData/TableData.styled";
import Link from "../Link/Link";
import { UserRendererContainer } from "../Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer.styled";

export const DashboardContainer = styled(Box)`
  padding-bottom: 20px;
  & ${TableSingleColumnContainer} {
    color: ${selectedTheme.colors.dashboardTitleColor};
  }
  & ${TableColumnsContainer} {
    background-color: ${selectedTheme.colors.dashboardHeaderColor};
  }
  & ${TableDataRowContainer} {
    background-color: ${selectedTheme.colors.dashboardRowColor};
    &:hover {
      background-color: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
    }
  }
  & ${UserRendererContainer} {
    &:hover {
      cursor: initial;
    }
  }
`;

export const TablesContainer = styled(Grid)`
  margin-top: -15px;
`;

export const TableTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.pageTitleColor};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`;

export const TableTitleContainer = styled(Box)`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const RedirectIcon = styled(ExportIcon2)`
  cursor: pointer;
  min-width: 20px;
`;
export const RedirectIconContainer = styled(Link)``
