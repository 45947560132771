import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import loadingReducer from "./loading/loadingReducer";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import createFilter from "redux-persist-transform-filter";
import modalReducer from "./modal/modalReducer";
import usersReducer from "./users/usersReducer";
import standardsReducer from "./standards/standardsReducer";
import clientsReducer from "./clients/clientsReducer";
import measuringInstrumentsReducer from "./measuringInstrumentsReducer/measuringInstrumentsReducer";
import scanningObjectsReducer from "./scanningObjects/scanningObjectsReducer";
import stuffsReducer from "./stuffs/stuffsReducer";
import companiesReducer from "./companies/companiesReducer";
import sidebarReducer from "./sidebar/sidebarReducer";
import findingsReducer from "./findings/findingsReducer";
import appReducer from "./app/appReducer";

const loginPersistConfig = {
  key: "login",
  storage: storage,
  transform: [createFilter("login", ["email", "token"])],
};

export default combineReducers({
  app: appReducer,
  login: persistReducer(loginPersistConfig, loginReducer),
  loading: loadingReducer,
  modal: modalReducer,
  users: usersReducer,
  standards: standardsReducer,
  clients: clientsReducer,
  measuringInstruments: measuringInstrumentsReducer,
  scanningObjects: scanningObjectsReducer,
  stuffs: stuffsReducer,
  companies: companiesReducer,
  sidebar: sidebarReducer,
  findings: findingsReducer,
});
