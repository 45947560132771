import { all } from "redux-saga/effects";
import clientsSaga from "./clientsSaga";
import loginSaga from "./loginSaga";
import measuringInstrumentsSaga from "./measuringInstrumentsSaga";
import scanningObjectsSaga from "./scanningObjectsSaga";
import standardsSaga from "./standardsSaga";
import stuffsSaga from "./stuffsSaga";
import usersSaga from "./usersSaga";
import companiesSaga from "./companiesSaga";
import findingsSaga from "./findingsSaga";
import imagesSaga from "./imagesSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    usersSaga(),
    standardsSaga(),
    measuringInstrumentsSaga(),
    clientsSaga(),
    scanningObjectsSaga(),
    stuffsSaga(),
    companiesSaga(),
    findingsSaga(),
    imagesSaga(),
  ]);
}
