const settingsTabsConstants = {
  general: {
    id: 0,
    i18key: "companySettings.general",
  },
  mail: {
    id: 1,
    i18key: "companySettings.mail",
  },
};

export default settingsTabsConstants;

export const settingsTabsAsArray = Object.keys(settingsTabsConstants).map(
  (singleTab) => settingsTabsConstants[singleTab]
);
