export default {
  app: {
    title: "Diligent EQ",
  },
  apiErrors: {
    error: "Greška!",
    500: "Server error",
  },
  notOnline: {
    title: "Internet konekcija je prekinuta!",
    description:
      "Čuvanje podataka nije dostupno bez internet konekcije, ostvarite konekciju kako bi nastavili sa radom!\n\nNemojte gasiti pretraživač kako ne biste izgubili unete informacije!",
  },
  common: {
    close: "Zatvori",
    okay: "U redu",
    open: "Otvori",
    trademark: "TM",
    noOptions: "Nema opcija",
    search: "Pretraži",
    error: "Greška!",
    continue: "Nastavi",
    labelUsername: "Username",
    labelPassword: "Lozinka",
    next: "Dalje",
    nextPage: "Sledeća stranica",
    previousPage: "Prethodna stranica",
    back: "Nazad",
    goBack: "Idi nazad",
    ok: "Ok",
    done: "Gotovo",
    confirm: "Potvrdi",
    cancel: "Odustani",
    remove: "Izbriši",
    invite: "Pozovi",
    save: "Sačuvaj",
    submit: "Pošalji",
    complete: "Završi",
    download: "Skini",
    activate: "Aktiviraj",
    deactivate: "Deaktiviraj",
    loading: "Učitavanje...",
    add: "Dodaj",
    yes: "Da",
    no: "Ne",
    to: "do",
    select: "Izaberi...",
    choose: "Izaberi",
    requiredFieldsText: "Polja označena sa * su obavezna.",
    date: {
      range: "{{start}} do {{end}}",
    },
    created: "Kreiran",
    lastChanges: "Poslednje izmene",
    name: "Naziv",
    reset: "Resetuj",
    active: "Aktivan",
    answer: "Odgovor",
    promptLeave:
      "Imate nesačuvane promene. Da li ste sigurni da želite da napustite stranu?",
    saveFirst: "Prvo sačuvajte podatke",
    copy: "Kopiraj",
    setPassword: "Podesi lozinku",
    changePassword: "Promeni lozinku",
    changePasswordEmail: "Promeni Lozinku Emaila",
    passwordEntered: "Lozinka uneta",
    change: "Izmeni",
    delete: "Obriši",
    pdf: "PDF",
  },
  login: {
    logInTitle: "Ulogujte se u svoj nalog",
    emailLabel: "Email",
    emailPlaceholder: "jovan.jovanovic@gmail.com",
    passwordLabel: "Lozinka",
    rememberMeLabel: "Zapamti me",
    loginButton: "Uloguj se",
    emailFormat: "Mejl nije u validnom formatu!",
    usernameRequired: "Mejl je obavezan!",
    passwordRequired: "Lozinka je obavezna!",
    emailPasswordIncorect: "Pogrešan mail ili lozinka",
  },
  changePassword: {
    newPassword: "Nova lozinka",
    newPasswordLabel: "Lozinka",
    confirmPassword: "Potvrdi lozinku",
    confirmPasswordLabel: "Potvrda lozinke",
    blankPasswordError: "Unesite lozinku!",
    differentPasswordsError: "Lozinke nisu iste!",
    changePasswordTitle: "Promeni lozinku za {{name}}",
    passwordNumberOfCharacters: "Lozinka ne sme imati više od 255 karaktera!",
    validationPasswordDescription:
      "Lozinka mora da sadrži najmanje 6 karaktera, brojeve, <br /> velika i mala slova i bar jedan specijalni karakter",
    passwordLength: "Lozinka mora sadržati najmanje 6 karaktera",
    numbersRequred: "Lozinka mora sadržati brojeve",
    specialCharactersRequred: "Lozinka mora sadržati specijalne karaktere",
    lettersRequred: "Lozinka mora sadržati velika i mala slova",
    changedPasswordSuccess: "Lozinka promenjena!",
    changedPasswordSuccessDescription: "Lozinka za {{name}} je promenjena!",
  },
  forgotPassword: {
    title: "Zaboravili ste lozinku",
    label: "Pošalji mejl",
    emailRequired: "Mejl je obavezan.",
    emailFormat: "Nevalidan format mejl adrese.",
    forgotPassword: {
      title: "Zaboravili ste lozinku",
      subtitle:
        "Odgovorite na tajno pitanje kako biste dobili pristup vašem nalogu",
      label: "Resetuj lozinku",
    },
  },
  table: {
    filter: "Filter",
    title: "Naslov",
    subtitle: "Podnaslov",
    addRow: "Dodaj novi red",
    removeRow: "Izbriši odgovore iz reda",
    copyRow: "Kopiraj odgovore iz reda",
    addRowNotAllowed:
      "Ne možete dodati novi red ukoliko poslednji red nema barem jedno popunjeno polje",
    renameColumns: {
      title: "Preimenuj naslove kolone",
      rename: "Preimenuj",
      titleRequired: "Naslov mora biti popunjen.",
      subtitleRequired: "Podnaslov mora biti popunjen.",
      errors: {
        existingWithSubtitle: "Već postoji kolona sa istim naslovom!",
        existingSameSubtitle:
          "Već postoji kolona sa istim naslovom i podnaslovom!",
        onlySubtitle: "Već postoji kolona sa istim naslovom i nema podnaslov!",
      },
    },
    dropdown: {
      desc1: "Prikaži",
      desc2: "od <0>{{number}}</0> podataka",
    },
  },
  roles: {
    admin: "Administrator",
    manager: "Menadžer",
    guest: "Gost",
    operator: "Operater",
  },
  users: {
    title: "Korisnici",
    typeOfData: "Korisnik",
    typeOfDataPlural: "korisnike",
    searchPlaceholder: "Pretraži korisnike...",
    table: {
      users: {
        columnTitle: "Ime i prezime",
      },
      email: {
        columnTitle: "Email",
      },
      lastLogin: {
        columnTitle: "Poslednje aktivan",
      },
      role: {
        columnTitle: "Uloga",
      },
      lastChanges: {
        columnTitle: "Poslednje izmene",
      },
      actions: {
        columnTitle: "Akcije",
      },
    },
    newUser: "Novi korisnik",
    basicInfo: "Osnovne informacije",
    accountInfo: "Nalog",
    firstName: "Ime",
    lastName: "Prezime",
    created: "Kreiran",
    userRole: "Korisnička uloga",
    email: "Email",
    password: "Lozinka",
    confirmPassword: "Potvrda lozinke",
    setPassword: "Podesi lozinku",
    cancel: "Odustani",
    save: "Napravi korisnika",
    editUser: "Korisnik",
    deleteUser: "Izbriši korisnika",
    createUser: "Napravi korisnika",
    activateUser: "Aktiviraj",
    deactivateUser: "Deaktiviraj",
    userChangedSuccess: "Uspešno ste izmenili korisnika",
    tabActive: "Aktivni",
    tabDeactive: "Deaktivirani",
    newUserPlaceholders: {
      firstName: "Jovan",
      lastName: "Jovic",
      email: "jovan.jovic@gmail.com",
      password: "Lozinka",
      confirmPassword: "Potvrda lozinke",
    },
    modal: {
      deactivate: {
        title: "Deaktiviraj korisnika",
        description: "Da li zelite da deaktivirate označenog korisnika?",
      },
      activate: {
        title: "Aktiviraj korisnika",
        description: "Da li zelite da aktivirate označenog korisnika?",
      },
    },
    tooltip: {
      changePassword: "Promeni lozinku",
      changeUser: "Detalji",
    },
  },
  paging: {
    next: "Sledeća",
    prev: "Prethodna",
  },
  companySettings: {
    title: "Podešavanja",
    companyName: "Naziv",
    PIB: "PIB",
    identificationNumber: "Matični broj",
    address: "Adresa",
    companyDirector: "Direktor kompanije",
    email: "Email",
    logo: "Logo preduzeća",
    choseLogo: `Privuci ili <p>dodaj logo</p>`,
    signature: "Potpis direktora",
    chooseSignature: `Privuci ili <p>dodaj potpis</p>`,
    stamp: "Pečat",
    chooseStamp: `Privuci ili <p>dodaj pečat</p>`,
    companyLicense: "Licenca preduzeća",
    choseCompanyLicense: "Privuci ili <p>dodaj licencu</p>",
    APR: "Izvod iz APR-a",
    choseAPR: "Privuci ili <p>dodaj izvod iz APR-a</p>",
    save: "Sačuvaj",
    cancel: "Odustani",
    companyNameRequired: "Naziv kompanije je obavezan",
    PIBRequred: "PIB kompanije je obavezan",
    PIBnoOfCharacters: "PIB mora imati 9 cifara",
    idNumberRequred: "Matični broj je obavezan",
    idNumberNoOfCharacters: "Matični broj mora imati 8 cifara",
    addressRequired: "Adresa je obavezna",
    emailRequired: "Email je obavezan",
    moreImagesAllowed: "Moguće je dodati više slika",
    choseAPRSmall: "Privuci ili dodaj <br /> izvod iz APR-a",
    distributionEmails: "Lista distribucionih emailova",
    newEmail: "Novi Email",
    bankAccountNumber: "Broj tekućeg računa",
    phoneNumber: "Broj telefona",
    bankAccountNumberRequired: "Broj tekućeg računa je obavezan",
    phoneNumberRequired: "Broj telefona je obavezan",
    companyChangedSuccess: "Kompanija je uspešno izmenjena",
    general: "Osnovna",
    mail: "Email i arhiva",
    autoSendMails: "Pošalji email po zatvaranju stručnog nalaza",
    archive: {
      autoSave: "Arhiviraj po zatvaranju stručnog nalaza",
      dropdown: "Izaberi mesto arhiviranja",
      googleDrive: {
        driveId: "Drive ID",
        driveSecret: "Drive Secret",
        descriptionText: "Privuci ili <p>dodaj drive fajl</p>",
        addedFileDesc:
          "Fajl <0>{{name}}</0> uspešno dodat! <br /> <p>Promeni ili privuci novi fajl</p>",

        verify: "Verifikuj",
        verifyText:
          "Popunite sva polja kako biste verifikovali nove podatke za arhiviranje, ili verifikujte već sačuvane podatke.",
        driveVerified: "Google Drive verifikovan",
        fileUploaded: "{{name}} uspešno dodat!",
        driveIdRequried: "Drive ID je obavezno polje!",
        fileRequired: "Fajl je obavezan!",
      },
    },
    confirmModal: {
      title: "Nezapamćene promene",
      description:
        "Napravili ste promene u ovoj sekciji i niste sačuvali!\nDa li želite da nastavite bez čuvanja promena?",
      cancel: "Odbaci promene",
      save: "Sačuvaj",
    },
  },
  emailSettings: {
    userNameRequired: "Email je obavezno polje!",
    hostRequired: "Host je obavezno polje!",
    sslRequired: "SSL je obavezan!",
    portRequired: "Port je obavezno polje!",
    portIsNumber: "Port mora biti broj!",
    passwordRequired: "Lozinka je obavezno polje!",
    emailFormat: "Email format nije validan!",
    label: {
      userName: "Email",
      port: "Port",
      host: "Host",
      password: "Lozinka",
      ssl: "Uključi SSL",
    },
    isVerified: "Email verifikovan",
    notVerified: "Email nije verifikovan!",
    verificationHint: "Popunite sva polja kako biste verifikovali email",
    buttonLabel: "Verifikuj",
  },
  userPopover: {
    logout: "Odjava",
    profile: "Profil",
  },
  pages: {
    settings: "Podešavanja",
    newUser: "Novi korisnik",
    users: "Korisnici",
    editUser: "Korisnik",
    standards: "Propisi i standardi",
    research: "Predefinisanje ispitivanja",
    clients: "Klijenti",
    profile: "Profil",
    newClient: "Novi klijent",
    editClient: "Klijent",
    "measuring-instruments": "Instrumenti za ispitivanje",
    "scanning-objects": "Predmeti ispitivanja",
    newScanningObject: "Novi predmet",
    editScanningObject: "Predmet",
    staff: "Osoblje",
    newStuff: "Nova osoba",
    editStuff: "Osoba",
    findings: "Stručni nalazi",
    newFinding: "Novi nalaz",
    editFinding: "Nalaz",
    personal: "Personal",
    home: "Početna",
  },
  dashboard: {
    title: "Dobrodošli na vašu kontrolnu tablu!",
    tableSubtitle1: "Novi nalazi",
    tableSubtitle2: "Nedavno kompletirani nalazi",
    tableSubtitle3: "Nedavno promenjeni predmeti ispitivanja",
    tableSubtitle4: "Poslednje logovani korisnici",
    table: {
      name: { columnTitle: "Naziv" },
    },
  },
  registerUser: {
    firstNameRequired: "Ime je obavezno!",
    lastNameRequired: "Prezime je obavezno!",
    userRoleRequired: "Uloga je obavezna!",
    emailRequired: "Email je obavezan!",
    blankPasswordError: "Lozinka je obavezna!",
    confirmPasswordRequred: "Potvrda lozinke je obavezna!",
    emailExisting: "Email je zauzet!",
    firstNameNumberOfCharacters: "Ime ne sme imati više od 255 karaktera!",
    lastNameNumberOfCharacters: "Prezime ne sme imati više od 255 karaktera!",
    emailNumberOfCharacters: "Email ne sme imati više od 255 karaktera!",
    passwordNumberOfCharacters: "Lozinka ne sme imati više od 255 karaktera!",
  },
  standards: {
    title: "Propisi i standardi",
    typeOfData: "Standard",
    typeOfDataPlural: "standarde i propise",
    table: {
      caption: {
        columnTitle: "Naziv",
      },
      isDefault: {
        columnTitle: "Podrazumevani",
      },
      lastChanges: {
        columnTitle: "Poslednje izmene",
      },
      findingTypes: {
        columnTitle: "Tipovi nalaza",
      },
      actions: {
        columnTitle: "Akcije",
      },
      searchPlaceholder: "Pretraži propise i standarde...",
      newStandard: "Novi standard",
    },
    addStandard: "Dodaj propis/standard",
    chooseStandard: "Izaberi propis/standard",
    modal: {
      txtLabel: "Naziv propisa",
      findingTypesRequired: "Izaberite bar jedan tip nalaza!",
      txtareaPlaceholder: "Propis",
      newStandard: "Novi propis i standard",
      editStandard: "Propis i standard",
      isDefault: "Podrazumevani propis",
      errorMessage: "Naziv je obavezno polje!",
      validationMessage: "Naziv ne sme sadržati više od 255 karaktera!",
      deleteBtnLabel: "Obriši",
      defaultTitle: "Promeni lozinku",
      chooseTypes: "Izaberi tipove",
      findingTypes: "Tipovi nalaza",
      delete: {
        title: "Izbriši propis/standard",
        description: {
          1: "Da li želite da izbrišete označeni propis/standard?",
          2: "Ovu funkciju nije moguće revertovati!",
        },
      },
    },
    tooltip: {
      changeStandard: "Detalji",
      deleteStandard: "Izbriši",
    },
  },
  clients: {
    title: "Klijenti",
    typeOfData: "Klijent",
    typeOfDataPlural: "klijente",
    table: {
      clients: {
        columnTitle: "Naziv",
      },
      pib: {
        columnTitle: "PIB",
      },
      address: {
        columnTitle: "Adresa",
      },
      phoneNumber: {
        columnTitle: "Broj telefona",
      },
      idNumber: {
        columnTitle: "Matični broj",
      },
      created: {
        columnTitle: "Kreiran",
      },
      lastChanges: {
        columnTitle: "Poslednje izmene",
      },
      actions: {
        columnTitle: "Akcije",
      },
      searchPlaceholder: "Pretraži klijente...",
      newClient: "Novi klijent",
    },
    clientName: "Naziv",
    pib: "PIB",
    address: "Adresa",
    phoneNumber: "Broj telefona",
    idNumber: "Matični broj",
    contactEmail: "Kontakt email",
    newEmail: "Novi email",
    distributionMailList: "Lista distribucionih emailova",
    cancel: "Odustani",
    newClient: "Novi klijent",
    editClient: "Detalji",
    editClientTitle: "Klijent",
    deleteClient: "Izbriši",
    createClient: "Napravi klijenta",
    clientNameRequired: "Naziv klijenta je obavezno polje!",
    clientNameNumberOfCharacters:
      "Naziv klijenata ne sme imati više od 255 karaktera!",
    pibRequired: "PIB je obavezno polje!",
    pibNumberOfCharacters: "PIB mora imati 9 cifara",
    addressRequired: "Adresa je obavezno polje!",
    addressNumberOfCharacters: "Adresa ne sme imati više od 255 karaktera!",
    phoneNumberRequired: "Broj telefona je obavezno polje!",
    phoneNumberNumberOfCharacters:
      "Broj telefona ne sme imati više od 255 karaktera!",
    idNumberRequired: "Matični broj je obavezno polje!",
    idNumberNoOfCharacters: "Matični broj mora imati 8 cifara",
    emailRequired: "Email je obavezno polje!",
    emailFormat: "Email format nije validan!",
    contactEmailNumberOfCharacters:
      "Email adresa ne sme imati više od 255 karaktera!",
    distributionEmailNumberOfCharacters:
      "Email adresa ne sme imati više od 255 karaktera!",
    distributionEmailsEmpty:
      "Obavezno je uneti barem jedan distribution email!",
    newClientPlaceholders: {
      clientName: "Avera d.o.o",
      pib: "123456789",
      address: "Bulevar 23/25",
      phoneNumber: "012/345-6789",
      idNumber: "12345678",
      contactEmail: "avera@gmail.com",
      distributionEmail: "avera@gmail.com",
    },
    modal: {
      distributionEmails: {
        title: "Distribucioni emailovi",
      },
      delete: {
        title: "Izbriši klijenta",
        description: {
          1: "Da li želite da izbrišete označenog klijenta?",
          2: "Ovu funkciju nije moguće revertovati!",
        },
      },
    },
    tooltip: {
      removeDistributionMail: "Izbriši",
      mail: "Prikaži distribucione emailove",
    },
  },
  clipboard: {
    copy: "Kopiraj u klipbord",
  },
  measuringInstruments: {
    typeOfData: "Instrument za ispitivanje",
    typeOfDataPlural: "Instrumente za ispitivanje",
    title: "Instrumenti za ispitivanje",
    deleteInstrument: "Izbriši",
    table: {
      name: {
        columnTitle: "Naziv",
      },
      factoryNumber: {
        columnTitle: "Fabrički broj",
      },
      manufacturer: {
        columnTitle: "Proizvođač",
      },
      benchmarking: {
        columnTitle: "Uverenje o etaloniranju",
        columnTitleShorter: "Broj uverenja",
      },
      benchmarkDate: {
        columnTitle: "Datum",
      },
      lastChanges: {
        columnTitle: "Poslednje izmene",
      },
      findingTypes: {
        columnTitle: "Tipovi nalaza",
      },
      actions: {
        columnTitle: "Akcije",
      },
      delete: {
        columnTitle: "Izbaci",
      },
      searchPlaceholder: "Pretraži instrumente...",
      newInstrument: "Novi instrument",
    },
    errors: {
      findingTypesRequired: "Izaberite bar jedan tip nalaza!",
      nameRequired: "Naziv instrumenta je obavezan!",
      factoryNumberRequired: "Fabrički broj je obavezan!",
      factoryNumberExisting: "Fabrički broj je zauzet!",
      manufacturerRequired: "Proizvođač je obavezan!",
      benchmarkingRequired: "Broj uverenja o etaloniranju je obavezan!",
    },
    modal: {
      newInstrument: "Novi instrument",
      editInstrument: "Instrument za ispitivanje",
      delete: {
        title: "Izbriši instrument",
        description: {
          1: "Da li želite da izbrišete označeni instrument?",
          2: "Ovu funkciju nije moguće revertovati!",
        },
      },
      chooseCalibrationCertificate:
        "Privuci ili <p>dodaj sliku uverenja o etaloniranju</p>",
      fields: {
        name: {
          label: "Naziv instrumenta",
          placeholder: "Instrument",
        },
        factoryNumber: {
          label: "Fabrički broj",
          placeholder: "FB-436425632",
        },
        manufacturer: {
          label: "Proizvođač",
          placeholder: "Proizvođač",
        },
        benchmarking: {
          label: "Broj uverenja o etaloniranju",
          placeholder: "Broj uverenja",
        },
        date: {
          label: "Uverenje važi do:",
          placeholder: "Datum",
        },
        calibrationCertificate: {
          label: "Slika uverenja o etaloniranju",
        },
      },
    },
    tooltip: {
      changeMeasuringInstrument: "Detalji",
    },
    nameRequired: "Naziv instrumenta je obavezan",
    factoryNumberRequired: "Fabrički broj je obavezan",
    manufacturerRequired: "Proizvođač je obavezan",
    benchmarkRequired: "Broj uverenja je obavezan",
  },
  scanningObjects: {
    typeOfData: "Predmet ispitivanja",
    typeOfDataPlural: "Predmete ispitivanja",
    title: "Predmeti ispitivanja",
    positiveConclusion: "Pozitivni zaključak",
    negativeConclusion: "Negativni zaključak",
    table: {
      name: {
        columnTitle: "Naziv",
      },
      pattern: {
        columnTitle: "Šablon",
      },
      lastChanges: {
        columnTitle: "Poslednje izmene",
      },
      actions: {
        columnTitle: "Akcije",
      },
      searchPlaceholder: "Pretraži predmete ispitivanja...",
      newScanningObject: "Novi predmet",
    },
    editScanningObject: {
      title: "Predmet ispitivanja",
      submitButton: "Izmeni predmet ispitivanja",
    },
    duplicateError: "Dupla pitanja moraju imati popunjen prvi opis.",
    addScanningObject: "Dodaj predmet ispitivanja",
    addScanningObjectShorter: "Dodaj predmet",
    chooseScanningObject: "Izaberi predmet ispitivanja",
    type: "Tip: ",
    newScanningObject: {
      submitButton: "Napravi predmet ispitivanja",
      title: "Novi predmet ispitivanja",
      namePlaceholder: "Naziv predmeta ispitivanja...",
      addTemplate: "Dodaj šablon",
      templateEditor: {
        pages: {
          active: "Aktivni šablon",
          all: "Svi šabloni",
        },
        addInstrument: "Dodaj instrument",
        pickInstrument: "Izaberite instrument",
        addStandard: "Dodaj propis/standard",
        pickStandard: "Izaberite propis/standard",
        noInstrumentsText: "Dodajte instrument  za ispitivanje iz menija",
        noStandardsText: "Dodajte propis/standard iz menija",
        sections: "Sekcije",
        addSections: "Dodajte sekciju",
        createdBy: "Kreiran {{date}} - {{username}}",
        updatedAt: "{{state}} {{date}}",
        archived: "Arhiviran",
        active: "Aktivan",
        newSection: "Nova sekcija",
      },
    },
    addFirstItem: "Dodaj prvu stavku",
    deleteScanningObject: "Izbriši",
    copyScanningObject: "Kopiraj",
    noTemplate: "Nema Šablona!",
    noTemplatev2: "Ili izaberi iz liste ",
    noTemplateLink: "Svih Šablona",
    nameRequired: "Naziv je obavezan!",

    modal: {
      delete: {
        title: "Izbriši predmet ispitivanja",
        description: {
          1: "Da li želite da izbrišete označeni predmet ispitivanja?",
          2: "Ovu funkciju nije moguće revertovati!",
        },
      },
    },
    tooltip: {
      changeScanningObject: "Detalji",
      removeInstrument: "Izbriši",
      addInstrument: "Dodaj instrument za ispitivanje",
      addStandard: "Dodaj propis/standard",
      editItem: "Izmeni stavku",
      removeItem: "Izbriši stavku",
      editSection: "Izmeni sekciju",
      addSection: "Dodaj sekciju",
      removeSection: "Izbriši sekciju",
    },
  },
  toast: {
    success: {
      createdTitle: "{{typeOfData}} napravljen!",
      editedTitle: "{{typeOfData}} izmenjen!",
      deletedTitle: "{{typeOfData}} izbrisan!",
      createdDescription: "{{name}} uspešno napravljen!",
      editedDescription: "{{name}} uspešno izmenjen!",
      deletedDescription: "{{name}} uspešno izbrisan!",
      emailVerificationTitle: "Email je verifikovan!",
      emailVerificationDescription: "Uspešno ste verifikovali email!",
      deactivateTitle: "{{typeOfData}} deaktiviran!",
      deactivateDescription: "{{name}} uspešno deaktiviran!",
      activateTitle: "{{typeOfData}} aktiviran!",
      activateDescription: "{{name}} uspešno aktiviran!",
      copyTitle: "{{typeOfData}} kopiran!",
      copyDescription: "{{name}} uspešno kopiran!",
    },
  },
  questions: {
    add: "Dodaj stavku",
    new: "Nova stavka",
    edit: "Izmeni stavku",
    name: "Naziv stavke",
    description: "Dodatni detalji stavke",
    namePlaceholder: "Naziv",
    nameRequired: "Naziv stavke je obavezno polje!",
    type: "Vrsta stavke",
    typeAltText: "Vrsta odgovora stavke",
    defaultAnswerOptional: "Podrazumevani odgovor (opciono)",
    defaultAnswerPlaceholder: "Odgovor",
    defaultAnswer: "Podrazumevani odgovor",
    chooseDefaultAnswer: "Izaberi podrazumevani odgovor",
    noDefaultAnswer: "Nema podrazumevanog",
    noteOptional: "Napomena - Dozvoljeni opseg (opciono)",
    noteOptionalPlaceholder: "255 karaktera",
    possibleAnswers: "Mogući odgovori",
    countedAnswer: "Odgovor {{count}}",
    requiredAnswer: "Obavezan odgovor",
    researchPassed: "Potvrda uspešnog ispitivanja",
    researchPassedSatisfaction: "(Odgovara/Ne odgovara)",
    ordinalNumber: "Redni broj",
    addColumn: "Dodaj kolonu",
    columnTitle: "Naslov",
    columnSubtitle: "Podnaslov",
    previewTable: "Pregled tabele",
    hintText: "Vrsta odgovora stavke: <0>{{type}}</0>",
    tableError:
      "Ne sme podnaslov kolone biti prazan ukoliko ima više kolona sa istim naslovom!",
  },
  tablePreview: {
    title: "Pregled tabele",
  },
  findingPreview: {
    title: "Pregled stručnog nalaza",
  },
  stuffs: {
    title: "Osoblje",
    typeOfData: "Osoblje",
    typeOfDataPlural: "osoblje",
    table: {
      stuffs: {
        columnTitle: "Ime i prezime",
      },
      attribute: {
        columnTitle: "Svojstvo",
      },
      idNumber: {
        columnTitle: "Jedinstveni broj",
      },
      email: {
        columnTitle: "Email",
      },
      rank: {
        columnTitle: "Zvanje",
      },
      lastChanges: {
        columnTitle: "Poslednje promene",
      },
      actions: {
        columnTitle: "Akcije",
      },
      searchPlaceholder: "Pretraži osoblje...",
      newStuff: "Nova osoba",
    },
    firstName: "Ime",
    lastName: "Prezime",
    email: "Email",
    rank: "Zvanje",
    license: "Licenca",
    licenseNumber: "Broj licence",
    signature: "Potpis",
    chooseSignature: "Privuci ili <p>dodaj potpis</p>",
    newStuff: "Nova osoba",
    cancel: "Odustani",
    createStuff: "Napravi osobu",
    connectWithUser: "Poveži sa korisnikom",
    editStuff: "Detalji",
    editStuffTitle: "Osoba",
    deleteStuff: "Izbriši",
    firstNameNumberOfCharacters: "Ime ne sme imati više od 255 karaktera!",
    lastNameNumberOfCharacters: "Prezime ne sme imati više od 255 karaktera!",
    rankNumberOfCharacters: "Zvanje ne sme imati više od 255 karaktera!",
    emailNumberOfCharacters: "Email ne sme imati više od 255 karaktera!",
    licenseNumberNumberOfCharacters:
      "Broj licence ne sme imati više od 255 karaktera!",
    professionRequired: "Zvanje je obavezno!",
    emailRequired: "Email je obavezan!",
    userRequired: "Izaberi povezanog korisnika",
    licenseRequired: "Izaberi licencu",
    dropdownDefault: "Izaberi",
    newStuffPlaceholders: {
      firstName: "Jovan",
      lastName: "Jovic",
      email: "jovan.jovic@gmail.com",
      rank: "Zvanje",
      licenseNumber: "Broj licence",
    },
    tooltip: {
      noLicense: "Nema dostupne licence!",
      licencePreview: "Pregled licence",
    },
    modal: {
      previewLicense: {
        title: "Pregled licence za",
      },
      delete: {
        title: "Izbriši osobu",
        description: {
          1: "Da li želite da izbrišete označenu osobu?",
          2: "Ovu funkciju nije moguće revertovati!",
        },
      },
    },
  },
  sidebarNavigation: {
    home: "Početna",
    scanning: "Predefinisanje ispitivanja",
    standards: "Propisi i standardi",
    scanningInstruments: "Instrumenti za <br /> ispitivanje",
    scanningObjects: "Predmeti ispitivanja",
    scanningTemplate: "Šablon ispitivanja",
    scanningTemplatePlural: "Šabloni ispitivanja",
    findings: "Stručni nalaz",
    findingsPlural: "Stručni nalazi",
    findingsPreview: "Pregled stručnih <br /> nalaza",
    findingsPreviewLine: "Pregled stručnih nalaza",
    newFinding: "Novi nalaz",
    clients: "Klijenti",
    users: "Korisnici",
    staff: "Osoblje",
    privilege: "Privilegije",
    personal: "Personal",
  },
  findings: {
    title: "Stručni nalazi",
    findingTypes: "Tip nalaza",
    typeOfData: "Stručni nalaz",
    typeOfDataPlural: "stručne nalaze",
    table: {
      header: {
        columnTitle: "Nalazi",
      },
      createdAtUtc: {
        columnTitle: "Datum",
      },
      client: {
        columnTitle: "Klijent",
      },
      location: {
        columnTitle: "Lokacija",
      },
      staff: {
        columnTitle: "Odgovorno lice",
      },
      lastChanges: {
        columnTitle: "Poslednje promene",
      },
      status: {
        columnTitle: "Status",
      },
      findingType: {
        columnTitle: "Tip nalaza",
      },
      actions: {
        columnTitle: "Akcije",
      },
      number: {
        columnTitle: "Broj",
      },
      created: {
        columnTitle: "Kreiran",
      },
      searchPlaceholder: "Pretraži stručne nalaze...",
      newStuff: "Novi stručni nalaz",
    },
    status: {
      inProgress: "U izradi",
      completed: "Kompletiran",
      closed: "Zatvoren",
      cancelled: "Storniran",
    },
    positiveConclusion: "Iskoristiti pozitivni zaključak",
    negativeConclusion: "Iskoristiti negativni zaključak",
    notFilledMandatoryField:
      "Pitanje pod rednim brojem {{questionOrderNumber}} u sekciji '{{sectionName}}' u predmetu ispitivanja pod rednim brojem {{scanningSubjectOrderNumber}} nije popunjeno!",
    settings: "Opšti podaci",
    schema: "Skice radnog prostora",
    createFindingTitle: "Novi stručni nalaz",
    editFindingTitle: "Stručni nalaz",
    form: {
      client: {
        title: "Klijent",
        placeholder: "Izaberi klijenta",
        required: "Klijent je obavezan",
      },
      staff: {
        title: "Odgovorno lice",
        placeholder: "Izaberi odgovorno lice",
        required: "Odgovorno lice je obavezno",
      },
      location: {
        title: "Lokacija",
        placeholder: "Lokacija...",
        required: "Lokacija je obavezna",
      },
      facility: {
        title: "Objekat",
        placeholder: "Objekat...",
        required: "Objekat je obavezan",
      },
      description: {
        title: "Opis ispitivanja",
        placeholder: "Opis ispitivanja...",
        required: "Opis ispitivanja je obavezan",
      },
      dateOfChecking: {
        title: "Datum ispitivanja",
        placeholder: "Datum ispitivanja...",
        required: "Datum ispitivanja je obavezan",
      },
      dateOfFinding: {
        title: "Datum stručnog nalaza",
        placeholder: "Datum stručnog nalaza...",
        required: "Datum stručnog nalaza je obavezan",
      },
      note: {
        title: "Napomena",
        placeholder: "Napomena...",
      },
    },
    staffs: {
      title: "Izvršioci ispitivanja",
      addStaff: "Dodaj izvršioca",
      chooseStaff: "Izaberi izvršioca",
      attribute: "Svojstvo",
      newStaff: "Novi izvršioc",
      changeStaff: "Izmeni izvršioca",
    },
    scanning: {
      researchPassed: "Odgovara?",
      selectPlaceholder: "Izaberite odgovor...",
    },
    tooltip: {
      print: "Štampaj",
      edit: "Detalji",
    },
    modalButtons: {
      measuringInst: "Instrumenti za ispitivanje",
      standards: "Propisi i standardi",
    },
    findingAttendees: {
      tableTitle: "Prisutna lica",
      addButton: "Dodaj prisutno lice",
      newPerson: "Novo prisutno lice",
      editPerson: "Izmeni prisutno lice",
      deletePerson: "Izbriši prisutno lice",
      deleteDescriptionOne:
        "Da li zelite da izbrišete prisutno lice <0>{{name}}</0>?",
      deleteDescriptionTwo: "Ovu akciju nije moguće revertovati!",
      nameField: "Ime i prezime prisutnog lica",
      titleField: "Svojstvo",
      nameColumnTitle: "Ime",
      titleColumnTitle: "Svojstvo",
    },
    stepsRange: "{{currentStep}} od {{totalSteps}}",
    copy: "Kopiraj nalaz",
    export: "Štampaj",
    undoCancelled: "Aktiviraj nalaz",
    forwardToMail: "Prosledi na mail",
    cancelFindingModal: {
      title: "Storniraj Nalaz",
      description:
        "Da li ste sigurni da zelite da stornirate<0/> izabrani nalaz?",
      cancelFinding: "Storniraj",
      giveUp: "Odustani",
    },
    lastChanges: "Poslednje izmene",
    lastStateChanges: "Poslednje promene stanja",
    sendMailsModal: {
      title: "Pošalji nalaz",
      inputLabel: "Lista emailova",
      newMail: "Novi Email",
      mailPlaceholder: "Email...",
      emptyMails: "Obavezno je uneti barem jedan email!",
    },
    renameScanningSubjectModal: {
      placeholder: "Dodaj predmet",
      label: "Tip predmeta",
      specificName: "Specifično ime predmeta",
    },
    sectionNoteTitle: "Napomena sekcije",
    sectionNotePlaceholder: "Napomena",
    subjectNoteTitle: "Zaključak predmeta",
    subjectNotePlaceholder: "Zaključak",
    closeFinding: "Zatvori nalaz",
    closeFindingAndSend: "Zatvori nalaz i pošalji",
    closeFindingFirstDesc:
      "Da li ste sigurni da želite da zatvorite nalaz <0>#{{findingNumber}}</0>",
    closeFindingSecondDesc:
      "Pri zatvaranju nalaza, automatski se šalju emailovi izabranom klijentu sa njegove liste distribucionih emailova, odgovornom licu i listi distribucionih mejlova kompanije.",
    filter: {
      reset: "Resetuj filtere",
      findingId: "Broj",
      dateMin: "Datum od",
      dateMax: "Datum do",
      client: "Klijent",
      stuff: "Odgovorno lice",
      state: "Status",
      findingType: "Tip nalaza",
      placeholder: {
        default: "Izaberi",
        input: "Pretraži stručne nalaze...",
        client: "Izaberi klijenta",
        stuff: "Izaberi odgovorno lice",
        findingType: "Izaberi tip nalaza",
        date: "Datum",
      },
      stateArray: {
        all: "Izaberi sve",
        inPreperation: "U izradi",
        completed: "Kompletiran",
        closed: "Zatvoren",
        cancelled: "Storniran",
      },
    },
    imagePicker: {
      addSchema: "<p>Dodaj skicu<p>",
      modalTitle: "Skica radnog prostora",
    },
    copyFindingModal: {
      title: "Kopirajte nalaz",
      dateRequired: "Datum je obavezan!",
    },
  },
  imagePicker: {
    choose: "Privuci ili <p>dodaj sliku</p>",
    moreImages: "Moguće je dodati više slika",
    tooltip: {
      changeImage: "Izmeni sliku",
      deleteImage: "Izbriši sliku",
      showImage: "Prikaži sliku",
    },
  },
  findingTypes: {
    findingType: "Tip nalaza",
    workEquipment: { title: "Ispitivanje opreme za rad" },
    workEnvironment: { title: "Ispitivanje uslova radne okoline" },
    electricalInstallations: { title: "Ispitivanje električnih instalacija" },
    lightningProtection: { title: "Ispitivanje gromobranske instalacije" },
    antiPanicLightning: { title: "Ispitivanje protivpanične rasvete" },
    temporaryEletricalInstallations: {
      title: "Ispitivanje privremene elektro instalacije",
    },
    staticCharge: { title: "Ispitivanje statičkog naelektrisanja" },
  },
};
