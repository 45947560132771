import { getRequest, postRequest, putRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchCompanies = (payload) =>
  getRequest(apiEndpoints.companies.getClients, payload);

export const attemptCreateCompany = (payload) =>
  postRequest(apiEndpoints.companies.createCompany, payload);

export const attemptFetchSingleCompany = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.companies.getSingleCompany, {
      companyId: payload.companyId,
    })
  );

export const attemptEditSingleCompany = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.companies.editCompany, {
      companyId: payload.companyId,
    }),
    payload.data
  );

export const attemptCreateAPRImages = (payload) =>
  postRequest(apiEndpoints.companies.createAPRImages, payload);
export const attemptEditAPRImages = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.companies.editAPRImages, {
      companyId: payload.companyId,
    }),
    payload.data
  );
export const attemptEmailVerification = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.companies.emailVerification, {
      companyId: payload.companyId,
    }),
    payload?.data
  );

export const attemptUploadJsonSecret = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.companies.uploadJsonSecret, {
      companyId: payload.companyId,
    }),
    payload.data
  );

export const attemptValidateDriveGet = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.companies.validateDriveGet, {
      companyId: payload.companyId,
    })
  );

export const attemptValidateDrivePost = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.companies.validateDrivePost, {
      companyId: payload.companyId,
    }),
    payload?.data
  );
