import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import CopyIcon from "../../../Icon/Icons/CopyIcon";
import ExportIcon from "../../../Icon/Icons/ExportIcon";
import UndoIcon from "../../../Icon/Icons/UndoIcon";
import { TitleTypography } from "../../../Typography/Title/Title.styled";
import MailIcon from "../../../Icon/Icons/MailIcon";
import NewEyeIcon from "../../../Icon/Icons/NewEye";

export const NewFindingTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
  @media (max-width: 600px) {
    & ${TitleTypography} {
      white-space: nowrap;
    }
    margin-bottom: 24px;
  }
`;
export const EditFindingNumber = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.orangeBorder};
  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const CopyFindingIcon = styled(CopyIcon)`
  margin-left: 16px;
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;
export const ExportFindingIcon = styled(ExportIcon)`
  margin-left: 16px;
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;

export const EyeIcon = styled(NewEyeIcon)`
  margin-left: 16px;
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
  width: 23px;
  height: 23px;
`;
export const SendMailIcon = styled(MailIcon)`
  margin-left: 16px;
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const UndoFindingIcon = styled(UndoIcon)`
  margin-left: 16px;
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const FindingTitleDetails = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const FindingTypeContainer = styled(Box)`
  padding: 8px 16px 8px 16px;
  height: 36px;
  border-radius: 24px;
  gap: 4px;
  background: ${selectedTheme.colors.findingTypeColor};
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.findingTypeTextColor};
  max-width: fit-content;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: "salt" on;
`;

export const FindingDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;
