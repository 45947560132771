import React from "react";
import PropTypes from "prop-types";
import {
  AddButtonContainer,
  AddButtonIconContainer,
  AddButtonText,
} from "./AddButton.styled";
import PlusIcon from "../../Icon/Icons/PlusIcon";
import i18n from "../../../i18n";

const AddButton = (props) => {
  return (
    <AddButtonContainer onClick={props?.onClick} {...props}>
      {(props?.title?.length === 0 || !props?.$hideTitle) && (
        <AddButtonText>{props?.title}</AddButtonText>
      )}
      <AddButtonIconContainer>
        <PlusIcon />
      </AddButtonIconContainer>
    </AddButtonContainer>
  );
};

AddButton.propTypes = {
  title: PropTypes.string,
  $hideTitle: PropTypes.bool,
  onClick: PropTypes.func,
};
AddButton.defaultProps = {
  onClick: () => {},
  title: i18n.t("common.add"),
  $hideTitle: false,
};

export default AddButton;
