import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  attemptEmailVerification,
  attemptEditAPRImages,
  attemptEditSingleCompany,
  attemptFetchSingleCompany,
  attemptUploadJsonSecret,
  attemptValidateDriveGet,
  attemptValidateDrivePost,
} from "../../request/companiesRequest";
import {
  CREATE_APR_IMAGES_FETCH,
  EDIT_APR_IMAGES_FETCH,
  EDIT_COMPANY_FETCH,
  EMAIL_VERIFICATION_FETCH,
  MY_COMPANY_FETCH,
  SINGLE_COMPANY_FETCH,
  UPLOAD_JSON_SECRET_FETCH,
  VALIDATE_DRIVE_FETCH,
  VALIDATE_NEW_DRIVE_FETCH,
} from "../actions/companies/companiesActionConstants";
import {
  fetchCreateAprImagesError,
  // fetchCreateAprImagesSuccess,
  fetchEditAprImagesError,
  fetchEditAprImagesSuccess,
  fetchEditCompanyError,
  fetchEditCompanySuccess,
  fetchEmailVerificationError,
  fetchEmailVerificationSuccess,
  fetchMyCompanyError,
  fetchMyCompanySuccess,
  fetchSingleCompanyError,
  fetchSingleCompanySuccess,
  fetchUploadJsonSecretError,
  fetchUploadJsonSecretSuccess,
  fetchValidateDriveError,
  fetchValidateDriveSuccess,
  fetchValidateNewDriveSuccess,
  setMyCompany,
  setSingleCompany,
} from "../actions/companies/companiesActions";
import { selectMineCompanyId } from "../selectors/loginSelectors";

function* fetchSingleCompany({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleCompany, {
      companyId: payload.companyId,
    });
    yield put(
      setSingleCompany({
        ...data,
        aprImages: data?.aprImages?.map((singleImage) => ({
          imageUrl: singleImage?.imageUrl,
          orderNumber: singleImage?.orderNumber,
        })),
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchSingleCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleCompanyError(errorMessage));
  }
}

function* fetchMyCompany({ payload }) {
  try {
    const companyId = yield select(selectMineCompanyId);
    const { data } = yield call(attemptFetchSingleCompany, {
      companyId: companyId,
    });
    yield put(
      setMyCompany({
        ...data,
        aprImages: data?.aprImages?.map((singleImage) => ({
          imageUrl: singleImage?.imageUrl,
          orderNumber: singleImage?.orderNumber,
        })),
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchMyCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchMyCompanyError(errorMessage));
  }
}

function* fetchEditSingleCompany({ payload }) {
  try {
    const data = {
      name: payload?.companyData?.companyName,
      tin: String(payload?.companyData?.companyPIB),
      identificationNumber: String(payload?.companyData?.identificationNumber),
      address: payload?.companyData?.address,
      email: payload?.companyData?.email,
      bankAccount: payload?.companyData?.bankAccountNumber,
      phoneNumber: payload?.companyData?.phoneNumber,
      logoUrl: payload?.companyData?.logo,
      stampUrl: payload?.companyData?.stamp,
      directorSignatureUrl: payload?.companyData?.signature,
      licenseUrl: payload?.companyData?.license,
      distributionEmails: payload?.companyData?.distributionEmails.join(";"),
      userNameForMailService: payload?.companyData?.userName,
      passwordForMailService: payload?.companyData?.password
        ? payload?.companyData?.password
        : null,
      hostForMailService: payload?.companyData?.host,
      portForMailService:
        payload?.companyData?.port?.length !== 0
          ? payload?.companyData?.port
          : 0,
      enableSSlForMailService: payload?.companyData?.enableSSl,
      aprImages: payload?.companyData?.APR,
      automaticallySendEmails: payload?.companyData?.automaticallySendEmails,
      directorInfo: payload?.companyData?.companyDirector,
      automaticallyUploadToDrive:
        payload?.companyData?.automaticallyUploadToDrive,
      driveFolderId: payload?.companyData?.driveFolderId,
    };
    Object.keys(data).forEach(
      (item) =>
        (data[item] =
          typeof data[item] === "string"
            ? data[item].replace(/  +/g, " ").trim()
            : data[item])
    );
    yield call(attemptEditSingleCompany, {
      companyId: 1,
      data,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchEditCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditCompanyError(errorMessage));
  }
}

function* fetchCreateAprImages({ payload }) {
  try {
    const images = payload.aprImages;
    const formData = new FormData();
    formData.append("file", images[0]);
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateAprImagesError(errorMessage));
  }
}

function* fetchEditAprImages({ payload }) {
  try {
    yield call(attemptEditAPRImages, {
      companyId: payload.companyId,
      data: { ...payload.companyData },
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchEditAprImagesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditAprImagesError(errorMessage));
  }
}

function* fetchEmailverification({ payload }) {
  try {
    yield call(attemptEmailVerification, {
      companyId: payload.id,
      data: payload.data,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchEmailVerificationSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchEmailVerificationError(errorMessage));
  }
}

function* fetchUploadJsonSecret({ payload }) {
  try {
    const formData = new FormData();
    formData.append("file", payload.file);
    yield call(attemptUploadJsonSecret, {
      companyId: payload.id,
      data: formData,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess, payload.file.name);
    }
    yield put(fetchUploadJsonSecretSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchUploadJsonSecretError(errorMessage));
  }
}

function* fetchValidateDriveGet({ payload }) {
  try {
    yield call(attemptValidateDriveGet, {
      companyId: payload.id,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchValidateDriveSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchValidateDriveError(errorMessage));
  }
}

function* fetchValidateDrivePost({ payload }) {
  try {
    const formData = new FormData();
    formData.append("file", payload?.data?.file ?? null);
    formData.append("folderId", payload?.data?.folderId ?? null);
    yield call(attemptValidateDrivePost, {
      companyId: payload.id,
      data: formData,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchValidateNewDriveSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchValidateDriveError(errorMessage));
  }
}

export default function* clientsSaga() {
  yield all([
    takeLatest(SINGLE_COMPANY_FETCH, fetchSingleCompany),
    takeLatest(MY_COMPANY_FETCH, fetchMyCompany),
    takeLatest(EDIT_COMPANY_FETCH, fetchEditSingleCompany),
    takeLatest(CREATE_APR_IMAGES_FETCH, fetchCreateAprImages),
    takeLatest(EDIT_APR_IMAGES_FETCH, fetchEditAprImages),
    takeLatest(EMAIL_VERIFICATION_FETCH, fetchEmailverification),
    takeLatest(UPLOAD_JSON_SECRET_FETCH, fetchUploadJsonSecret),
    takeLatest(VALIDATE_DRIVE_FETCH, fetchValidateDriveGet),
    takeLatest(VALIDATE_NEW_DRIVE_FETCH, fetchValidateDrivePost),
  ]);
}
