import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { EXISTING_FACTORY_NUMBER } from "../../constants/backendErrorMessages";
import i18n from "../../i18n";
import {
  attemptFetchCreateMeasuringInstrument,
  attemptFetchDeleteMeasuringInstrument,
  attemptFetchEditMeasuringInstrument,
  attemptFetchMeasuringInstruments,
  attemptFetchSingleMeasuringInstrument,
} from "../../request/measuringInstrumentsRequest";
import {
  MEASURING_INSTRUMENTS_CREATE_FETCH,
  MEASURING_INSTRUMENTS_DELETE_FETCH,
  MEASURING_INSTRUMENTS_EDIT_FETCH,
  MEASURING_INSTRUMENTS_FETCH,
  SINGLE_MEASURING_INSTRUMENT_FETCH,
} from "../actions/measuringInstruments/measuringInstrumentsActionConstants";
import {
  fetchCreateMeasuringInstrumentError,
  fetchCreateMeasuringInstrumentSuccess,
  fetchDeleteMeasuringInstrumentError,
  fetchDeleteMeasuringInstrumentSuccess,
  fetchEditMeasuringInstrumentError,
  fetchEditMeasuringInstrumentSuccess,
  fetchMeasuringInstrumentsError,
  fetchMeasuringInstrumentsSuccess,
  fetchSingleMeasuringInstrumentError,
  fetchSingleMeasuringInstrumentSuccess,
  setMeasuringInstruments,
  setMeasuringInstrumentsTotal,
  setSingleMeasuringInstrument,
} from "../actions/measuringInstruments/measuringInstrumentsActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchMeasuringInstruments({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload);
    const data = yield call(attemptFetchMeasuringInstruments, queryObject);
    yield put(
      setMeasuringInstruments({
        data: data?.data?.measuringInstruments,
      })
    );
    yield put(setMeasuringInstrumentsTotal(data?.data?.count));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchMeasuringInstrumentsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchMeasuringInstrumentsError(errorMessage));
  }
}

function* fetchSingleMeasuringInstrument({ payload }) {
  try {
    const { data } = yield call(
      attemptFetchSingleMeasuringInstrument,
      payload.measuringInstrumentId
    );
    yield put(setSingleMeasuringInstrument(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchSingleMeasuringInstrumentSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleMeasuringInstrumentError(errorMessage));
  }
}
function* fetchCreateMeasuringInstrument({ payload }) {
  try {
    yield call(attemptFetchCreateMeasuringInstrument, payload.data);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchCreateMeasuringInstrumentSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    if (errorMessage?.trim() === EXISTING_FACTORY_NUMBER.trim()) {
      yield put(
        fetchCreateMeasuringInstrumentError(
          i18n.t("findings.errors.factoryNumberExisting")
        )
      );
    } else yield put(fetchCreateMeasuringInstrumentError(errorMessage));
  }
}
function* fetchEditMeasuringInstrument({ payload }) {
  try {
    yield call(attemptFetchEditMeasuringInstrument, {
      measuringInstrumentId: payload.measuringInstrumentId,
      data: payload.data,
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchEditMeasuringInstrumentSuccess());
  } catch (e) {
    console.log(e);
    let errorMessage = e?.response?.data?.message;

    if (payload.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    if (errorMessage?.trim() === EXISTING_FACTORY_NUMBER.trim()) {
      yield put(
        fetchEditMeasuringInstrumentError(
          i18n.t("findings.errors.factoryNumberExisting")
        )
      );
    } else yield put(fetchEditMeasuringInstrumentError(errorMessage));
  }
}

function* fetchDeleteMeasuringInstrument({ payload }) {
  try {
    yield call(attemptFetchDeleteMeasuringInstrument, {
      measuringInstrumentId: payload.measuringInstrumentId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteMeasuringInstrumentSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteMeasuringInstrumentError(errorMessage));
  }
}

export default function* measuringInstrumentsSaga() {
  yield all([
    takeLatest(MEASURING_INSTRUMENTS_FETCH, fetchMeasuringInstruments),
    takeLatest(
      SINGLE_MEASURING_INSTRUMENT_FETCH,
      fetchSingleMeasuringInstrument
    ),
    takeLatest(
      MEASURING_INSTRUMENTS_CREATE_FETCH,
      fetchCreateMeasuringInstrument
    ),
    takeLatest(MEASURING_INSTRUMENTS_EDIT_FETCH, fetchEditMeasuringInstrument),
    takeLatest(
      MEASURING_INSTRUMENTS_DELETE_FETCH,
      fetchDeleteMeasuringInstrument
    ),
  ]);
}
