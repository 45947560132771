import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Table from "../../../../Table/Table";
import TextRenderer from "./Renderers/TextRenderer/TextRenderer";
import templateStandardsTableConstants from "../../../../../constants/tableConstants/templateStandardsTableConstants";
import EmptyStandardsTable from "./EmptyStandardsTable/EmptyStandardsTable";

const TemplateStandardsTable = (props) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(templateStandardsTableConstants).map(
      (property) => ({
        field: t(templateStandardsTableConstants[property].i18key),
        propertyName: property,
        disableSort: true,
        style: {
          ...templateStandardsTableConstants[property]?.style,
          // whiteSpace: "nowrap",
          minWidth: 0,
        },
        renderer:
          templateStandardsTableConstants[property]?.renderer || TextRenderer,
        rendererProps: {
          handleRemoveEntity: props?.handleRemoveStandard,
        },
      })
    );
    if (props?.isReadOnly) return columnsObject.slice(0, -1);
    return columnsObject;
  }, [templateStandardsTableConstants, t, props?.handleRemoveStandard]);

  const mapDataFunction = useCallback((newData) => {
    console.log(newData);
    return newData?.map((singleData) => {
      let mappedDataObject = {
        name: singleData?.caption,
        delete: {
          id: singleData?.id,
          name: singleData?.caption,
        },
      };
      if (props?.isReadOnly) delete mappedDataObject?.delete;
      return mappedDataObject;
    });
  }, []);

  if (props?.data?.length === 0) return <EmptyStandardsTable />;

  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      hideHeader
      hidePaging
      custom
      customData={props?.data}
      mapDataFunction={mapDataFunction}
    />
  );
};

TemplateStandardsTable.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array,
  handleRemoveStandard: PropTypes.func,
  custom: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
TemplateStandardsTable.defaultProps = {
  data: [],
};

export default TemplateStandardsTable;
