import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FINDINGS_PAGE,
  SCANNING_OBJECTS_PAGE,
  USERS_PAGE,
} from "../../constants/pages";
import {
  MANAGER_ROLE,
  OPERATOR_ROLE,
  ADMIN_ROLE,
} from "../../constants/rolesConstants";
import Title from "../Typography/Title/Title";
import WithPermissions from "../WithPermissions/WithPermissions";
import {
  DashboardContainer,
  RedirectIcon,
  RedirectIconContainer,
  TablesContainer,
  TableTitle,
  TableTitleContainer,
} from "./DashboardContent.styled";
import FindingsTableCreated from "./FindingsTables/TableRecentlyCreated/FindingsTable";
import FindingsTableCompleted from "./FindingsTables/TableRecentlyCompleted/FindingsTable";
import ScanningObjectsTable from "./ScanningObjectsTable/ScanningObjectsTable";
import UsersTable from "./UsersTable/UsersTable";

const DashboardContent = () => {
  const { t } = useTranslation();

  return (
    <DashboardContainer>
      <Title>{t("dashboard.title")}</Title>
      <TablesContainer container rowSpacing={4} columnSpacing={{ xs: 7 }}>
        <WithPermissions roles={[MANAGER_ROLE, OPERATOR_ROLE]}>
          <Grid item xs={12} sm={6}>
            <TableTitleContainer>
              <TableTitle>{t("dashboard.tableSubtitle1")}</TableTitle>
              <RedirectIconContainer to={FINDINGS_PAGE}>
                <RedirectIcon />
              </RedirectIconContainer>
            </TableTitleContainer>
            <FindingsTableCreated />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableTitleContainer>
              <TableTitle>{t("dashboard.tableSubtitle2")}</TableTitle>
              <RedirectIconContainer to={FINDINGS_PAGE}>
                <RedirectIcon />
              </RedirectIconContainer>
            </TableTitleContainer>
            <FindingsTableCompleted />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableTitleContainer>
              <TableTitle>{t("dashboard.tableSubtitle3")}</TableTitle>
              <RedirectIconContainer to={SCANNING_OBJECTS_PAGE}>
                <RedirectIcon />
              </RedirectIconContainer>
            </TableTitleContainer>
            <ScanningObjectsTable />
          </Grid>
        </WithPermissions>
        <WithPermissions roles={[ADMIN_ROLE]}>
          <Grid item xs={12} sm={6}>
            <TableTitleContainer>
              <TableTitle>{t("dashboard.tableSubtitle4")}</TableTitle>
              <RedirectIconContainer to={USERS_PAGE}>
                <RedirectIcon />
              </RedirectIconContainer>
            </TableTitleContainer>
            <UsersTable />
          </Grid>
        </WithPermissions>
      </TablesContainer>
    </DashboardContainer>
  );
};

export default DashboardContent;
