import { createSelector } from "reselect";

const companiesSelector = (state) => state.companies;

export const selectCompanies = createSelector(
  companiesSelector,
  (state) => state.companies
);
export const selectTotalCompanies = createSelector(
  companiesSelector,
  (state) => state.totalCompanies
);
export const selectSingleCompany = createSelector(
  companiesSelector,
  (state) => state.singleCompany
);
export const selectMyCompany = createSelector(
  companiesSelector,
  (state) => state.myCompany
);
export const selectCompaniesData = createSelector(
  companiesSelector,
  (state) => state.companies.data
);
