import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  InputsContainer,
  SetStandardModalContainer,
  TextareaInput,
  CheckboxContainer,
  DeleteBtnContainer,
  DeleteBtn,
  DeleteBtnLabel,
  TextareaLabel,
  TextareaContainer,
} from "./SetStandardModal.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import Checkbox from "../../NewUser/Checkbox/Checkbox";
import TrashIcon from "../../Icon/Icons/TrashIcon";
import i18n from "../../../i18n";
import BackdropComponent from "../../MUI/BackdropComponent";
import {
  fetchCreateStandard,
  fetchDeleteStandard,
  fetchEditStandard,
} from "../../../store/actions/standards/standardsActions";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import useAuth from "../../../hooks/useAuth";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";
import { newStandardInitialValues } from "../../../initialValues/newStandardInitialValues";
import { useFormik } from "formik";
import newStandardValidationSchema from "../../../validations/newStandardValidationSchema";
import { selectFindingTypes } from "../../../store/selectors/findingsSelectors";

const SetStandardModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const findingTypes = useSelector(selectFindingTypes);

  const readOnlyModal = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    if (props?.isEditing)
      dispatch(
        fetchEditStandard({
          standardId: props?.standardId,
          data: {
            caption: values?.caption?.replace?.(/  +/g, " ")?.trim?.(),
            findingTypesIds: values?.findingTypes?.map?.((type) => type?.id),
            isDefault: values?.isDefault,
          },
          handleApiResponseSuccess: () =>
            handleApiResponseSuccess({
              inputValue: values?.caption,
              isDeleted: false,
            }),
          handleApiResponseError,
        })
      );
    else
      dispatch(
        fetchCreateStandard({
          data: {
            caption: values?.caption?.replace?.(/  +/g, " ")?.trim?.(),
            findingTypesIds: values?.findingTypes?.map?.((type) => type?.id),
            isDefault: values?.isDefault,
          },
          handleApiResponseSuccess: () =>
            handleApiResponseSuccess({
              inputValue: values?.caption,
              isDeleted: false,
            }),
          handleApiResponseError: handleApiResponseError,
        })
      );
  };

  const initialValues = useMemo(() => {
    return newStandardInitialValues(props?.prefilledData);
  }, [props?.prefilledData]);

  const formik = useFormik({
    initialValues,
    validationSchema: newStandardValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleDelete = () => {
    dispatch(
      toggleSetDeleteModal({
        title: t("standards.modal.delete.title"),
        description: {
          first: t("standards.modal.delete.description.1"),
          second: t("standards.modal.delete.description.2"),
        },
        onSubmit: handleDeleteStandard,
        rank: 2,
      })
    );
  };

  const handleApiResponseSuccess = (values) => {
    dispatch(closeModal());
    props?.refetchTable(values);
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleDeleteStandard = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteStandard({
        standardId: props?.standardId,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            inputValue: formik?.values?.caption,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  console.log(formik?.errors?.caption);
  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SetStandardModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <InputsContainer>
          <TextareaContainer>
            <TextareaLabel $required={formik?.values?.caption?.length === 0}>
              {t("standards.modal.txtLabel")}
            </TextareaLabel>
            <TextareaInput
              minRows={6}
              maxRows={6}
              onChange={(e) => formik?.setFieldValue?.("caption", e.target.value)}
              value={formik?.values?.caption}
              placeholder={t("standards.modal.txtareaPlaceholder")}
              disabled={readOnlyModal}
            />
            {formik?.errors?.caption && formik?.touched?.caption && (
              <ErrorMessage>{formik?.errors?.caption}</ErrorMessage>
            )}
          </TextareaContainer>
          <AutocompleteField
            options={findingTypes}
            label={t("standards.modal.chooseTypes")}
            title={t("standards.modal.findingTypes")}
            onChange={(query) => formik?.setFieldValue?.("findingTypes", query)}
            onDelete={(value) =>
              formik?.setFieldValue?.(
                "findingTypes",
                formik?.values?.findingTypes?.filter?.(
                  (item) => item?.id !== value?.id
                )
              )
            }
            isMultiple
            dropdownLabel={["caption"]}
            value={formik?.values?.findingTypes}
            disabled={readOnlyModal}
          />
          {formik?.errors?.findingTypes && formik?.touched?.findingTypes && (
            <ErrorMessage>
              {t("standards.modal.findingTypesRequired")}
            </ErrorMessage>
          )}
        </InputsContainer>
        <CheckboxContainer>
          <Checkbox
            label={t("standards.modal.isDefault")}
            name="isDefault"
            checked={formik?.values?.isDefault}
            onChange={(value) => formik?.setFieldValue?.("isDefault", value)}
            disabled={readOnlyModal}
          />
        </CheckboxContainer>
        {props?.isEditing === true && !readOnlyModal ? (
          <DeleteBtnContainer>
            <DeleteBtn onClick={handleDelete}>
              <TrashIcon />
              <DeleteBtnLabel>
                {t("standards.modal.deleteBtnLabel")}
              </DeleteBtnLabel>
            </DeleteBtn>
          </DeleteBtnContainer>
        ) : (
          <></>
        )}
        {!readOnlyModal && (
          <ButtonsContainer>
            <CancelButton onClick={handleCloseModal}>
              {t("common.cancel")}
            </CancelButton>
            <SubmitButton
              onClick={isSavingEnabled ? () => {} : formik?.handleSubmit}
              disabled={isSavingEnabled}
            >
              {t("common.save")}
            </SubmitButton>
          </ButtonsContainer>
        )}
      </SetStandardModalContainer>
    </>
  );
};

SetStandardModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  deleteProps: PropTypes.object,
  isEditing: PropTypes.bool,
  selectedTypes: PropTypes.any,
  tableRef: PropTypes.any,
  standardId: PropTypes.number,
  refetchTable: PropTypes.func,
  prefilledData: PropTypes.object,
};
SetStandardModal.defaultProps = {
  title: i18n.t("standards.modal.defaultTitle"),
  onSubmit: () => {},
};

export default SetStandardModal;
