import * as Yup from "yup";
import i18n from "../i18n";

export default (isEditing) =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(255, i18n.t("registerUser.firstNameNumberOfCharacters"))
      .required(i18n.t("registerUser.firstNameRequired")),
    lastName: Yup.string()
      .trim()
      .max(255, i18n.t("registerUser.lastNameNumberOfCharacters"))
      .required(i18n.t("registerUser.lastNameRequired")),
    createdDate: Yup.string(),
    userRole: Yup.array(),
    email: Yup.string()
      .trim()
      .email(i18n.t("clients.emailFormat"))
      .max(255, i18n.t("registerUser.emailNumberOfCharacters"))
      .required(i18n.t("registerUser.emailRequired")),
    password: isEditing
      ? Yup.string().max(255, i18n.t("registerUser.passwordNumberOfCharacters"))
      : Yup.string()
          .max(255, i18n.t("registerUser.passwordNumberOfCharacters"))
          .required(i18n.t("registerUser.blankPasswordError")),
    confirmPassword: isEditing
      ? Yup.string().max(255, i18n.t("registerUser.passwordNumberOfCharacters"))
      : Yup.string()
          .max(255, i18n.t("registerUser.passwordNumberOfCharacters"))
          .required(i18n.t("registerUser.confirmPasswordRequred"))
          .oneOf(
            [Yup.ref("password"), null],
            i18n.t("changePassword.differentPasswordsError")
          )
          .matches(
            /^(?=.{6,})/, //eslint-disable-line
            i18n.t(i18n.t("changePassword.passwordLength"))
          )
          .matches(
            /^(?=.*[0-9])/, //eslint-disable-line
            i18n.t(i18n.t("changePassword.numbersRequred"))
          )
          .matches(
            /^(?=.*[!@#\$%\^&\*])/, //eslint-disable-line
            i18n.t(i18n.t("changePassword.specialCharactersRequred"))
          )
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])/, //eslint-disable-line
            i18n.t(i18n.t("changePassword.lettersRequred"))
          ),
  });
