import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { useTranslation } from "react-i18next";
import { selectStuffsData } from "../../../../../../store/selectors/stuffsSelector";
import {
  selectSingleFindingSettings,
  selectSingleFindingSettingsOld,
} from "../../../../../../store/selectors/findingsSelectors";
import AutocompleteField from "../../../../../Field/AutocompleteField/AutocompleteField";
import { statusTableConstants } from "../../../../../../constants/findingStatusConstants";

const StaffField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const staff = useSelector(selectStuffsData);
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  const findingSettings = useSelector(selectSingleFindingSettings);

  const stuffName = useMemo(() => {
    return `${findingSettings?.staff?.firstName} ${findingSettings?.staff?.lastName}`;
  }, [findingSettings]);

  const isDeletedStaff = useMemo(() => {
    return (
      !staff?.some?.(
        (singleStaff) => singleStaff?.id === findingSettings?.staffId
      ) &&
      props?.stateId !== statusTableConstants.inProgress.id &&
      formik?.values?.[findingFormikConstants.staffId]?.value != null
    );
  }, [findingSettings, staff, props?.stateId, props?.formik]);

  return (
    <AutocompleteField
      options={staff}
      label={isDeletedStaff ? stuffName : t("findings.form.staff.placeholder")}
      disabled={props?.disabled}
      title={t("findings.form.staff.title")}
      required={formik?.values?.[findingFormikConstants.staffId].value == null}
      onChange={(value) =>
        formik?.setFieldValue?.(findingFormikConstants.staffId, {
          edited: findingSettingsOld?.staffId?.value !== value,
          value: value?.id,
        })
      }
      dropdownLabel={["firstName", "lastName"]}
      value={staff.find(
        (staff) =>
          staff?.id == formik?.values?.[findingFormikConstants.staffId].value
      )}
      error={
        formik?.touched?.[findingFormikConstants.staffId]?.value &&
        formik?.errors?.[findingFormikConstants.staffId]?.value
      }
    />
  );
};

StaffField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  stateId: PropTypes.number,
};

export default StaffField;
