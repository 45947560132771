import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  SingleInputContainer,
  SingleInputTitle,
} from "../SetMeasuringInstrumentModal.styled";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { Trans, useTranslation } from "react-i18next";
import { CalibrationCertificatePicker } from "./CalibrationCertificate.styled";

const CalibrationCertificate = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();

  const hasImage = useMemo(
    () =>
      formik?.values?.[
        newMeasuringInstrumentFormikConstants.calibrationCertificateUrl
      ] !== "",
    [formik?.values]
  );
  return (
    <SingleInputContainer>
      <SingleInputTitle>
        {t("measuringInstruments.modal.fields.calibrationCertificate.label")}
      </SingleInputTitle>
      <CalibrationCertificatePicker
        smallerButtons
        width="100%"
        height={hasImage ? 420 : 120}
        minWidth="100%"
        minHeight={hasImage ? 420 : 120}
        $hasImage={hasImage}
        image={
          formik?.values?.[
            newMeasuringInstrumentFormikConstants.calibrationCertificateUrl
          ]
        }
        setImage={(newImage) =>
          formik?.setFieldValue?.("calibrationCertificateUrl", newImage)
        }
        descriptionText={
          <Trans i18nKey="measuringInstruments.modal.chooseCalibrationCertificate" />
        }
        disablePreview
      />
      {formik?.errors?.[
        newMeasuringInstrumentFormikConstants.calibrationCertificateUrl
      ] &&
        formik?.touched?.[
          newMeasuringInstrumentFormikConstants.calibrationCertificateUrl
        ] && (
          <ErrorMessage>
            {
              formik?.errors?.[
                newMeasuringInstrumentFormikConstants.calibrationCertificateUrl
              ]
            }
          </ErrorMessage>
        )}
    </SingleInputContainer>
  );
};

CalibrationCertificate.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default CalibrationCertificate;
