import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ScanningObjectNameInput from "./ScanningObjectNameInput/ScanningObjectNameInput";
import ScanningObjectPicker from "./ScanningObjectPicker/ScanningObjectPicker";
import {
  NewScanningObjectContentContainer,
  TitleButtonsContainer,
} from "./NewScanningObjectContent.styled";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  EDIT_SCANNING_OBJECTS_PAGE,
  SCANNING_OBJECTS_PAGE,
} from "../../../constants/pages";
import {
  clearSingleScanningObject,
  fetchDeleteScanningObject,
  setScanningObjectsChanged,
  fetchCopyScanningObject,
  setSingleScanningObjectType,
} from "../../../store/actions/scanningObjects/scanningObjectsActions";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import { useFormik } from "formik";
import {
  scanningSubjectFormikConstants,
  scanningSubjectInitialValues,
} from "../../../initialValues/scanningSubjectInitialValues";
import scanningSubjectValidationSchema from "../../../validations/scanningSubjectValidationSchema";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useIsMobile from "../../../hooks/useIsMobile";
import ActionButtons from "../../Buttons/ActionsButtons/ActionButtons";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import {
  selectIsScanningObjectChanged,
  selectScanningObjectType,
  selectSelectedChecklistTemplate,
} from "../../../store/selectors/scanningObjectsSelectors";
import { useMemo } from "react";
import NewScanningObjectTitle from "./NewScanningObjectTitle/NewScanningObjectTitle";

const NewScanningObjectContent = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const findingType = useSelector(selectScanningObjectType);
  const [isPrefilledDataLoaded, setIsPrefilledDataLoaded] = useState(false);
  const scanningObjectRef = useRef(null);
  const templateRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const isScanningObjectChanged = useSelector(selectIsScanningObjectChanged);
  const templateData = useSelector(selectSelectedChecklistTemplate);

  useEffect(() => {
    if (
      findingType?.id !== history?.location?.state?.findingType?.id &&
      history?.location?.state?.findingType != null
    ) {
      dispatch(
        setSingleScanningObjectType(history?.location?.state?.findingType)
      );
    }
    if (!props?.isEditing && history?.location?.state?.findingType == null) {
      history.replace(SCANNING_OBJECTS_PAGE);
    }
  }, [history.location.state]);

  useEffect(() => {
    return () => {
      dispatch(clearSingleScanningObject());
      dispatch(setScanningObjectsChanged(false));
    };
  }, []);

  useEffect(() => {
    if (props?.prefilledData?.name?.length > 0 && !isPrefilledDataLoaded) {
      // nameRef?.setName(props?.prefilledData?.name);
      if (props?.prefilledData?.checklistTemplates?.length > 0)
        scanningObjectRef?.current?.setIsTemplateChosen(true);
      setIsPrefilledDataLoaded(true);
    }
  }, [props?.prefilledData, scanningObjectRef]);

  const testForDuplicate = () => {
    let dupliciate = false;

    templateData?.sections?.map((section, index1) =>
      section?.questions?.map((question, index2) => {
        templateData?.sections[index1]?.questions?.map(
          (questionTwo, index3) => {
            console.log(question, questionTwo);
            if (index2 === index3) return;
            if (
              question?.questionName === questionTwo?.questionName &&
              question?.descriptionOne === "" &&
              questionTwo?.descriptionOne === ""
            ) {
              dupliciate = true;
            }
          }
        );
      })
    );
    return dupliciate;
  };

  const handleSubmitForm = () => {
    if (testForDuplicate()) {
      makeErrorToastMessage(
        {
          title: t("common.error"),
          description: t("scanningObjects.duplicateError"),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      return;
    }
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    dispatch(
      props?.dispatchFunction({
        type: findingType,
        name: formik?.values?.[scanningSubjectFormikConstants.name]
          ?.replace(/  +/g, " ")
          .trim(),
        checklistTemplateId: templateRef?.current?.checklistTemplateId,
        measuringInstruments:
          templateRef?.current?.selectedMeasuringInstruments,
        standardIds: templateRef?.current?.selectedStandards,
        sections: templateRef?.current?.sections,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: scanningSubjectInitialValues(props?.prefilledData),
    validationSchema: scanningSubjectValidationSchema,
    onSubmit: handleSubmitForm,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleApiResponseSuccess = (payload) => {
    dispatch(closeModal());
    if (payload?.isDeleted)
      history.replace({
        pathname: SCANNING_OBJECTS_PAGE,
        state: {
          name: formik?.values?.[scanningSubjectFormikConstants.name],
          isDeleted: payload?.isDeleted || false,
        },
      });
    else {
      const titleI18Key = props?.isEditing
        ? "toast.success.editedTitle"
        : "toast.success.createdTitle";
      const descriptionI18Key = props?.isEditing
        ? "toast.success.editedDescription"
        : "toast.success.createdDescription";
      makeToastMessage(
        {
          title: t(titleI18Key, {
            typeOfData: t("scanningObjects.typeOfData"),
          }),
          description: t(descriptionI18Key, {
            name: formik?.values?.[scanningSubjectFormikConstants.name],
            typeOfData: t("scanningObjects.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      if (!props?.isEditing)
        history.replace(
          replaceInRoute(EDIT_SCANNING_OBJECTS_PAGE, {
            scanningObjectId: payload,
          })
        );
    }
  };

  const handleApiResponseSuccessCopy = (payload) => {
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t("toast.success.copyTitle", {
          typeOfData: t("scanningObjects.typeOfData"),
        }),
        description: t("toast.success.copyDescription", {
          name: formik?.values?.[scanningSubjectFormikConstants.name],
          typeOfData: t("scanningObjects.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    history.replace({
      pathname: replaceInRoute(EDIT_SCANNING_OBJECTS_PAGE, {
        scanningObjectId: payload,
      }),
      state: {
        isCopy: true,
      },
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleDeleteScanningObject = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteScanningObject({
        scanningObjectId: routeMatch.params?.scanningObjectId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleCopyScanningObject = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchCopyScanningObject({
        scanningObjectId: routeMatch.params?.scanningObjectId,
        handleApiResponseSuccess: handleApiResponseSuccessCopy,
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty || isScanningObjectChanged) {
      return false;
    }
    return true;
  }, [formik?.dirty, isScanningObjectChanged]);

  return (
    <NewScanningObjectContentContainer>
      <TitleButtonsContainer>
        <NewScanningObjectTitle
          noMargin
          title={props?.title}
          isEditing={props?.isEditing}
          isLoading={props?.isLoading}
          onSubmit={handleDeleteScanningObject}
          handleCopy={handleCopyScanningObject}
          disableActions={props?.readOnly}
          dispatch={dispatch}
          isScanningObjectChanged={isScanningObjectChanged}
        />
        {!props?.readOnly && !isMobile && (
          <ActionButtons
            handleCancel={handleCancel}
            handleSave={formik?.submitForm}
            disabled={isSavingEnabled}
          />
        )}
      </TitleButtonsContainer>
      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {/* <FindingTypesSelect
            onChange={handleChangeSubjectType}
            value={findingType}
          /> */}
          <ScanningObjectNameInput
            formik={formik}
            isReadOnly={props?.readOnly}
          />
          <ScanningObjectPicker
            ref={{ scanningObjectRef, templateRef }}
            isEditing={props?.isEditing}
            isReadOnly={props?.readOnly}
          />
          {/* {!props?.readOnly && (
            <ButtonsAreaContainer>
              <RequiredFieldsText>
                {t("common.requiredFieldsText")}
              </RequiredFieldsText>
              <ButtonsContainer>
                <PrimaryButton onClick={handleCancel}>
                  {t("common.cancel")}
                </PrimaryButton>
                <PrimaryButton variant="contained" onClick={handleSubmitForm}>
                  {t("common.save")}
                </PrimaryButton>
              </ButtonsContainer>
            </ButtonsAreaContainer>
          )} */}
          {!props?.readOnly && isMobile && (
            <ActionButtons
              handleCancel={handleCancel}
              handleSave={formik?.submitForm}
              disabled={isSavingEnabled}
            />
          )}
        </>
      )}
    </NewScanningObjectContentContainer>
  );
};

NewScanningObjectContent.propTypes = {
  scanningObjectName: PropTypes.string,
  prefilledData: PropTypes.object,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
  dispatchFunction: PropTypes.func,
  readOnly: PropTypes.bool,
};

NewScanningObjectContent.defaultProps = {
  scanningObjectName: "",
  isEditing: false,
};

export default NewScanningObjectContent;
