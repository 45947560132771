import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  DeleteBtn,
  DeleteBtnContainer,
  DeleteBtnLabel,
  InputsContainer,
  SetMeasuringInstrumentModalContainer,
} from "./SetMeasuringInstrumentModal.styled";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import newMeasuringInstrumentInitialValues, {
  newMeasuringInstrumentFormikConstants,
} from "../../../initialValues/newMeasuringInstrumentInitialValues";
import { useFormik } from "formik";
import NameField from "./NameField/NameField";
import FactoryNumberField from "./FactoryNumberField/FactoryNumberField";
import ManufacturerField from "./ManufacturerField/ManufacturerField";
import BenchmarkingField from "./BenchmarkingField/BenchmarkingField";
import newMeasuringInstrumentValidationSchema from "../../../validations/newMeasuringInstrumentValidationSchema";
import {
  fetchCreateMeasuringInstrument,
  fetchDeleteMeasuringInstrument,
  fetchEditMeasuringInstrument,
  setMeasuringInstrumentsErrorMessage,
} from "../../../store/actions/measuringInstruments/measuringInstrumentsActions";
import TrashIcon from "../../Icon/Icons/TrashIcon";
import BackdropComponent from "../../MUI/BackdropComponent";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import DateField from "./DateField/DateField";
import {
  constructDate,
  convertDateToISO,
} from "../../../util/helpers/dateHelpers";
import FindingTypesField from "./FindingTypesField/FindingTypesField";
import { selectFindingTypes } from "../../../store/selectors/findingsSelectors";
import CalibrationCertificate from "./CalibrationCertificate/CalibrationCertificate";

const SetMeasuringInstrumentModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const findingTypes = useSelector(selectFindingTypes);

  const readOnlyModal = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(setMeasuringInstrumentsErrorMessage(""));
  }, []);

  const handleSubmit = (values) => {
    console.log(values);
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    if (props?.isEditing)
      dispatch(
        fetchEditMeasuringInstrument({
          measuringInstrumentId: props?.measuringInstrumentId,
          data: {
            findingTypesIds: values?.findingTypes?.map?.((type) => type?.id),
            name: values?.name?.replace(/  +/g, " ").trim(),
            serialNumber: values?.factoryNumber,
            manufacturer: values?.manufacturer,
            calibrationNumber: values?.benchmarking,
            calibrationCertificateUrl: values?.calibrationCertificateUrl,
            validUntil: values?.date
              ? convertDateToISO(constructDate(values?.date))
              : null,
          },
          handleApiResponseSuccess: () =>
            handleApiResponseSuccess(values?.name),
          handleApiResponseError,
        })
      );
    else
      dispatch(
        fetchCreateMeasuringInstrument({
          data: {
            findingTypesIds: values?.findingTypes?.map?.((type) => type?.id),
            name: values?.name?.replace(/  +/g, " ").trim(),
            serialNumber: values?.factoryNumber,
            manufacturer: values?.manufacturer,
            calibrationNumber: values?.benchmarking,
            calibrationCertificateUrl: values?.calibrationCertificateUrl,
            validUntil: values?.date
              ? convertDateToISO(constructDate(values?.date))
              : null,
          },
          handleApiResponseSuccess: () =>
            handleApiResponseSuccess(values?.name),
          handleApiResponseError,
        })
      );
  };

  const initialValues = useMemo(() => {
    return newMeasuringInstrumentInitialValues(props?.prefilledData);
  }, [props?.prefilledData]);

  const formik = useFormik({
    initialValues,
    validationSchema: newMeasuringInstrumentValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleApiResponseSuccess = (name) => {
    dispatch(closeModal());
    props?.refetchTable(name);
  };

  const handleDelete = () => {
    dispatch(
      toggleSetDeleteModal({
        title: t("measuringInstruments.modal.delete.title"),
        description: {
          first: t("measuringInstruments.modal.delete.description.1"),
          second: t("measuringInstruments.modal.delete.description.2"),
        },
        onSubmit: handleDeleteInstrument,
        openedThroughEdit: true,
        rank: 2,
      })
    );
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccessDelete = () => {
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t("toast.success.deletedTitle", {
          typeOfData: t("measuringInstruments.typeOfData"),
        }),
        description: t("toast.success.deletedDescription", {
          name: formik?.values?.[newMeasuringInstrumentFormikConstants.name],
          typeOfData: t("measuringInstruments.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.tableRef?.current?.forceRefetch?.();
  };

  const handleDeleteInstrument = () => {
    dispatch(toggleSetLoadingModal({ rank: 3 }));
    dispatch(
      fetchDeleteMeasuringInstrument({
        measuringInstrumentId: props?.measuringInstrumentId,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  console.log(
    formik?.values?.[
      newMeasuringInstrumentFormikConstants.calibrationCertificateUrl
    ]
  );
  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SetMeasuringInstrumentModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <InputsContainer>
          <NameField formik={formik} isReadOnly={readOnlyModal} />
          <FindingTypesField
            formik={formik}
            isReadOnly={readOnlyModal}
            data={findingTypes}
          />
          <FactoryNumberField formik={formik} isReadOnly={readOnlyModal} />
          <ManufacturerField formik={formik} isReadOnly={readOnlyModal} />
          <BenchmarkingField formik={formik} isReadOnly={readOnlyModal} />
          <CalibrationCertificate formik={formik} isReadOnly={readOnlyModal} />
          <DateField formik={formik} isReadOnly={readOnlyModal} />
        </InputsContainer>
        {props?.isEditing === true && !readOnlyModal ? (
          <DeleteBtnContainer>
            <DeleteBtn onClick={handleDelete}>
              <TrashIcon></TrashIcon>
              <DeleteBtnLabel>
                {t("standards.modal.deleteBtnLabel")}
              </DeleteBtnLabel>
            </DeleteBtn>
          </DeleteBtnContainer>
        ) : (
          <></>
        )}
        {!readOnlyModal && (
          <ButtonsContainer>
            <CancelButton onClick={handleCloseModal}>
              {t("common.cancel")}
            </CancelButton>
            <SubmitButton
              onClick={isSavingEnabled ? () => {} : formik?.handleSubmit}
              disabled={isSavingEnabled}
            >
              {t("common.save")}
            </SubmitButton>
          </ButtonsContainer>
        )}
      </SetMeasuringInstrumentModalContainer>
    </>
  );
};

SetMeasuringInstrumentModal.propTypes = {
  rank: PropTypes.number,
  title: PropTypes.string,
  prefilledData: PropTypes.any,
  isEditing: PropTypes.bool,
  tableRef: PropTypes.any,
  measuringInstrumentId: PropTypes.number,
  refetchTable: PropTypes.func,
};

export default SetMeasuringInstrumentModal;
