import {
  FINDING_TYPE_ID,
  NAME_KEY,
  NOT_SORTED,
  PAGE_NUM,
  PAGE_SIZE,
  SORT_COLUMN,
  SORT_DIR,
} from "../../constants/queryConstants";
import questionTypeConstants from "../../constants/questionTypeConstants";
import { convertDateToISO } from "../../util/helpers/dateHelpers";

export const mapFetchAllFunction = (payload, nameKeys = [NAME_KEY]) => {
  const queryObject = new URLSearchParams();
  let pageSize = payload.size || "10";
  let pageNumber = payload?.searchValue?.length > 0 ? 1 : payload.page;
  queryObject.set(PAGE_SIZE, pageSize);
  queryObject.set(PAGE_NUM, pageNumber);
  if (payload?.searchValue?.length > 0) {
    nameKeys.map((key) => queryObject.set(key, payload.searchValue));
  }
  if (payload?.findingTypeId != null) {
    queryObject.set(FINDING_TYPE_ID, payload?.findingTypeId);
  }
  if (payload?.sorting && payload?.sorting?.sortDirection !== NOT_SORTED) {
    queryObject.set(SORT_COLUMN, payload.sorting.column);
    queryObject.set(SORT_DIR, payload.sorting.sortDirection);
  }
  return queryObject;
};

export const mapFetchFindingsFunction = (queryObject, payload) => {
  if (payload?.client) queryObject.set("ClientId", payload.client);
  if (payload?.stuff) queryObject.set("StaffId", payload.stuff);
  if (payload?.findingType)
    queryObject.set("FindingTypeId", payload?.findingType);
  if (payload?.date?.start)
    queryObject.set("MinDate", convertDateToISO(payload?.date?.start));
  queryObject.set("Intersect", true);
  if (payload?.date?.end)
    queryObject.set("MaxDate", convertDateToISO(payload?.date?.end));

  if (payload?.searchNumber)
    queryObject.set("FindingNumber", payload?.searchNumber);
  if (payload?.status?.length > 0) {
    payload?.status?.map((id) => queryObject.append("Status", id));
  }
};

export const mapFetchScanningSubjectFunction = (data, findingType) => {
  return {
    ...data,
    type: findingType,
    checklistTemplates: data?.checklistTemplates?.map((singleTemplate) => ({
      createdAtUtc: singleTemplate?.createdAtUtc,
      updatedAtUtc: singleTemplate?.updatedAtUtc,
      createdByUser:
        singleTemplate?.createdByUser?.firstName +
        " " +
        singleTemplate?.createdByUser?.lastName,
      id: singleTemplate?.id,
      isDefault: singleTemplate?.isDefault,
      positiveConclusionTemplate: singleTemplate?.positiveConclusionTemplate,
      negativeConclusionTemplate: singleTemplate?.negativeConclusionTemplate,
      measuringInstruments: singleTemplate?.measuringInstruments,
      standards: singleTemplate?.standards,
      sections: singleTemplate?.sections?.map((singleSection) => ({
        id: singleSection?.id,
        title: singleSection?.title,
        questions: singleSection?.items?.map((singleQuestion) => {
          let questionObject = {
            id: singleQuestion?.id,
            itemTypeId: singleQuestion?.itemType,
            questionName: singleQuestion?.question,
            questionType: singleQuestion?.itemType,
            descriptionOne: singleQuestion?.descriptionOne,
            descriptionTwo: singleQuestion?.descriptionTwo,
            isRequired: singleQuestion?.isMandatory,
            hasResearchPassed: singleQuestion?.isRateInRange,
          };
          if (singleQuestion?.itemType === questionTypeConstants.TEXT) {
            questionObject = {
              ...questionObject,
              textTypeInputs: {
                defaultAnswer: singleQuestion?.defaultAnswer,
                note: singleQuestion?.note,
              },
            };
          }
          if (singleQuestion?.itemType === questionTypeConstants.TABLE) {
            questionObject = {
              ...questionObject,
              tableTypeInputs: {
                columns: singleQuestion?.columns?.map((column) => {
                  return { ...column };
                }),
              },
            };
          }
          if (singleQuestion?.itemType === questionTypeConstants.SELECT) {
            questionObject = {
              ...questionObject,
              selectTypeInputs: singleQuestion?.options?.reduce(
                (prevValue, currentValue, currentIndex) => {
                  let newAnswers = [
                    ...prevValue?.possibleAnswers,
                    {
                      answer: currentValue?.answer,
                      questionId: currentIndex + 1,
                    },
                  ];
                  let isSelected = currentValue?.isSelected;
                  if (prevValue?.defaultAnswer) {
                    return {
                      possibleAnswers: [...newAnswers],
                      defaultAnswerIndex: prevValue?.defaultAnswerIndex,
                    };
                  } else {
                    if (isSelected) {
                      return {
                        possibleAnswers: [...newAnswers],
                        defaultAnswerIndex: currentIndex,
                      };
                    } else {
                      return {
                        ...prevValue,
                        possibleAnswers: [...newAnswers],
                      };
                    }
                  }
                },
                {
                  possibleAnswers: [],
                }
              ),
            };
          }
          return questionObject;
        }),
      })),
    })),
  };
};
