import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../TextInputField/TextInputField";
import Tooltip from "../../../Tooltip/Tooltip";

const CompanyPhoneNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={
        formik?.values?.phoneNumber?.length > 41
          ? formik?.values?.phoneNumber
          : ""
      }
    >
      <TextInputField
        name="phoneNumber"
        label={t("companySettings.phoneNumber")}
        value={formik?.values?.phoneNumber}
        number
        onChange={(event) => {
          props?.formik?.setFieldValue?.("phoneNumber", event.target.value);
        }}
        error={formik?.touched?.phoneNumber && formik?.errors?.phoneNumber}
        helperText={formik?.touched?.phoneNumber && formik?.errors?.phoneNumber}
        requiredField={props?.isRequired}
        onInput={(e) => {
          e.target.value =
            e.target.value[0] === "0" && e.target.value.length > 1
              ? "0" +
                String(
                  Math.max(0, parseInt(e.target.value)).toString().slice(0, 14)
                )
              : Math.max(0, parseInt(e.target.value)).toString().slice(0, 14);
        }}
      />
    </Tooltip>
  );
};

CompanyPhoneNumberField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default CompanyPhoneNumberField;
