import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  EntityInputsLabel,
  ManualTableContainer,
  TableTitle,
  TitleButtonContainer,
} from "./ManualTable.styled";
// import EntityPicker from "./EntityPicker/EntityPicker";
import EntityCustomTable from "./EntityCustomTable/EntityCustomTable";
import AddButton from "../Button/AddButton/AddButton";

const ManualTable = (props) => {
  const [selectedData, setSelectedData] = useState(props?.selectedData);

  useEffect(() => {
    if (props?.setSelectedData) props?.setSelectedData(selectedData);
  }, [selectedData]);

  // const handleAddEntity = (entity) => {
  //   if (
  //     !props?.availableMultipleEntities &&
  //     selectedData.find((singleEntity) => singleEntity?.id === entity?.id)
  //   )
  //     return;
  //   if (props?.customAddFunction) props?.customAddFunction(entity);
  //   else setSelectedData((prevData) => [...prevData, entity]);
  //   if (props?.onAddEntity) props?.onAddEntity(entity);
  //   if (props?.onChangeEntity) props?.onChangeEntity?.(entity);
  // };

  const handleRemoveEntity = useCallback(
    (entity, entityIndex = null) => {
      if (entityIndex == null) {
        let indexOfEntity = selectedData?.findIndex?.(
          (singleEntity) => singleEntity?.id === entity?.id
        );
        if (indexOfEntity !== -1) {
          if (props?.customRemoveFunction)
            props?.customRemoveFunction(indexOfEntity);
          else
            setSelectedData((prevData) => {
              let newData = [...prevData];
              newData?.splice?.(indexOfEntity, 1);
              return [...newData];
            });
        }
      } else {
        if (props?.customRemoveFunction)
          props?.customRemoveFunction(entityIndex);
        else
          setSelectedData((prevData) => {
            let newData = [...prevData];
            newData?.splice?.(entityIndex, 1);
            return [...newData];
          });
      }
      if (props?.onRemoveEntity) props?.onRemoveEntity(entity);
      if (props?.onChangeEntity) props?.onChangeEntity?.(entity);
    },
    [selectedData]
  );

  //Editing table columns to pass remove entity from table function
  const tableColumns = useMemo(() => {
    let columnsToReturn = props?.tableColumns?.map((singleColumn) => {
      // if (props?.disabled && props?.tableColumns?.length - 1 === index) return;
      return {
        ...singleColumn,
        rendererProps: {
          ...singleColumn?.rendererProps,
          handleRemoveEntity,
        },
      };
    });
    if (props?.disabled) columnsToReturn?.slice?.(-1);
    return columnsToReturn;
  }, [handleRemoveEntity, props?.disabled]);

  return (
    <ManualTableContainer $noActions={props?.noActions}>
      <TitleButtonContainer>
        <TableTitle>{props?.title}</TableTitle>
        {!props?.disabled && (
          <AddButton
            title={props?.buttonTitle}
            onClick={props?.handleClickAddButton}
          />
        )}
      </TitleButtonContainer>
      {!props?.disabled && (
        <>
          <EntityInputsLabel>{props?.addEntityLabel}</EntityInputsLabel>
          {/* <EntityPicker
            fetchEntitiesDispatchFunction={props?.fetchEntitiesDispatchFunction}
            entityDataSelector={props?.entityDataSelector}
            selectPlaceholder={props?.selectPlaceholder}
            namePropertyOfEntity={props?.namePropertyOfEntity}
            availableMultipleEntities={props?.availableMultipleEntities}
            handleAddEntity={handleAddEntity}
            data={selectedData}
            fetchedData={props?.fetchedData}
            dropdownLabel={props?.dropdownLabel}
          /> */}
        </>
      )}

      <EntityCustomTable
        data={selectedData}
        tableColumns={tableColumns}
        mapDataFunction={props?.mapDataFunction}
      />
    </ManualTableContainer>
  );
};

ManualTable.displayName = "ManualTable";

ManualTable.propTypes = {
  children: PropTypes.node,
  selectedData: PropTypes.array,
  title: PropTypes.string,
  fetchedData: PropTypes.bool,
  addEntityLabel: PropTypes.string,
  fetchEntitiesDispatchFunction: PropTypes.func,
  setSelectedData: PropTypes.func,
  onAddEntity: PropTypes.func,
  onChangeEntity: PropTypes.func,
  onRemoveEntity: PropTypes.func,
  mapDataFunction: PropTypes.func,
  entityDataSelector: PropTypes.any,
  selectPlaceholder: PropTypes.string,
  namePropertyOfEntity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  tableColumns: PropTypes.array,
  availableMultipleEntities: PropTypes.bool,
  disabled: PropTypes.bool,
  noActions: PropTypes.bool,
  customAddFunction: PropTypes.func,
  customRemoveFunction: PropTypes.func,
  dropdownLabel: PropTypes.array,
  buttonTitle: PropTypes.string,
  handleClickAddButton: PropTypes.func,
};
ManualTable.defaultProps = {
  selectedData: [],
};

export default ManualTable;
