import React from "react";
import { Router } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import { ToastContainer } from "react-toastify";
import history from "./store/utils/history";
import AppRoutes from "./AppRoutes";
import { StyledEngineProvider } from "@mui/material";
import Modal from "./components/Modals/Modal";
import Header from "./components/Header/Header";
import { ReactComponent as UsersIcon } from "./assets/images/svg/users.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { APPLICATION_TOAST_CONTAINER_ID } from "./constants/toastConstants";
import AppLayout from "./layouts/AppLayout/AppLayout";
import SidebarNavigation from "./components/SidebarNavigation/SidebarNavigation";
import { TouchBackend } from "react-dnd-touch-backend";

// Styles
import GlobalStyle from "./components/Styles/globalStyles";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/fonts/Inter/Inter.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useIsMobile from "./hooks/useIsMobile";

const App = () => {
  const { isMobile } = useIsMobile();
  return (
    <>
      <Router history={history} className="app">
        <Helmet>
          <title>{i18next.t("app.title")}</title>
        </Helmet>

        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <StyledEngineProvider injectFirst>
            <AppLayout
              sidebar={<SidebarNavigation />}
              content={
                <>
                  <Modal />
                  <ToastContainer
                    enableMultiContainer
                    limit={1}
                    containerId={APPLICATION_TOAST_CONTAINER_ID}
                  />

                  <Header icon={<UsersIcon />} route="Users" />
                  <GlobalStyle />
                  <AppRoutes />
                </>
              }
            />
          </StyledEngineProvider>
        </DndProvider>
      </Router>
    </>
  );
};

export default App;
