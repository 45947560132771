import RoleRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/RoleRenderer/RoleRenderer";
import UserRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  users: {
    id: 0,
    i18key: "users.table.users.columnTitle",
    renderer: UserRenderer,
    backendProperty: "firstName",
    disableSort: true,
    style: {
      maxWidth: "calc(100% / 3)",
    },
  },
  lastLogin: {
    id: 1,
    i18key: "users.table.lastLogin.columnTitle",
    backendProperty: "lastLoginDate",
    disableSort: true,
    style: {
      maxWidth: "calc(100% / 3)",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  role: {
    id: 2,
    i18key: "users.table.role.columnTitle",
    renderer: RoleRenderer,
    disableSort: true,
    style: {
      maxWidth: "calc(100% / 3)",
      justifyContent: "center",
    },
  },
};
