import { Box } from "@mui/material";
import styled from "styled-components";

export const ImagePreviewModalContainer = styled(Box)`
  width: 448px;
  /* height: 870px; */
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => "15" + props.rank || "151"};
  @media (max-width: 600px) {
    width: 90vw;

    max-height: 100vh;
  }
`;
export const ImagePreviewContainer = styled(Box)`
  max-width: 100%;
  position: relative;
  @media (max-width: 600px) {
    width: 100%;
    overflow: auto;
  }
`;
export const ImagePreview = styled.img`
  max-width: 100%;
  object-fit: cover;
  @media (max-width: 600px) {
    width: 100%;
    overflow: auto;
    object-fit: contain;
  }
`;

export const ButtonsContainer = styled(Box)``;
