import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";
import { ErrorMessage } from "../NewClientForm.styled";

const TinField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="pib"
        placeholder={t("clients.newClientPlaceholders.pib")}
        label={t("clients.pib")}
        number
        value={formik?.values?.pib}
        onChange={formik?.handleChange}
        requiredField={
          newClientValidationSchema?.fields?.pib?.exclusiveTests?.required
        }
        error={formik?.errors?.pib && formik?.touched?.pib}
        helperText={formik?.errors?.pib && formik?.touched?.pib}
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.pib && formik?.touched?.pib && (
        <ErrorMessage>{formik?.errors?.pib}</ErrorMessage>
      )}
    </>
  );
};

TinField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default TinField;
