import React from "react";
import PropTypes from "prop-types";
import {
  CloseButton,
  NotOnlineDescription,
  NotOnlinePopoverContainer,
  NotOnlinePopoverImage,
  NotOnlinePopoverImageContainer,
  NotOnlineTitle,
} from "./NotOnlinePopover.styled";
import { useTranslation } from "react-i18next";

const NotOnlinePopover = (props) => {
  const { t } = useTranslation();
  console.log(props);
  return (
    <NotOnlinePopoverContainer>
      <NotOnlinePopoverImageContainer>
        <NotOnlinePopoverImage />
      </NotOnlinePopoverImageContainer>
      <NotOnlineTitle>{t("notOnline.title")}</NotOnlineTitle>
      <NotOnlineDescription>{t("notOnline.description")}</NotOnlineDescription>
      <CloseButton onClick={props?.closePopover}>
        {t("common.okay")}
      </CloseButton>
    </NotOnlinePopoverContainer>
  );
};

NotOnlinePopover.propTypes = {
  closePopover: PropTypes.func,
};

export default NotOnlinePopover;
