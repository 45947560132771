import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  attemptCreateClient,
  attemptDeleteClient,
  attemptEditSingleClient,
  attemptFetchClients,
  attemptFetchSingleClient,
} from "../../request/clientsRequest";
import {
  CREATE_CLIENT_FETCH,
  DELETE_CLIENT_FETCH,
  EDIT_CLIENT_FETCH,
  GET_CLIENTS_FETCH,
  SINGLE_CLIENT_FETCH,
} from "../actions/clients/clientsActionConstants";
import {
  fetchClientsError,
  fetchClientsSuccess,
  fetchCreateClientError,
  fetchCreateClientSuccess,
  fetchDeleteClientError,
  fetchDeleteClientSuccess,
  fetchEditClientError,
  fetchEditClientSuccess,
  fetchSingleClientError,
  fetchSingleClientSuccess,
  setClients,
  setSingleClient,
  setTotalClients,
} from "../actions/clients/clientsActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchClients({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload);
    const data = yield call(attemptFetchClients, queryObject);
    yield put(
      setClients({
        data: data?.data?.clients,
      })
    );
    yield put(setTotalClients(data?.data?.count));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchClientsSuccess());
  } catch (e) {
    console.log("greska", e);
    if (payload.handleApiResponseError) {
      yield call(payload.handleApiResponseError);
    }
    yield put(fetchClientsError());
  }
}

function* fetchCreateClient({ payload }) {
  try {
    const data = yield call(attemptCreateClient, payload?.clientData);
    console.log(data);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data?.id);
    }
    yield put(fetchCreateClientSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload.handleApiResponseError, errorMessage);
      }
      yield put(fetchCreateClientError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload.handleApiResponseError);
      }
      yield put(fetchCreateClientError());
    }
  }
}

function* fetchSingleClient({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleClient, {
      clientId: payload.clientId,
    });
    yield put(setSingleClient(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchSingleClientSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchSingleClientError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchSingleClientError());
    }
  }
}

function* fetchEditSingleClient({ payload }) {
  try {
    yield call(attemptEditSingleClient, {
      clientId: payload.clientId,
      data: {
        ...payload.clientData,
        id: payload.clientId,
      },
    });
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
    yield put(fetchEditClientSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload.handleApiResponseError) {
        yield call(payload.handleApiResponseError, errorMessage);
      }
      yield put(fetchEditClientError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchEditClientError());
    }
  }
}

function* fetchDeleteClient({ payload }) {
  try {
    yield call(attemptDeleteClient, {
      clientId: payload.clientId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteClientSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteClientError(errorMessage));
  }
}

export default function* clientsSaga() {
  yield all([
    takeLatest(GET_CLIENTS_FETCH, fetchClients),
    takeLatest(CREATE_CLIENT_FETCH, fetchCreateClient),
    takeLatest(EDIT_CLIENT_FETCH, fetchEditSingleClient),
    takeLatest(SINGLE_CLIENT_FETCH, fetchSingleClient),
    takeLatest(DELETE_CLIENT_FETCH, fetchDeleteClient),
  ]);
}
