import React from "react";
import PropTypes from "prop-types";
import { ButtonsAreaContainer, ButtonsContainer } from "./ActionButtons.styled";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { RequiredFieldsText } from "../../Clients/NewClient/NewClientForm.styled";
import { useTranslation } from "react-i18next";

const ActionButtons = (props) => {
  const { t } = useTranslation();
  return (
    <ButtonsAreaContainer>
      <ButtonsContainer>
        <PrimaryButton onClick={props?.handleCancel}>
          {t("common.cancel")}
        </PrimaryButton>
        <PrimaryButton
          variant="contained"
          onClick={props?.handleSave}
          disabled={props?.disabled}
        >
          {t("common.save")}
        </PrimaryButton>
      </ButtonsContainer>
      <RequiredFieldsText>{t("common.requiredFieldsText")}</RequiredFieldsText>
    </ButtonsAreaContainer>
  );
};

ActionButtons.propTypes = {
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ActionButtons;
