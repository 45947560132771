import Title from "../../../Typography/Title/Title";
import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import Icon from "../../../Icon/Icon";
import CopyIcon from "../../../Icon/Icons/CopyIcon";
import selectedTheme from "../../../../themes";

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0px;
  /* margin-bottom: ${(props) => !props?.$noMargin && "62px"}; */
  width: 100%;
`;
export const PageTitleOutterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

export const PageTitleLeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const PageTitle = styled(Title)``;

export const IconContainer = styled(Icon)`
  cursor: pointer;
  margin-left: 16px;
`;

export const CopySubjectIcon = styled(CopyIcon)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
