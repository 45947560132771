import {
  downloadRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchFindings = (payload) =>
  getRequest(apiEndpoints.findings.getFindings, payload);

export const attemptFetchSingleFinding = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.findings.getSingleFinding, {
      findingId: payload,
    })
  );
export const attemptFetchFindingPreview = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.findings.getFindingPreview, {
      findingId: payload,
    })
  );
export const attemptFetchCreateFinding = (payload) =>
  postRequest(apiEndpoints.findings.createFinding, payload);

export const attemptFetchPrintFinding = (payload) =>
  downloadRequest(
    replaceInUrl(apiEndpoints.findings.printFinding, {
      findingId: payload,
    })
  );

export const attemptFetchEditFinding = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.findings.getSingleFinding, {
      findingId: payload.findingId,
    }),
    payload.data
  );
export const attemptFillFindingQuestion = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.findings.editSingleItem, {
      findingId: payload.findingId,
    }),
    payload.data
  );
export const attemptChangeFindingState = (payload) =>
  patchRequest(apiEndpoints.findings.changeState, null, payload);

export const attemptSendFindingMails = (payload) =>
  getRequest(apiEndpoints.findings.sendFindingMails, payload);

export const attemptFetchFindingSubject = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.findings.fetchFindingSubject, {
      subjectId: payload,
    })
  );

export const attemptChangeSectionNotes = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.findings.changeSectionNotes, {
      findingId: payload?.findingId,
    }),
    payload?.body
  );
export const attemptChangeSubjectConclusions = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.findings.changeSubjectConclusions, {
      findingId: payload?.findingId,
    }),
    payload?.body
  );

export const attemptPatchFinding = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.findings.patchFinding, {
      findingId: payload?.findingId,
    }),
    payload?.data,
    null,
    {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    }
  );

export const attemptCopyFinding = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.findings.copyFinding, {
      findingId: payload?.findingId,
    }),
    payload?.data,
    null,

    { headers: { "Content-Type": "application/json-patch+json" } }
  );

export const attemptFetchFindingTypes = (payload) =>
  getRequest(apiEndpoints.findings.findingTypes, payload);
