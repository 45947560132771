export const mapChecklistTemplateForBE = (frontendRequest, data) => {
  let requestObject = { ...frontendRequest };
  if (data?.measuringInstruments !== undefined) {
    requestObject = {
      ...requestObject,
      measuringInstruments: data?.measuringInstruments?.map(
        (singleInstrument) => singleInstrument?.id
      ),
      standardIds: data?.standards?.map((singleStandard) => singleStandard?.id),
    };
  }
  if (data?.sections !== undefined) {
    requestObject = {
      ...requestObject,
      sections: data?.sections?.map((singleSection, sectionIndex) => ({
        title: singleSection?.title,
        orderNumber: sectionIndex,
        items: singleSection?.questions?.map(
          (singleQuestion, questionIndex) => {
            let singleQuestionObject = {
              orderNumber: questionIndex,
              question: singleQuestion?.questionName,
              itemType: singleQuestion?.questionType,
              isMandatory: singleQuestion?.isRequired,
              isRateInRange: singleQuestion?.hasResearchPassed,
              descriptionOne: singleQuestion?.descriptionOne || "",
              descriptionTwo: singleQuestion?.descriptionTwo || "",
              defaultAnswer:
                singleQuestion?.textTypeInputs?.defaultAnswer || "",
              note: singleQuestion?.textTypeInputs?.note || "",
              columns: singleQuestion?.tableTypeInputs?.columns
                ? singleQuestion?.tableTypeInputs?.columns
                    ?.sort(function (a, b) {
                      return a.columnIndex - b.columnIndex;
                    })
                    ?.map((column, index) => {
                      return { ...column, columnIndex: index };
                    })
                : [],
              options:
                singleQuestion?.selectTypeInputs?.possibleAnswers?.map(
                  (singleAnswer, answerIndex) => ({
                    isSelected:
                      answerIndex ===
                      singleQuestion?.selectTypeInputs?.defaultAnswerIndex,
                    answer: singleAnswer.answer,
                  })
                ) || [],
            };
            return singleQuestionObject;
          }
        ),
      })),
    };
  }

  if (data?.negativeConclusionTemplate !== undefined) {
    requestObject = {
      ...requestObject,
      negativeConclusionTemplate:
        data.negativeConclusionTemplate !== null
          ? data.negativeConclusionTemplate
          : "",
    };
  }
  if (data?.positiveConclusionTemplate !== undefined) {
    requestObject = {
      ...requestObject,
      positiveConclusionTemplate:
        data.positiveConclusionTemplate !== null
          ? data.positiveConclusionTemplate
          : "",
    };
  }
  console.log(requestObject)
  return requestObject;
};
