export const ADMIN_ROLE = "Admin";
export const MANAGER_ROLE = "Manager";
export const OPERATOR_ROLE = "Operator";
export const GUEST_ROLE = "Guest";

const rolesConstants = [
  {
    roleName: ADMIN_ROLE,
    i18key: "roles.admin",
  },
  {
    roleName: MANAGER_ROLE,
    i18key: "roles.manager",
  },
  {
    roleName: OPERATOR_ROLE,
    i18key: "roles.operator",
  },
  // {
  //   roleName: GUEST_ROLE,
  //   i18key: "roles.guest",
  // },
];

export default rolesConstants;

export const findRoleByRoleName = (roleName) => {
  return rolesConstants.find(
    (item) => item.roleName.toLowerCase() === roleName.toLowerCase()
  );
};

export const getHierarchyRoles = (roleName) => {
  const rolesArray = [];
  if (!roleName) return rolesConstants.map((item) => item.roleName);
  for (let i = 0; i < rolesConstants.length; i++) {
    rolesArray.push(rolesConstants[i].roleName);
    if (rolesConstants[i].roleName === roleName) break;
  }
  return rolesArray;
};
