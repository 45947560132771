import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { selectClientsData } from "../../../../../../store/selectors/clientsSelector";
import { useTranslation } from "react-i18next";
import {
  selectSingleFindingSettings,
  selectSingleFindingSettingsOld,
} from "../../../../../../store/selectors/findingsSelectors";
import AutocompleteField from "../../../../../Field/AutocompleteField/AutocompleteField";
import { statusTableConstants } from "../../../../../../constants/findingStatusConstants";

const ClientField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const clients = useSelector(selectClientsData);
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  const findingSettings = useSelector(selectSingleFindingSettings);

  const clientName = useMemo(() => {
    return findingSettings?.client?.name;
  }, [findingSettings]);

  const isDeletedClient = useMemo(() => {
    return (
      !clients?.some?.(
        (singleClient) => singleClient?.id === findingSettings?.clientId
      ) &&
      props?.stateId !== statusTableConstants.inProgress.id &&
      formik?.values?.[findingFormikConstants.clientId]?.value != null
    );
  }, [findingSettings, clients, props?.stateId]);

  return (
    <AutocompleteField
      options={clients}
      label={
        isDeletedClient ? clientName : t("findings.form.client.placeholder")
      }
      disabled={props?.disabled}
      title={t("findings.form.client.title")}
      required={formik?.values?.[findingFormikConstants.clientId].value == null}
      onChange={(value) =>
        formik?.setFieldValue?.(findingFormikConstants.clientId, {
          edited: findingSettingsOld?.clientId?.value !== value,
          value: value?.id,
        })
      }
      dropdownLabel={["name"]}
      value={clients.find(
        (client) =>
          client?.id == formik?.values?.[findingFormikConstants.clientId].value
      )}
      error={
        formik?.touched?.[findingFormikConstants.clientId]?.value &&
        formik?.errors?.[findingFormikConstants.clientId]?.value
      }
    />
  );
};

ClientField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  stateId: PropTypes.number,
};

export default ClientField;
