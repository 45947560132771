import { FormControlLabel, Radio } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";

export const FindingTypeLabelContainer = styled(FormControlLabel)`
  padding: 8px 12px;
  height: 36px;
  border-radius: 24px;
  gap: 8px;
  background: ${selectedTheme.colors.findingTypeColor};

  color: ${selectedTheme.colors.findingTypeTextColor};
  max-width: fit-content;
  font-size: 16px;
  line-height: 20px;
  & * {
    font-weight: 500;
    font-feature-settings: "salt" on;
    font-family: ${selectedTheme.fonts.mainText};
  }
  ${(props) =>
    props?.$isChecked &&
    css`
      padding: 7px 11px;
      color: ${selectedTheme.colors.checkedRadioColor};
      background: ${selectedTheme.colors.checkedRadioBackground};
      border: 1px solid ${selectedTheme.colors.checkedRadioColor};
    `}
`;
export const RadioButton = styled(Radio)`
  width: 20px;
  height: 20px;
  background: ${selectedTheme.colors.white};
  ${(props) =>
    props?.isChecked &&
    css`
      & .MuiSvgIcon-root {
        fill: ${selectedTheme.colors.checkedRadioColor};
      }
      background: ${selectedTheme.colors.checkedRadioBackground};
    `}
`;
