import React from "react";
import {
  AutocompleteContainer,
  AutocompleteDropdown,
  AutocompleteInputField,
  AutocompleteItem,
  DeleteButton,
  FieldLabel,
  Tag,
  TagsContainer,
} from "./AutocompleteField.styled";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../Select/Select.styled";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../themes";
import { useState } from "react";
import { useEffect } from "react";
import useIsMobile from "../../../hooks/useIsMobile";

const AutocompleteField = ({
  options,
  dropdownLabel,
  label,
  title,
  onChange,
  defaultValue,
  value,
  error,
  required,
  disabled,
  setMargin,
  isMultiple,
  onDelete,
  removeMaxWidth,
}) => {
  const { t } = useTranslation();
  const [autoValue, setAutoValue] = useState(value);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setAutoValue(value);
  }, [value]);

  return (
    <AutocompleteContainer>
      {title && (
        <FieldLabel $required={required} $setMargin={setMargin}>
          {title}
        </FieldLabel>
      )}
      <AutocompleteDropdown
        disablePortal
        clearText={t("common.remove")}
        closeText={t("common.close")}
        openText={t("common.open")}
        noOptionsText={t("common.noOptions")}
        fullWidth
        disabled={disabled}
        multiple={isMultiple}
        removeMaxWidth={removeMaxWidth}
        disableCloseOnSelect={isMultiple}
        options={options}
        InputLabelProps={{ shrink: false }}
        getOptionLabel={(option) =>
          option
            ? dropdownLabel?.reduce(
                (accumulator, currVal, index) =>
                  index === dropdownLabel?.length - 1
                    ? accumulator + option[currVal]
                    : accumulator + option[currVal] + " ",
                ""
              )
            : null
        }
        renderInput={(params) => (
          <AutocompleteInputField
            {...params}
            error={Boolean(error)}
            InputLabelProps={{ shrink: false }}
            placeholder={label}
            value={value}
          />
        )}
        renderOption={(props, option) => (
          <AutocompleteItem {...props} key={option?.id}>
            {dropdownLabel?.reduce?.(
              (accumulator, currVal) => accumulator + option?.[currVal] + " ",
              ""
            )}
          </AutocompleteItem>
        )}
        renderTags={(selected) => (
          <TagsContainer>
            {selected?.map?.((value) => (
              <Tag
                key={value?.id}
                label={dropdownLabel?.reduce(
                  (accumulator, currVal) => accumulator + value[currVal] + " ",
                  ""
                )}
                clickable={isMobile}
                onClick={() => (isMobile ? onDelete(value) : () => {})}
                onDelete={() => onDelete(value)}
                deleteIcon={
                  <DeleteButton
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </TagsContainer>
        )}
        onChange={(event, query) => {
          onChange(query);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        defaultValue={defaultValue}
        value={autoValue}
        sx={{
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: !error
              ? `1px solid ${selectedTheme.colors.inputBorderColor};`
              : `1px solid ${selectedTheme.colors.errorColor}`,
          },
          "&.Mui-disabled .MuiOutlinedInput-root": {
            color: `${selectedTheme.colors.black} !important`,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${selectedTheme.colors.inputBorderColor} !important`,
          },
          "&.MuiAutocomplete-endAdornment": {
            position: "absolute !important",
          },
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </AutocompleteContainer>
  );
};

AutocompleteField.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  dropdownLabel: PropTypes.array,
  title: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  setMargin: PropTypes.bool,
  isMultiple: PropTypes.bool,
  onDelete: PropTypes.func,
  removeMaxWidth: PropTypes.bool,
};

export default AutocompleteField;
