import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";
import { ErrorMessage } from "../NewClientForm.styled";
import Tooltip from "../../../Tooltip/Tooltip";

const AddressField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={formik?.values?.address?.length > 41 ? formik?.values?.address : ""}
    >
      <TextInputField
        name="address"
        placeholder={t("clients.newClientPlaceholders.address")}
        label={t("clients.address")}
        value={formik?.values?.address}
        onChange={formik?.handleChange}
        requiredField={
          newClientValidationSchema?.fields?.address?.exclusiveTests?.required
        }
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.address &&
                formik?.touched?.address &&
                "40px !important",
              width:
                formik?.errors?.address &&
                formik?.touched?.address &&
                "314px !important",
            },
          },
        }}
        error={formik?.errors?.address && formik?.touched?.address}
        helperText={formik?.errors?.address && formik?.touched?.address}
        disabledField={props?.isReadOnly}
      />
      {formik?.errors?.address && formik?.touched?.address && (
        <ErrorMessage>{formik?.errors?.address}</ErrorMessage>
      )}
    </Tooltip>
  );
};

AddressField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default AddressField;
