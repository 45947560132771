import React from "react";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import BackdropComponent from "../../MUI/BackdropComponent";
import { SetLoadingModalContainer } from "./SetLoadingModal.styled";
import PropTypes from "prop-types";

const SetLoadingModal = (props) => {
  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent isLoading={true} rank={props?.rank - 1} />
      ) : (
        <></>
      )}
      <SetLoadingModalContainer rank={props?.rank}>
        <CircularLoader />
      </SetLoadingModalContainer>
    </>
  );
};

SetLoadingModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
};

export default SetLoadingModal;
