import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectModalValues } from "../../store/selectors/modalSelectors";
import SetPasswordModal from "./SetPasswordModal/SetPasswordModal";
import BackdropComponent from "../MUI/BackdropComponent";
import { closeModal } from "../../store/actions/modal/modalActions";
import { ModalContainer } from "./Modal.styled";
import SetStandardModal from "./SetStandardModal/SetStandardModal";
import SetMeasuringInstrumentModal from "./SetMeasuringInstrumentModal/SetMeasuringInstrumentModal";
import DistributionEmailsModal from "./DistributionEmailsModal/DistributionEmailsModal";
import ImagePreviewModal from "./ImagePreviewModal/ImagePreviewModal";
import SetDeleteModal from "./SetDeleteModal/SetDeleteModal";
import SetLoadingModal from "./SetLoadingModal/SetLoadingModal";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import SetScanningObjectModal from "./SetScanningObjectModal/SetScanningObjectModal";
import SendMailsModal from "./SendMailsModal/SendMailsModal";
import SetUserModal from "./SetUserModal/SetUserModal";
import TableModal from "./TableModal/TableModal";
import CopyFindingModal from "./CopyFindingModal/CopyFindingModal";
import RenameScanningSubjectModal from "./RenameScanningSubjectModal/RenameScanningSubjectModal";
import ColorPicker from "../ColorPicker/ColorPicker";
import RenameTableColumnsModal from "./RenameTableColumnsModal/RenameTableColumnsModal";
import AddFindingStaffModal from "./AddFindingStaffModal/AddFindingStaffModal";
import FindingTypesModal from "./FindingTypesModal/FindingTypesModal";
import AttendedPersonModal from "./AttendedPersonModal/AttendedPersonModal";
import TablePreviewModal from "./TablePreviewModal/TablePreviewModal";
import FindingPreviewModal from "./FindingPreviewModal/FindingPreviewModal";

const Modal = () => {
  const modals = useSelector(selectModalValues);
  const dispatch = useDispatch();

  const isModalShown = useMemo(() => {
    const properties = Object.getOwnPropertyNames(modals);
    let isModalShownLocal = false;
    properties?.forEach?.((singleModal) => {
      if (modals[singleModal] === true) isModalShownLocal = true;
    });
    return isModalShownLocal;
  }, [modals]);

  if (isModalShown) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      {isModalShown ? (
        <>
          <BackdropComponent
            isLoading
            handleClose={handleCloseModal}
            position="fixed"
          />
          <ModalContainer
            onMouseDown={(event) => {
              if (event.target === event.currentTarget) {
                handleCloseModal();
              }
            }}
          >
            {modals?.setPasswordModal && (
              <SetPasswordModal {...modals?.props?.passwordProps} />
            )}
            {modals?.renameColumnsModal && (
              <RenameTableColumnsModal {...modals?.props?.renameColumnsProps} />
            )}
            {modals?.setStandardModal && (
              <SetStandardModal {...modals?.props?.standardProps} />
            )}
            {modals?.setDeleteModal && (
              <SetDeleteModal {...modals?.props?.deleteProps} />
            )}
            {modals?.setUserModal && (
              <SetUserModal {...modals?.props?.userProps} />
            )}
            {modals?.setLoadingModal && (
              <SetLoadingModal {...modals?.props?.loadingProps} />
            )}
            {modals?.setMeasuringInstrumentModal && (
              <SetMeasuringInstrumentModal {...modals?.props?.measuringProps} />
            )}
            {modals?.setScanningObjectModal && (
              <SetScanningObjectModal {...modals?.props?.scanningObjectProps} />
            )}
            {modals?.distributionEmailsModal && (
              <DistributionEmailsModal {...modals?.props?.emailProps} />
            )}
            {modals?.imagePreviewModal && (
              <ImagePreviewModal {...modals?.props?.imageProps} />
            )}
            {modals?.findingPreviewModal && (
              <FindingPreviewModal {...modals?.props?.findingPreviewProps} />
            )}
            {modals?.tablePreviewModal && (
              <TablePreviewModal {...modals?.props?.tablePreviewProps} />
            )}
            {modals?.confirmModal && (
              <ConfirmModal {...modals?.props?.confirmProps} />
            )}
            {modals?.sendMailsModal && (
              <SendMailsModal {...modals?.props?.sendMailsProps} />
            )}
            {modals?.tableModal && (
              <TableModal {...modals?.props?.tableProps} />
            )}
            {modals?.copyFindingModal && (
              <CopyFindingModal {...modals?.props?.copyFindingProps} />
            )}
            {modals?.renameScanningSubjectModal && (
              <RenameScanningSubjectModal
                {...modals?.props?.renameScanningSubjectProps}
              />
            )}
            {modals?.colorPickerModal && (
              <ColorPicker {...modals?.props?.colorPickerProps} />
            )}
            {modals?.addFindingStaffModal && (
              <AddFindingStaffModal {...modals?.props?.addFindingStaffModal} />
            )}
            {modals?.setFindingTypesModal && (
              <FindingTypesModal {...modals?.props?.findingTypesProps} />
            )}
            {modals?.attendedPersonModal && (
              <AttendedPersonModal {...modals?.props?.attendedPersonProps} />
            )}
          </ModalContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
