import styled, { css } from "styled-components";
import { Box, Button, Typography } from "@mui/material";
import Title from "../Typography/Title/Title";
import { ReactComponent as SetPassword } from "../../assets/images/svg/set-password.svg";
import selectedTheme from "../../themes";
import Icon from "../Icon/Icon";
import TickCircledIcon from "../Icon/Icons/TickCircledIcon";
import DeactivateIcon from "../Icon/Icons/DeactivateIcon";
import { TextInputFieldContainer } from "../TextInputField/TextInputField.styled";

export const NewUserContainerForm = styled.form`
  /* margin-left: 8vw; */
  min-height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  padding-bottom: 72px;
  @media (max-width: 600px) {
    padding-bottom: 32px;
    & ${TextInputFieldContainer} input {
      width: 100%;
    }
    & ${TextInputFieldContainer} div:has(input) {
      width: 100%;
    }
  }
`;

export const NewUserInputsContainer = styled(Box)`
  display: flex;
  gap: 43px;
  flex: 1;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0px;
  margin-bottom: ${(props) => !props?.$noMargin && "62px"};
  width: 100%;
  @media (max-width: 600px) {
    margin-bottom: 32px;
  }
`;

export const PageeTitle = styled(Title)``;

export const DeactivateIconContainer = styled(Icon)`
  cursor: pointer;
  margin-left: 16px;
  & svg {
    path {
      stroke: ${selectedTheme.colors.toastError};
    }
  }
`;

export const DeactivateIconCustom = styled(DeactivateIcon)`
  width: 24px;
  height: 24px;
`;
export const NewUserBasicInfo = styled(Box)``;

export const NewUserInfoHeader = styled(Typography)`
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
`;

export const NewUserAcountInfo = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const NewUserInputlabel = styled(Typography)`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 32px;
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `}
`;

export const NewUserInput = styled.input``;

export const PasswordButtonContainer = styled(Box)`
  display: flex;
  margin-left: auto;
  margin-top: 24px;
  margin-bottom: 6px;
`;

export const PasswordValidationIcon = styled(Icon)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-top: 10px;
  margin-right: 13px;
  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const PasswordButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === "contained"
      ? selectedTheme.colors.primaryButtonDarkColor
      : selectedTheme.colors.primaryButtonLightColor};
  color: ${(props) =>
    props.variant === "contained"
      ? "white"
      : selectedTheme.colors.primaryButtonTextColor};
  text-transform: none;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 500;
  width: 155px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  white-space: nowrap;
  &:hover {
    background-color: ${(props) =>
      props.variant === "contained"
        ? selectedTheme.colors.orangeLightHover
        : selectedTheme.colors.primaryButtonLightColor};
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 15px;
  margin-left: -50px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

export const Line = styled(Box)`
  width: 191px;
  height: 1px;
  background-color: #abadc6;
`;

export const TickIcon = styled(TickCircledIcon)`
  path {
    stroke: ${selectedTheme.colors.toastSuccess};
  }
`;

export const SetPasswordIcon = styled(SetPassword)`
  path {
    stroke: #ffffff;
  }
`;
export const RolesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 6px;
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorMessage};
  height: 0px;
  text-align: ${(props) => props?.$rightAlign && "right"};
`;

export const TitleButtonsContainer = styled(Box)`
  display: flex;
  align-items: start;
`;
