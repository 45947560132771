import * as Yup from "yup";
import i18n from "../i18n";

export default Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required(i18n.t("companySettings.companyNameRequired")),
  companyPIB: Yup.string()
    .trim()
    .required(i18n.t("companySettings.PIBRequred"))
    .min(9, i18n.t("companySettings.PIBnoOfCharacters"))
    .max(9, i18n.t("companySettings.PIBnoOfCharacters")),
  identificationNumber: Yup.string()
    .trim()
    .required(i18n.t("companySettings.idNumberRequred"))
    .min(8, i18n.t("companySettings.idNumberNoOfCharacters"))
    .max(8, i18n.t("companySettings.idNumberNoOfCharacters")),
  bankAccountNumber: Yup.string(),
  phoneNumber: Yup.string(),
  address: Yup.string(),
  companyDirector: Yup.string(),
  email: Yup.string().email(i18n.t("clients.emailFormat")),
  distributionEmails: Yup.array().of(
    Yup.string().email(i18n.t("clients.emailFormat"))
  ),
  userName: Yup.string()
    .email(i18n.t("clients.emailFormat"))
    .when("automaticallySendEmails", {
      is: true,
      then: Yup.string().required(i18n.t("emailSettings.userNameRequired")),
    }),
  password: Yup.string().when("automaticallySendEmails", {
    is: true,
    then: Yup.string().when("hasEmailPassword", {
      is: false,
      then: Yup.string().required(i18n.t("emailSettings.passwordRequired")),
    }),
  }),
  host: Yup.string().when("automaticallySendEmails", {
    is: true,
    then: Yup.string().required(i18n.t("emailSettings.hostRequired")),
  }),
  port: Yup.string().when("automaticallySendEmails", {
    is: true,
    then: Yup.string().required(i18n.t("emailSettings.portRequired")),
  }),
  enableSSl: Yup.bool().when("automaticallySendEmails", {
    is: true,
    then: Yup.bool()
      .required(i18n.t("emailSettings.sslRequired"))
      .oneOf([true], i18n.t("emailSettings.sslRequired")),
  }),
  automaticallySendEmails: Yup.bool(),
  automaticallyUploadToDrive: Yup.bool(),
  driveFolderId: Yup.string().when("automaticallyUploadToDrive", {
    is: true,
    then: Yup.string().required(
      i18n.t("companySettings.archive.googleDrive.driveIdRequried")
    ),
  }),
  hasCredentialsFile: Yup.bool().when("automaticallyUploadToDrive", {
    is: true,
    then: Yup.bool()
      .required(i18n.t("companySettings.archive.googleDrive.fileRequired"))
      .oneOf(
        [true],
        i18n.t("companySettings.archive.googleDrive.fileRequired")
      ),
  }),
  hasEmailPassword: Yup.bool(),
});
