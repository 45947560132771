import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const FindingTypeContainer = styled(Box)`
  padding: 8px 16px 8px 16px;
  height: 36px;
  border-radius: 24px;
  gap: 4px;
  background: ${selectedTheme.colors.findingTypeColor};
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.findingTypeTextColor};
  max-width: fit-content;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: "salt" on;
`;
