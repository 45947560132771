import React from "react";
import PropTypes from "prop-types";
import { FindingTypeContainer } from "./FindingTypeOption.styled";

const FindingTypeOption = (props) => {
  return (
    <FindingTypeContainer {...props}>
      {props?.findingTypeLabel}
    </FindingTypeContainer>
  );
};

FindingTypeOption.propTypes = {
  findingTypeLabel: PropTypes.string,
};

export default FindingTypeOption;
