import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";

export const SidebarNavigationContainer = styled(Box)`
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: ${selectedTheme.colors.userPopoverItemColor};
  width: ${(props) => (props?.$isSidebarOpened ? "258px" : "72px")};
  display: flex;
  padding-top: 21px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: ${(props) => !props?.$isSidebarOpened && "center"};
  justify-content: space-between;
  z-index: 150;
  padding-left: 12px;
  padding-right: 12px;
  transition-duration: 0.2s;
  & * {
    transition-duration: 0.2s;
  }
  @media (max-width: 600px) {
    position: absolute;
    width: 70vw;
    height: 100dvh;
    left: ${(props) => (props?.$isSidebarOpened ? "0" : "-70vw")};
    transition-duration: 0.4s;
  }
`;

export const SidebarNavigationLogoContainer = styled(Box)``;
export const SidebarNavigationIconContainer = styled(Box)`
  margin: 10px 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f6f6fa;
  }

  ${(props) =>
    props.active &&
    `
      background-color: #f6f6fa;
    `}
`;
export const SidebarBackdrop = styled(Box)`
  @media (min-width: 600px) {
    display: none;
  }
  background-color: ${(props) =>
    props?.$isSidebarOpened
      ? hexToRGB(selectedTheme.colors.black, 0.4)
      : hexToRGB(selectedTheme.colors.black, 0)};
  transition: background-color 0.2s;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 149;
  width: 100vw;
  height: 100vh;
  ${(props) =>
    !props?.$isSidebarOpened &&
    css`
      width: 0;
      height: 0;
    `}
`;

export const SidebarContainer = styled(Box)`
  width: 100%;
  height: 100%;
`;
