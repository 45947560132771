import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  AddRowButton,
  ErrorMessage,
  FindingItemTableRefContainer,
  FindingRowsContainer,
  FindingTableInputContainer,
  FindingTableInputFooter,
  PlusIconThin,
} from "./FindingTableInput.styled";
import FindingTableSingleRow from "./FindingTableSingleRow/FindingTableSingleRow";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  selectFindingColumnAnswers,
  selectFindingColumnAnswersToEdit,
  selectFindingColumns,
  selectSingleFinding,
  selectSingleFindingToEdit,
} from "../../../../store/selectors/findingsSelectors";
import { useTranslation } from "react-i18next";
import {
  addFindingColumnAnswer,
  setSingleFindingColumnsFromEdited,
  setSingleFindingToEdit,
} from "../../../../store/actions/findings/findingsActions";
import { selectMyProfileId } from "../../../../store/selectors/loginSelectors";
import FindingItemTable from "./FindingItemTable/FindingItemTable";
import useIsMobile from "../../../../hooks/useIsMobile";

const FindingTableInput = (props) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = useSelector(selectFindingColumns(props?.questionId));
  const singleFindingToEdit = useSelector(selectSingleFindingToEdit);
  const singleFinding = useSelector(selectSingleFinding);
  const userId = useSelector(selectMyProfileId);
  const [isClickedToAdd, setIsClickedToAdd] = useState();
  const columnAnswers = useSelector(
    selectFindingColumnAnswers(props?.questionId)
  );
  const addButtonRef = useRef(null);
  const columnAnswersToEdit = useSelector(
    selectFindingColumnAnswersToEdit(props?.questionId)
  );
  const containerRef = useRef(null);
  const [isAddedEventListener, setIsAddedEventListener] = useState(false);
  const findingRef = useRef(singleFindingToEdit);
  const [isAddedRow, setIsAddedRow] = useState(false);

  useEffect(() => {
    if (JSON.stringify(singleFinding) !== JSON.stringify(singleFindingToEdit)) {
      dispatch(setSingleFindingToEdit(singleFinding));
    }
  }, [singleFinding]);

  useEffect(() => {
    findingRef.current = singleFindingToEdit;
  }, [singleFindingToEdit]);

  const handleAddRowClick = (event) => {
    event.stopPropagation();
    setIsAddedRow(true);
    // let lastColumnAnswer = columnAnswersToEdit?.[columnAnswers?.length - 1];
    // if (isClickedToAdd) {
    batch(() => {
      dispatch(
        setSingleFindingColumnsFromEdited({ itemId: props?.questionId })
      );
      dispatch(
        addFindingColumnAnswer({
          userId,
          questionId: props?.questionId,
        })
      );
    });
  };

  const isClickableToAdd = useMemo(() => {
    let lastColumnAnswer =
      columnAnswersToEdit?.[columnAnswersToEdit?.length - 1];
    // if (columnAnswersToEdit?.length === 0)
    return !lastColumnAnswer?.every?.(
      (singleColumnAnswer) => singleColumnAnswer?.answer?.trim?.()?.length === 0
    );
  }, [columnAnswersToEdit]);

  const tableRowInputs = useMemo(
    () =>
      columns?.reduce?.(
        (acc, val) => {
          let indexOfTitle = acc.titles.findIndex(
            (singleTitle) => val?.columnTitle === singleTitle.title
          );
          if (indexOfTitle !== -1) {
            let newData = [...acc.data];
            newData.splice(acc.titles[indexOfTitle].dataIndex, 1, [
              ...acc.data[acc.titles[indexOfTitle].dataIndex],
              val,
            ]);
            return {
              ...acc,
              data: [...newData],
            };
          } else {
            return {
              titles: [
                ...acc.titles,
                { title: val.columnTitle, dataIndex: acc.data.length },
              ],
              data: [...acc.data, [val]],
            };
          }
        },
        { titles: [], data: [] }
      ),
    [columns]
  );

  const clickListener = useCallback(
    (event) => {
      if (containerRef.current) {
        if (addButtonRef.current?.contains?.(event.target)) {
          handleAddRowClick(event);
        }
        if (!containerRef.current?.contains?.(event.target)) {
          dispatch(
            setSingleFindingColumnsFromEdited({
              itemId: props?.questionId,
            })
          );
          setIsAddedEventListener(false);
          window.removeEventListener("click", clickListener);
        }
      }
    },
    [
      containerRef.current,
      addButtonRef.current,
      singleFindingToEdit,
      findingRef.current,
    ]
  );

  useEffect(() => {
    return () => {
      if (isAddedEventListener) {
        dispatch(
          setSingleFindingColumnsFromEdited({ itemId: props?.questionId })
        );
      }
    };
  }, [isAddedEventListener]);

  const handleClick = () => {
    if (!isAddedEventListener && !props?.disabled) {
      setIsAddedEventListener(true);
      window.addEventListener("click", clickListener);
    }
  };

  const handleRef = (newRef, index) => {
    if (index === columnAnswers?.length - 1 && isAddedRow && newRef) {
      // newRef?.scrollIntoView?.({behavior: "smooth", top: "50px"});
      window.scrollTo({
        top: newRef?.getBoundingClientRect?.()?.top + window.scrollY - 60,
        behavior: "smooth",
      });
      setIsAddedRow(false);
    }
  };

  return (
    <FindingTableInputContainer>
      {isMobile ? (
        <FindingRowsContainer ref={containerRef}>
          {columnAnswers?.map?.((singleColumnAnswer, index) => {
            return (
              <FindingTableSingleRow
                clickListener={handleClick}
                key={index}
                setIsAddedRow={() => setIsAddedRow(true)}
                ref={(el) => handleRef(el, index)}
                rowCount={index + 1}
                inputs={tableRowInputs.data}
                questionId={props?.questionId}
                lastRow={index === columnAnswers?.length - 1}
                disabled={props?.disabled}
                immediateApply={
                  index === columnAnswersToEdit?.length - 1 &&
                  !isClickableToAdd &&
                  !isClickedToAdd
                }
                immediateApplyFunction={() => setIsClickedToAdd(true)}
              />
            );
          })}
        </FindingRowsContainer>
      ) : (
        <FindingItemTableRefContainer ref={containerRef} onClick={handleClick}>
          <FindingItemTable
            mobile={props?.mobile}
            questionId={props?.questionId}
            readonly={props?.disabled}
          />
        </FindingItemTableRefContainer>
      )}

      <FindingTableInputFooter>
        {isClickedToAdd == false && !isClickableToAdd && (
          <ErrorMessage>{t("table.addRowNotAllowed")}</ErrorMessage>
        )}
        {!props?.disabled && (
          <AddRowButton onClick={handleAddRowClick} ref={addButtonRef}>
            <PlusIconThin />
            {/* {t("table.addRow")} */}
          </AddRowButton>
        )}
      </FindingTableInputFooter>
    </FindingTableInputContainer>
  );
};

FindingTableInput.propTypes = {
  children: PropTypes.node,
  questionId: PropTypes.number,
  disabled: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default FindingTableInput;
