import React, { useState, useEffect } from "react";
import {
  ButtonsContainer,
  SettingsContentContainer,
  ButtonsAreaContainer,
  RequiredFieldsText,
  TitleButtonsContainer,
  MailAndArchiveContainer,
} from "./SettingsContent.styled";
import PageTitle from "../PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import { useFormik } from "formik";
import { settingsInitialValues } from "../../initialValues/settingsInitialValues";
import settingsValidations from "../../validations/settingsValidations";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEditCompany,
  fetchMyCompany,
} from "../../store/actions/companies/companiesActions";
import { selectMyCompany } from "../../store/selectors/companiesSelector";
import { useHistory } from "react-router-dom";
import { makeToastMessage } from "../../store/utils/makeToastMessage";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { MY_COMPANY_SCOPE } from "../../store/actions/companies/companiesActionConstants";
import CircularLoader from "../Loader/CircularLoader/CircularLoader";
import {
  closeLoadingModal,
  closeModal,
  toggleConfirmModal,
  toggleSetLoadingModal,
} from "../../store/actions/modal/modalActions";
import TabChooser from "../TabChooser/TabChooser";
import settingsTabsConstants, {
  settingsTabsAsArray,
} from "../../constants/settingsTabsConstants";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import EmailInformation from "./EmailInformation/EmailInformation";
import useIsMobile from "../../hooks/useIsMobile";
import ArchivingDetails from "./ArchivingDetails/ArchivingDetails";

const SettingsContent = () => {
  const [selectedTab, setSelectedTab] = useState(
    settingsTabsConstants.general.id
  );
  const [googleDriveFile, setGoogleDriveFile] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const companyData = useSelector(selectMyCompany);
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsLoadingByActionType(MY_COMPANY_SCOPE));

  useEffect(() => {
    dispatch(fetchMyCompany());
  }, []);

  const handleApiResponseSuccess = () => {
    dispatch(closeLoadingModal());
    dispatch(fetchMyCompany());
    makeToastMessage({
      title: t("companySettings.companyChangedSuccess"),
    });
    formik?.resetForm();
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    dispatch(
      fetchEditCompany({
        companyData: values,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleChangeTab = (newTab) => {
    if (formik?.dirty) {
      dispatch(
        toggleConfirmModal({
          title: t("companySettings.confirmModal.title"),
          description: t("companySettings.confirmModal.description"),
          cancelButtonText: t("companySettings.confirmModal.cancel"),
          confirmButtonText: t("companySettings.confirmModal.save"),
          onConfirm: () => {
            formik?.submitForm();
            if (formik?.isValid) {
              setSelectedTab(newTab);
            }
            dispatch(closeModal());
          },
          onCancel: () => {
            dispatch(closeModal());
            formik?.resetForm();
            setSelectedTab(newTab);
          },
        })
      );
    } else {
      setSelectedTab(newTab);
    }
  };

  const formik = useFormik({
    initialValues: settingsInitialValues(companyData),
    validationSchema: settingsValidations,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  // Return loader component if data is still fetching
  if (isLoading) return <CircularLoader />;

  // const disabledSaveButton =
  //   (formik?.values?.automaticallySendEmails &&
  //     (formik?.values?.host.trim().length === 0 ||
  //       formik?.values?.port == 0 ||
  //       formik?.values?.userName.trim().length === 0 ||
  //       formik?.values?.password.trim().length === 0)) ||
  //   (formik?.values?.automaticallyUploadToDrive &&
  //     formik?.values?.driveFolderId.trim().length === 0);

  const onFileChange = (file) => {
    setGoogleDriveFile(file);
  };

  return (
    <SettingsContentContainer>
      <TitleButtonsContainer>
        <PageTitle>{t("companySettings.title")}</PageTitle>
        {/* Buttons */}
        {/* ****** */}
        {!isMobile && (
          <ButtonsAreaContainer>
            <ButtonsContainer>
              <PrimaryButton onClick={() => history.goBack()}>
                {t("companySettings.cancel")}
              </PrimaryButton>
              <PrimaryButton
                variant="contained"
                type="submit"
                onClick={formik?.handleSubmit}
                disabled={!formik?.dirty && googleDriveFile === null}
              >
                {t("companySettings.save")}
              </PrimaryButton>
            </ButtonsContainer>
            <RequiredFieldsText>
              {t("common.requiredFieldsText")}
            </RequiredFieldsText>
          </ButtonsAreaContainer>
        )}
        {/* ****** */}
      </TitleButtonsContainer>
      <TabChooser
        tabs={settingsTabsAsArray}
        selectedTab={selectedTab}
        handleChangeTab={handleChangeTab}
      />

      {/* Selected tab content */}
      {/* ****** */}
      {selectedTab === settingsTabsConstants.general.id ? (
        <GeneralSettings formik={formik} />
      ) : (
        <MailAndArchiveContainer>
          <EmailInformation formik={formik} />
          <ArchivingDetails formik={formik} onFileChange={onFileChange} />
        </MailAndArchiveContainer>
      )}
      {/* ****** */}

      {/* Buttons */}
      {/* ****** */}
      {isMobile && (
        <ButtonsAreaContainer>
          <ButtonsContainer>
            <PrimaryButton onClick={() => history.goBack()}>
              {t("companySettings.cancel")}
            </PrimaryButton>
            <PrimaryButton
              variant="contained"
              type="submit"
              onClick={formik?.handleSubmit}
            >
              {t("companySettings.save")}
            </PrimaryButton>
          </ButtonsContainer>
          <RequiredFieldsText>
            {t("common.requiredFieldsText")}
          </RequiredFieldsText>
        </ButtonsAreaContainer>
      )}
      {/* ****** */}
    </SettingsContentContainer>
  );
};

export default SettingsContent;
