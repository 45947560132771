import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { CheckboxLabel } from "../../NewUser/Checkbox/Checkbox.styled";

export const ArchivingDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-top: 24px;
`;
export const FormContainer = styled.form`
  display: flex;
  gap: 80px;
  width: fit-content;
  padding: 0px 24px 24px 24px;
  border: 1px solid ${selectedTheme.colors.tableBorder};
  border-radius: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 32px;
  }
`;

export const ContentLeft = styled(Box)`
  padding-top: 22px;
  width: 370px;
`;

export const ContentRight = styled(Box)``;

export const CheckboxMainContainer = styled(Box)`
  & ${CheckboxLabel} {
    font-weight: 600;
    color: ${selectedTheme.colors.labelTextColor};
  }
`;
