import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import PlusIcon from "../../Icon/Icons/PlusIcon";

export const SendMailsModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  width: 460px;
  z-index: ${(props) => "151" + props.rank || "151"};
  @media (max-width: 600px) {
    width: calc(100vw - 32px);
    padding: 24px;
    max-height: 80vh;
    overflow: auto;
  }
`;
export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 500;
  color: ${selectedTheme.colors.errorMessage};
  text-align: left;
`;
export const DistributionMailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
export const SingleDistributionMailContainer = styled(Box)`
  &:has(p) {
    margin-bottom: 12px;
  }
`;
export const NewEmailButton = styled(Button)`
  background-color: ${selectedTheme.colors.white};
  margin-bottom: 16px;

  color: ${selectedTheme.colors.primaryButtonTextColor};
  text-transform: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  align-self: flex-end;
  font-weight: 600;
  white-space: nowrap;
  & svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;
export const MailsLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  /* margin-bottom: 2px; */
  position: relative;
  top: 4px;
  text-align: left;
`;
export const NewEmailIcon = styled(PlusIcon)`
  & path {
    stroke: black;

    stroke-width: 1.25px;
  }
`;
