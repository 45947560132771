import * as Yup from "yup";
import i18n from "../i18n";
import { setPasswordFormikConstants } from "../initialValues/setPasswordInitialValues";

export default Yup.object().shape({
  [setPasswordFormikConstants.password]: Yup.string()
    .max(255, i18n.t("changePassword.passwordNumberOfCharacters"))
    .required(i18n.t("changePassword.blankPasswordError")),
  [setPasswordFormikConstants.confirmPassword]: Yup.string()
    .max(255, i18n.t("changePassword.passwordNumberOfCharacters"))
    .oneOf(
      [Yup.ref(setPasswordFormikConstants.password), null],
      i18n.t("changePassword.differentPasswordsError")
    )
    .required(i18n.t("changePassword.blankPasswordError"))
    .matches(
      /^(?=.{6,})/, //eslint-disable-line
      i18n.t(i18n.t("changePassword.passwordLength"))
    )
    .matches(
      /^(?=.*[0-9])/, //eslint-disable-line
      i18n.t(i18n.t("changePassword.numbersRequred"))
    )
    .matches(
      /^(?=.*[!@#\$%\^&\*])/, //eslint-disable-line
      i18n.t(i18n.t("changePassword.specialCharactersRequred"))
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/, //eslint-disable-line
      i18n.t(i18n.t("changePassword.lettersRequred"))
    ),
});
