import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectFindingColumns } from "../../../../../store/selectors/findingsSelectors";
import {
  FindingItemTableBody,
  FindingItemTableContainer,
} from "./FindingItemTable.styled";
import FindingItemTableRow from "./FindingItemTableRow/FindingItemTableRow";
import FindingItemTableHeader from "./FindingItemTableHeader/FindingItemTableHeader";

const FindingItemTable = (props) => {
  const columns = useSelector(selectFindingColumns(props?.questionId));

  const noData = useMemo(() => columns?.[0]?.answers?.length === 0, [columns]);

  const columnsSorted = useMemo(() => {
    return columns?.reduce((acc, val) => {
      if (acc?.find((singleColumn) => singleColumn?.id === val?.id))
        return [...acc];
      return [
        ...acc,
        ...columns?.filter(
          (singleColumn) => singleColumn?.columnTitle === val?.columnTitle
        ),
      ];
    }, []);
  }, [columns]);

  return (
    <FindingItemTableContainer $manuallyMobile={props?.mobile}>
      <FindingItemTableHeader
        columns={columnsSorted}
        noData={noData}
        readonly={props?.readonly}
      />
      <FindingItemTableBody>
        {columnsSorted?.[0]?.answers?.map?.((singleAnswer, index) => (
          <FindingItemTableRow
            key={index}
            readonly={props?.readonly}
            rowIndex={index}
            lastRow={index === columnsSorted?.[0]?.answers?.length - 1}
            questionId={props?.questionId}
            data={columnsSorted?.map?.(
              (singleColumn) => singleColumn?.answers?.[index]
            )}
          />
        ))}
      </FindingItemTableBody>
    </FindingItemTableContainer>
  );
};

FindingItemTable.propTypes = {
  children: PropTypes.node,
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readonly: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default FindingItemTable;
